import React, { Component } from "react";
import { observer } from "mobx-react";
import Nango from "@nangohq/frontend";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import Functions from "../../../helpers/Functions";
import analytics from "../../../helpers/Analytics";
import { toAbsoluteUrl } from "../../../helpers";
import { AuthTypes } from "../../../helpers/Enums";
import getGoogleAccessToken from "../../../helpers/api/getGoogleAccessToken";
import { App } from "../../models/App";
import { CompanyApp } from "../../models/CompanyApp";
import { ConnectionParam } from "../../models/ConnectionParam";
import { ConnectionCredential } from "../../models/ConnectionCredential";
import stores from "../../stores";
import AppParamView from "./AppParamView";

declare const google: any;

interface Props {
  app: App;
  onCancel: () => void;
  onConnect: (credential?: ConnectionCredential) => void;
  onUpdate: (companyApp: CompanyApp, credential?: ConnectionCredential) => void;
  onDisconnect: (companyApp: CompanyApp) => void;
}

interface State {
  companyApp?: CompanyApp;
  params: ConnectionParam[];
  credential?: ConnectionCredential;
  activationMessage: string;
  hasError: boolean;
  isLoading: boolean;
  shouldRedirect: boolean;
  pickerApiLoaded: boolean;
  gisClient?: any;
  gapiLoaded: boolean;
  googleAuth?: any;
  selectedDocs: any[];
  displayCount: number;
  showVerificationModal: boolean;
  showMainModal: boolean;
}

@observer
export default class ConnectAppModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      params: this.props.app.params,
      activationMessage: "",
      hasError: false,
      isLoading: false,
      shouldRedirect: false,
      companyApp: undefined,
      pickerApiLoaded: false,
      gisClient: undefined,
      gapiLoaded: false,
      googleAuth: undefined,
      selectedDocs: [],
      displayCount: 10,
      showVerificationModal: false,
      showMainModal: true,
    };
  }

  componentWillMount() {
    const { app } = this.props;
    const companyApp = stores.companyAppStore.companyApps.find(
      (ca) => ca.app_id.toString() === app.id.toString()
    );
    if (companyApp) this.setState({ companyApp });

    if (window.gapi) {
      window.gapi.load("picker", () => {
        this.setState({ pickerApiLoaded: true });
      });
    } else {
      console.error("window.gapi not loaded!");
    }

    this.initGoogleIdentityServices();
  }

  private initGoogleIdentityServices() {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    if (!clientId) {
      console.error("Google Client ID not defined!");
      return;
    }

    try {
      const gisClient = google.accounts.oauth2.initCodeClient({
        client_id: clientId,
        scope: "https://www.googleapis.com/auth/drive.file",
        // "https://www.googleapis.com/auth/drive.metadata.readonly" +
        // "https://www.googleapis.com/auth/documents.readonly" +
        // "https://www.googleapis.com/auth/spreadsheets.readonly",
        callback: (response: any) => {
          if (response.error) {
            console.error("OAuth error:", response.error);
          }
        },
      });

      this.setState({ gisClient });
    } catch (error) {
      console.error("Failed to initialize Google Identity Services:", error);
    }
  }

  // private async initGoogleClient() {
  //   try {
  //     await window.gapi.client.init({
  //       apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  //       clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //       scope: [
  //         "https://www.googleapis.com/auth/drive.file",
  //         "https://www.googleapis.com/auth/drive.metadata.readonly",
  //       ].join(" "),
  //       discoveryDocs: [
  //         "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  //       ],
  //     });

  //     const authInstance = window.gapi.auth2.getAuthInstance();

  //     if (authInstance) {
  //       this.setState({
  //         gapiLoaded: true,
  //         googleAuth: authInstance,
  //       });
  //     } else {
  //       console.error("initGoogleClient: Auth instance not obtained!");
  //     }
  //   } catch (error) {
  //     console.error(
  //       "initGoogleClient: GAPI client initialization error:",
  //       error
  //     );
  //   }
  // }

  private handleGooglePicker = async () => {
    const { pickerApiLoaded } = this.state;

    if (!pickerApiLoaded) {
      console.error("Google Picker API not loaded!");
      toast.error("Google Picker API not loaded!");
      return;
    }

    try {
      const { app } = this.props;
      const selectedCompany = stores.companyStore.selectedUserCompany;

      if (!selectedCompany) {
        console.error("handleGooglePicker: User company not selected!");
        toast.error("You cannot proceed without selecting a company.");
        return;
      }

      let key = "";
      let pickerView: any;
      if (app.name === "Google Drive") {
        key = "google-drive";
        pickerView = new google.picker.View(google.picker.ViewId.DOCS);
      } else if (app.name === "Google Docs") {
        key = "google-docs";
        const docsView = new google.picker.DocsView(google.picker.ViewId.DOCS)
          .setMimeTypes("application/vnd.google-apps.document")
          .setIncludeFolders(false);
        pickerView = docsView;
      } else if (app.name === "Google Sheets") {
        key = "google-sheet";
        pickerView = new google.picker.View(google.picker.ViewId.SPREADSHEETS);
      }

      const companyId = selectedCompany.id.toString();
      const connectionId = `${companyId}-${app.id}`;

      const accessToken = await getGoogleAccessToken(connectionId, key);

      if (!accessToken) {
        console.error(`Could not retrieve token for Google Picker for ${key}!`);
        toast.error(`Could not retrieve token for Google Picker for ${key}!`);
        return;
      }

      const styleEl = document.createElement("style");
      styleEl.innerHTML = `
        .picker-dialog {
          z-index: 999999 !important;
        }
      `;
      document.head.appendChild(styleEl);

      const picker = new window.google.picker.PickerBuilder()
        .addView(pickerView)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setOAuthToken(accessToken)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY!)
        .setCallback(this.pickerCallback)
        .build();

      picker.setVisible(true);
    } catch (error) {
      console.error("handleGooglePicker: An error occurred:", error);
      toast.error(
        "An error occurred while opening the Google Picker. Please check the console."
      );
    }
  };

  private handleTestClick = async (credential?: ConnectionCredential) => {
    const { app } = this.props;

    analytics.trackEvent("test_app_button_pressed", {
      category: "connection_events",
      label: `test_app_${app.name}`,
    });

    if (stores.companyStore.selectedUserCompany) {
      this.setState({ isLoading: true });

      const nango = new Nango({
        //@ts-ignore
        publicKey: process.env.REACT_APP_NANGO_PUBLIC_KEY,
      });

      const connectionId = `${stores.companyStore.selectedUserCompany.id}-${app.id}`;
      const credentials =
        app.auth_type && app.auth_type === AuthTypes.ApiKey && credential
          ? {
              apiKey: credential.params.find(
                (param) => param.key === AuthTypes.ApiKey
              )?.info,
            }
          : undefined;

      nango
        .auth(app.integration_id, connectionId, {
          params: credential
            ? Functions.transformArrayToObject(credential.params)
            : {},
          credentials: credentials ? credentials : {},
          detectClosedAuthWindow: true,
        })
        .then(
          async (result: {
            providerConfigKey: string;
            connectionId: string;
          }) => {
            const updatedCredential: ConnectionCredential = this.state
              .credential
              ? this.state.credential
              : {
                  params: [],
                  connectionId: "",
                };
            updatedCredential.connectionId = connectionId;

            this.setState({
              credential: updatedCredential,
              activationMessage: "Connection success.",
              hasError: false,
              isLoading: false,
            });
          }
        )
        .catch((err: { message: string; type: string }) => {
          this.setState({
            activationMessage: `Connection error: ${err.message}`,
            hasError: true,
            isLoading: false,
          });
        });
    }
  };

  private handleConnectLinkClick = async () => {
    const { credential } = this.state;
    const { app } = this.props;

    analytics.trackEvent("connect_app_button_pressed", {
      category: "connection_events",
      label: "connect_app_button",
    });

    this.setState({ isLoading: true });
    try {
      await stores.companyAppStore.createCompanyApp(
        app,
        credential?.connectionId
      );

      const newCompanyApp = stores.companyAppStore.companyApps.find(
        (ca) => ca.app_id.toString() === app.id.toString()
      );

      this.setState({
        companyApp: newCompanyApp,
        isLoading: false,
      });

      if (
        app.name !== "Google Drive" &&
        app.name !== "Google Docs" &&
        app.name !== "Google Sheets"
      ) {
        if (newCompanyApp) {
          this.props.onCancel();
          await stores.companyAppStore.syncCompanyAppData(newCompanyApp);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      this.setState({ isLoading: false });
    }
  };

  private pickerCallback = (data: any) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const selectedDocs = data.docs.map((doc: any) => ({
        id: doc.id,
        name: doc.name,
        mimeType: doc.mimeType,
      }));

      if (selectedDocs.length === 0) {
        toast.info(
          "All selected files were already synced. No new files to sync!"
        );
        return;
      }

      this.setState({ selectedDocs, displayCount: 10 });
    }
  };

  private handleUpdateLinkClick = async () => {
    this.setState({ isLoading: true, shouldRedirect: true });
  };

  private handleParamChange = (
    credential: ConnectionCredential | undefined,
    activationMessage: string,
    hasError: boolean
  ) => {
    this.setState({
      credential,
      activationMessage,
      hasError,
    });
  };

  private handleLoadMore = () => {
    this.setState((prevState) => ({
      displayCount: prevState.displayCount + 10,
    }));
  };

  private handleSyncYourFiles = async () => {
    const { companyApp, selectedDocs } = this.state;
    if (!companyApp) {
      return;
    }
    if (selectedDocs.length === 0) {
      return;
    }

    this.props.onCancel();
    this.setState({ isLoading: true });

    try {
      await stores.companyAppStore.syncSelectedFiles(companyApp, selectedDocs);
      this.setState({ isLoading: false });
    } catch (error) {
      console.error("Sync error:", error);
      this.setState({
        activationMessage: "Sync error occurred.",
        isLoading: false,
      });
    }
  };

  private handleShowVerificationModal = () => {
    this.setState({ showVerificationModal: true, showMainModal: false });
  };

  private handleContinueVerification = async () => {
    this.setState({ showVerificationModal: false, showMainModal: true });
    await this.handleTestClick(this.state.credential);
  };
  render() {
    const { app } = this.props;
    const {
      companyApp,
      params,
      credential,
      isLoading,
      activationMessage,
      hasError,
      shouldRedirect,
      selectedDocs,
      displayCount,
      showVerificationModal,
      showMainModal,
    } = this.state;

    const isGoogleDrive =
      app.name === "Google Drive" ||
      app.name === "Google Docs" ||
      app.name === "Google Sheets";

    const isGoogleApp =
      app.name === "Google Drive" ||
      app.name === "Google Docs" ||
      app.name === "Google Sheets" ||
      app.name === "Google Calendar" ||
      app.name === "Gmail" ||
      app.name === "YouTube";

    if (shouldRedirect) {
      return <Navigate to="/connections" />;
    }

    return (
      <>
        <div
          className="modal fade show d-block"
          id="kt_modal_add_company_users_apps"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: isGoogleDrive ? "700px" : "550px" }}
          >
            {showVerificationModal && (
              <div className="modal-content px-5 pb-0">
                <div className="modal-header justify-content-center">
                  <div className="text-dark fw-bold fs-4 text-center">
                    Google Verification in Progress – No Worries!
                  </div>
                </div>

                <div className="modal-body  px-5  pb-0 pt-7">
                  <p className="text-gray-800 fw-semibold fs-5">
                    Our app is currently undergoing Google’s verification
                    process, but there’s no risk to you!
                  </p>

                  <div className="text-center">
                    <img
                      src="/google.gif"
                      alt="Google verification"
                      className="w-100"
                    />
                  </div>

                  <div className="text-dark fw-bold fs-4 mb-1 mt-3">
                    How to Proceed?
                  </div>

                  <ol className="text-start fs-6 text-gray-700 fw-semibold">
                    <li>
                      Click{" "}
                      <span className="fw-bold text-dark">“Advanced”</span>
                    </li>

                    <li>
                      Select{" "}
                      <span className="fw-bold text-dark">
                        “Go to companydna.ai (unsafe)”
                      </span>
                    </li>
                  </ol>
                </div>

                <div className="modal-footer" style={{ border: "none" }}>
                  <button
                    className="btn btn-lg btn-color-gray-600 me-4 fw-bolder px-6 py-3"
                    onClick={() =>
                      this.setState({
                        showVerificationModal: false,
                        showMainModal: true,
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleContinueVerification}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}

            {showMainModal && (
              <div className="modal-content">
                <div className="modal-header pb-0 border-0">
                  <h3 className="align-items-start flex-row">
                    <img
                      src={toAbsoluteUrl(app.logo)}
                      style={{ width: 40 }}
                      className="align-self-center me-3"
                      alt=""
                    />
                    <span className="fw-bold text-dark">{app.name}</span>
                  </h3>
                </div>

                <div className="modal-body py-lg-10 px-lg-10">
                  <div className="current" data-kt-stepper-element="content">
                    {app.important && (
                      <>
                        <div className="fw-bolder text-dark fs-4 mb-1">
                          Important
                        </div>
                        <span className="text-gray-700 mt-1 fw-semibold fs-6">
                          {app.important}
                        </span>
                      </>
                    )}
                    {app.limitations && (
                      <>
                        <div className="fw-bolder text-dark fs-4 mb-1 mt-3">
                          Limitations
                        </div>
                        <span className="text-gray-700 mt-1 fw-semibold fs-6">
                          {app.limitations}
                        </span>
                      </>
                    )}

                    <div className="w-140">
                      <AppParamView
                        app={app}
                        activationMessage={activationMessage}
                        credential={credential}
                        hasError={hasError}
                        params={params}
                        handleParamChange={this.handleParamChange}
                      />
                      {activationMessage && (
                        <div className="fv-plugins-message-container mt-4">
                          <span
                            className={`fw-bolder fs-5 text-${
                              hasError ? "danger" : "success"
                            }`}
                          >
                            {activationMessage}
                          </span>
                        </div>
                      )}

                      {isGoogleDrive &&
                        companyApp &&
                        companyApp.source_ids &&
                        companyApp.source_ids.length > 0 && (
                          <div className="mt-4">
                            <h5>Previously Synced Files:</h5>
                            <ul>
                              {companyApp.source_ids
                                .slice(0, displayCount)
                                .map((file) => (
                                  <li key={file.id}>
                                    {file.name}
                                    {file.modifiedTime && (
                                      <span>
                                        {" "}
                                        (Last modified:{" "}
                                        {new Date(
                                          file.modifiedTime
                                        ).toLocaleString()}
                                        )
                                      </span>
                                    )}
                                  </li>
                                ))}
                            </ul>
                            {displayCount < companyApp.source_ids.length && (
                              <button
                                className="btn btn-sm btn-light"
                                onClick={this.handleLoadMore}
                              >
                                Load More
                              </button>
                            )}
                          </div>
                        )}

                      {selectedDocs && selectedDocs.length > 0 && (
                        <div className="alert alert-info mt-3">
                          {`You have selected ${selectedDocs.length} file${
                            selectedDocs.length > 1 ? "s" : ""
                          }.`}
                        </div>
                      )}

                      {selectedDocs && selectedDocs.length > 0 && (
                        <div className="mt-3">
                          <h5>Newly Selected Files:</h5>
                          <ul>
                            {selectedDocs
                              .slice(0, displayCount)
                              .map((doc: any) => (
                                <li key={doc.id}>
                                  {doc.name} ({doc.mimeType})
                                </li>
                              ))}
                          </ul>
                          {displayCount < selectedDocs.length && (
                            <button
                              className="btn btn-sm btn-light"
                              onClick={this.handleLoadMore}
                            >
                              Load More
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-center pt-10">
                    {companyApp && (
                      <button
                        type="reset"
                        className="btn btn-lg btn-color-danger me-4 fw-bolder px-6 py-3"
                        onClick={() => this.props.onDisconnect(companyApp)}
                      >
                        Disconnect
                      </button>
                    )}

                    <div style={{ flex: 1 }}></div>

                    <div className="d-flex align-items-center">
                      <button
                        type="reset"
                        className="btn btn-lg btn-color-gray-600 me-4 fw-bolder px-6 py-3"
                        onClick={this.props.onCancel}
                      >
                        Cancel
                      </button>

                      {!companyApp ? (
                        activationMessage && !hasError ? (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            disabled={isLoading}
                            onClick={this.handleConnectLinkClick}
                          >
                            {isLoading ? (
                              <span className="spinner-border spinner-border-sm align-middle"></span>
                            ) : (
                              "Activate"
                            )}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            disabled={isLoading}
                            onClick={() => {
                              if (isGoogleApp) {
                                this.handleShowVerificationModal();
                              } else {
                                this.handleTestClick(credential);
                              }
                            }}
                          >
                            {isLoading ? (
                              <span className="spinner-border spinner-border-sm align-middle"></span>
                            ) : activationMessage && !hasError ? (
                              "Activate"
                            ) : (
                              "Test"
                            )}
                          </button>
                        )
                      ) : isGoogleDrive ? (
                        <>
                          {selectedDocs && selectedDocs.length > 0 && (
                            <button
                              type="button"
                              className="btn btn-lg btn-secondary me-2"
                              disabled={isLoading}
                              onClick={this.handleGooglePicker}
                            >
                              {isLoading ? (
                                <span className="spinner-border spinner-border-sm align-middle"></span>
                              ) : (
                                "Update files"
                              )}
                            </button>
                          )}

                          <button
                            type="button"
                            className="btn btn-lg btn-primary me-2"
                            disabled={isLoading}
                            onClick={
                              selectedDocs && selectedDocs.length > 0
                                ? this.handleSyncYourFiles
                                : this.handleGooglePicker
                            }
                          >
                            {isLoading ? (
                              <span className="spinner-border spinner-border-sm align-middle"></span>
                            ) : selectedDocs && selectedDocs.length > 0 ? (
                              "Sync your files"
                            ) : (
                              "Select your files"
                            )}
                          </button>
                        </>
                      ) : this.props.app.is_source_available === 1 ? (
                        activationMessage && !hasError ? (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            disabled={isLoading}
                            onClick={this.handleUpdateLinkClick}
                          >
                            {isLoading ? (
                              <span className="spinner-border spinner-border-sm align-middle"></span>
                            ) : (
                              "Update"
                            )}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            disabled={isLoading}
                            onClick={() => {
                              if (isGoogleApp) {
                                this.handleShowVerificationModal();
                              } else {
                                this.handleTestClick(this.state.credential);
                              }
                            }}
                          >
                            {isLoading ? (
                              <span className="spinner-border spinner-border-sm align-middle"></span>
                            ) : (
                              "Test"
                            )}
                          </button>
                        )
                      ) : (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          onClick={() => {
                            if (activationMessage && !hasError) {
                              this.props.onUpdate(companyApp!, credential);
                            } else {
                              this.handleTestClick(credential);
                            }
                          }}
                        >
                          {isLoading ? (
                            <span className="spinner-border spinner-border-sm align-middle"></span>
                          ) : activationMessage && !hasError ? (
                            "Update"
                          ) : (
                            "Test"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
