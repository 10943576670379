/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import { Spinner } from "react-bootstrap";

import analytics from "../../../helpers/Analytics";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { CompanyAvatarStatus } from "../../../helpers/Enums";
import { CompanyAvatar } from "../../models/CompanyAvatar";

import stores from "../../stores";

interface State {}

interface Props {
  onDeletePress: (avatar: CompanyAvatar) => void;
  onEditPress: (avatar: CompanyAvatar) => void;
  onStartChatWithAvatarPress: (avatar: CompanyAvatar) => void;
  avatar: CompanyAvatar;
  isLoadingThisAvatar: boolean;
  isAnyLoading: boolean;
  canDelete: boolean;
  canEdit: boolean;
}

@observer
export default class AvatarItem extends React.Component<Props, State> {
  state: State = {};

  renderAvatarStatusBadge = (status: CompanyAvatarStatus) => {
    let iconPath = "";
    let badgeClass = "";
    let labelText = "";

    switch (status) {
      case CompanyAvatarStatus.Connected:
        iconPath = "/media/icons/duotune/general/gen048.svg";
        badgeClass = "text-primary";
        labelText = "Connected";
        break;
      case CompanyAvatarStatus.InProgress:
        iconPath = "/media/icons/duotune/general/gen049.svg";
        badgeClass = "text-warning";
        labelText = "In Progress";
        break;
      case CompanyAvatarStatus.Failed:
        iconPath = "/media/icons/duotune/general/gen050.svg";
        badgeClass = "text-danger";
        labelText = "Failed";
        break;
      default:
        iconPath = "/media/icons/duotune/general/gen051.svg";
        badgeClass = "text-danger";
        labelText = "Not Connected";
        break;
    }

    return (
      <span className={`${badgeClass} fs-8 ms-2 mb-1 fw-bold`}>
        <KTSVG
          path={iconPath}
          className={`${badgeClass} svg-icon svg-icon-3 me-1`}
        />
        {labelText}
      </span>
    );
  };
  handleOpenShareModal = () => {
    analytics.trackEvent("share_avatar_pressed", {
      category: "avatar_events",
      label: "share_avatar_button",
    });
    stores.companyAvatarStore.isSharedAvatarModalOpen = true;
    stores.companyAvatarStore.selectedCompanyAvatar = this.props.avatar;
  };

  render() {
    const {
      avatar,
      isLoadingThisAvatar,
      isAnyLoading,
      canDelete,
      canEdit,
      onDeletePress,
      onEditPress,
      onStartChatWithAvatarPress,
    } = this.props;

    const isAvatarReady = avatar.status === CompanyAvatarStatus.Connected;

    return (
      <div className="border-bottom mt-5">
        <div key={avatar.id} className="d-flex align-items-center mb-5 ">
          <div className="me-5 position-relative">
            <div className="symbol symbol-90px me-1">
              <span className="symbol-label">
                <img
                  src={toAbsoluteUrl(avatar.logo_url)}
                  className="h-75 align-self-center"
                  alt={avatar.name}
                />
              </span>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex align-items-center">
              <a
                href="#"
                className="fs-4 text-gray-800 fw-bolder text-hover-primary"
              >
                {avatar.name}
              </a>
              {this.renderAvatarStatusBadge(avatar.status)}
            </div>

            <div className="d-flex align-items-center symbol-group symbol-hover mt-2 mb-2">
              {avatar.company_avatar_apps?.slice(0, 10).map((app) => {
                const matchingApp = stores.companyAppStore.companyApps.find(
                  (companyApp) =>
                    companyApp.id.toString() === app.company_app_id.toString()
                );

                return (
                  <div
                    key={app.id}
                    className="symbol symbol-25px symbol-circle bg-body me-2"
                    title={matchingApp ? matchingApp.app.name : "App not found"}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                  >
                    <img
                      src={matchingApp?.app.logo || "/path/to/default/logo.png"}
                      alt={matchingApp?.app.name || "Unknown"}
                      className="rounded-circle"
                      style={{ width: "25px", height: "25px", padding: "3px" }}
                    />
                  </div>
                );
              })}

              {avatar.company_avatar_apps?.length > 10 && (
                <span
                  className="symbol symbol-30px symbol-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={`+${avatar.company_avatar_apps.length - 10} more`}
                >
                  <span className="symbol-label fs-8">
                    +{avatar.company_avatar_apps.length - 10}
                  </span>
                </span>
              )}
            </div>

            <div className="fw-semibold text-gray-500">
              {avatar.description}
            </div>
          </div>

          <div className="ms-auto d-flex gap-2">
            <button
              className="btn btn-light-primary btn-sm fs-6"
              disabled={!isAvatarReady}
              onClick={this.handleOpenShareModal}
            >
              {avatar.status !== CompanyAvatarStatus.Connected ? null : (
                <KTSVG
                  path="/media/icons/duotune/coding/cod008.svg"
                  className="svg-icon-2 me-2"
                />
              )}

              {avatar.status !== CompanyAvatarStatus.Connected
                ? "Creating avatar..."
                : "Share"}
            </button>

            <button
              className="btn btn-icon btn-light-success me-2"
              disabled={!isAvatarReady || isAnyLoading}
              onClick={() => onStartChatWithAvatarPress(avatar)}
            >
              {isLoadingThisAvatar ? (
                <Spinner as="span" animation="border" size="sm" />
              ) : (
                <KTSVG
                  path="/media/icons/duotune/communication/com012.svg"
                  className="svg-icon-3"
                />
              )}
            </button>

            <button
              className="btn btn-icon btn-light-warning me-2"
              onClick={() => {
                analytics.trackEvent("edit_avatar_pressed", {
                  category: "avatar_events",
                  label: "edit_avatar_button",
                });
                onEditPress(avatar);
              }}
              disabled={isAnyLoading}
              title={
                !canEdit
                  ? "You do not have permission to edit this avatar."
                  : "Edit Avatar"
              }
            >
              <KTSVG
                path="/media/icons/duotune/art/art005.svg"
                className="svg-icon-3"
              />
            </button>

            <button
              className="btn btn-icon btn-light-danger"
              onClick={() => {
                analytics.trackEvent("avatar_delete_confirm_pressed", {
                  category: "avatar_events",
                  label: "avatar_deleted",
                });
                onDeletePress(avatar);
              }}
              disabled={isAnyLoading}
              title={
                !canDelete
                  ? "You do not have permission to delete this avatar."
                  : "Delete Avatar"
              }
            >
              <KTSVG
                path="/media/icons/duotune/general/gen027.svg"
                className="svg-icon-3"
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
