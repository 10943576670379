import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import analytics from "../../../../helpers/Analytics";
import i18n from "../../../../i18n";

interface DeleteAccountModalProps {
  show: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  loading: boolean;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
  show,
  onClose,
  onDelete,
  loading,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        analytics.trackEvent("close_delete_account_modal", {
          category: "user_profile_events",
          label: "delete_account_modal_closed",
        });
        onClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{i18n.ToastMessages.confirmDeletion}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{i18n.ToastMessages.deleteAccountConfirmation}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            analytics.trackEvent("cancel_delete_account", {
              category: "user_profile_events",
              label: "delete_account_canceled",
            });
            onClose();
          }}
        >
          {i18n.common.cancel}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            analytics.trackEvent("confirm_delete_account", {
              category: "user_profile_events",
              label: "delete_account_confirmed",
            });
            onDelete();
          }}
          disabled={loading}
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            i18n.common.delete
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAccountModal;
