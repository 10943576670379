import { Edge, Node } from "@xyflow/react";

export const validateConnection = (
  nodes: Node[],
  sourceHandleId: string,
  targetHandleId: string,
  sourceId: string,
  targetId: string
): boolean => {
  const sourceNode = nodes.find((node) => node.id === sourceId);
  const targetNode = nodes.find((node) => node.id === targetId);

  const sourceHandle: any = nodes
    .flatMap((node) => node.data?.handles || [])
    .find((handle: any) => handle.id === sourceHandleId);
  const targetHandle: any = nodes
    .flatMap((node) => node.data?.handles || [])
    .find((handle: any) => handle.id === targetHandleId);

  if (!sourceHandle || !targetHandle || !sourceNode || !targetNode) {
    return false;
  }

  let connectionIsValid = false;
  // Allowed types check
  if (
    !sourceHandle.allowedTypes ||
    sourceHandle.allowedTypes.includes(targetNode.type)
  ) {
    connectionIsValid = true;
  }

  // Same question check
  if (targetNode.type === "resultNode") {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, sourceId, targetId] = targetNode.id.split("_");
    connectionIsValid = sourceNode.id !== sourceId;
  }

  return connectionIsValid;
};

export const handleEdgeUpdate = (
  nodes: Node[],
  edges: Edge[],
  params: Edge,
  newEdges: Edge[],
  setNodes: (nodes: Node[]) => void,
  setEdges: (edges: Edge[]) => void
): void => {
  const selectedEdge = edges.find((edge) => edge.id === params.id);

  const targetNode = nodes.find((node) => node.id === params.target);
  const sourceNode = nodes.find((node) => node.id === params.source);

  if (
    sourceNode?.type === "questionNode" &&
    (targetNode?.type === "appNode" ||
      targetNode?.type === "avatarNode" ||
      targetNode?.type === "appGroupNode" ||
      targetNode?.type === "avatarGroupNode")
  ) {
    const resultNodeId = `result_${sourceNode.id}_${targetNode.id}`;

    const newResultNode: Node = {
      id: resultNodeId,
      type: "resultNode",
      data: {
        label: `Result`,
        handles: [
          { id: "result_node_source_1", allowedTypes: ["questionNode"] },
          { id: "result_node_target_1", allowedTypes: ["finalResultNode"] },
        ],
      },
      position: {
        x: sourceNode.position.x + 300,
        y: sourceNode.position.y,
      },
    };

    const newResultEdge: Edge = {
      id: `edge_${resultNodeId}`,
      source: resultNodeId,
      sourceHandle: "result_node_source_1",
      target: sourceNode.id,
      targetHandle: "question_node_target_1",
      type: "smoothstep",
    };

    //@ts-ignore
    setNodes((nds) => {
      //@ts-ignore
      if (!nds.find((node) => node.id === resultNodeId)) {
        return [...nds, newResultNode];
      }
      return nds;
    });

    setTimeout(() => {
      //@ts-ignore
      setEdges((eds) => {
        //@ts-ignore
        if (!eds.find((edge) => edge.id === newResultEdge.id)) {
          return [...eds, newResultEdge];
        }
        return eds;
      });
    }, 200);
  } else if (selectedEdge) {
    const remainingEdges = newEdges.filter(
      (edge) => edge.id !== selectedEdge.id
    );
    setEdges(remainingEdges);

    if (selectedEdge.sourceHandle === "question_node_source_1") {
      const disconnectedResult = nodes.find(
        (node) => node.id === selectedEdge.target
      );
      if (disconnectedResult) {
        const questionEdges = edges.filter((edge) =>
          edge.id.startsWith("edge_")
        );

        if (questionEdges.length > 0) {
          //@ts-ignore
          setEdges((eds) =>
            eds.filter(
              //@ts-ignore
              (edge) => !questionEdges.some((qEdge) => qEdge.id === edge.id)
            )
          );
        }
        // //@ts-ignore
        // setEdges((eds) =>
        //   //@ts-ignore
        //   eds.filter((edge) => edge.source !== disconnectedResult.id)
        // );

        //@ts-ignore
        setNodes((nds) =>
          //@ts-ignore
          nds.filter((node) => node.type !== "resultNode")
        );
      }
    } else if (selectedEdge.targetHandle === "question_node_target_1") {
      const disconnectedResult = nodes.find(
        (node) => node.id === selectedEdge.target
      );

      if (disconnectedResult) {
        const finalResultEdges = edges.filter((edge) =>
          edge.id.startsWith("edge_")
        );

        if (finalResultEdges.length > 0) {
          //@ts-ignore
          setEdges((eds) =>
            //@ts-ignore
            eds.filter(
              //@ts-ignore
              (edge) =>
                !finalResultEdges.some(
                  (frEdge) => frEdge.source === edge.source
                )
            )
          );
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, sourceId, targetId] = selectedEdge.source.split("_");
        //@ts-ignore
        setEdges((eds) =>
          eds.filter(
            //@ts-ignore
            (edge) => edge.source !== sourceId && edge.target !== targetId
          )
        );

        //@ts-ignore
        setNodes((nds) =>
          //@ts-ignore
          nds.filter((node) => node.type !== "resultNode")
        );
      }
    }
  }
};
