/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { observer } from "mobx-react";

import { checkModelApiKey } from "../../../helpers/api";
import { KTIcon } from "../../../helpers";
import analytics from "../../../helpers/Analytics";
import { Model } from "../../models/Model";
import { CompanyModel } from "../../models/CompanyModel";
import stores from "../../stores";

interface Props {
  model: Model;
  companyModel?: CompanyModel;
  onCancel: () => void;
  onActivate: (apiKey: string) => void;
  onUpdate: (companyModel: CompanyModel, apiKey: string) => void;
  onDeactivate: (companyModel: CompanyModel) => void;
}

interface State {
  companyModel?: CompanyModel;
  apiKey: string;
  activationMessage: string;
  hasError: boolean;
  isLoading: boolean;
  isFileLoaded: boolean;
}

@observer
export default class SetupModelModal extends Component<Props, State> {
  private fileInput: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      apiKey: "",
      activationMessage: "",
      hasError: false,
      isLoading: false,
      isFileLoaded: false,
    };
  }

  componentWillMount() {
    const { model } = this.props;

    const companyModel = stores.companyModelStore.companyModels.find(
      (companyModel) => companyModel.model_id.toString() === model.id.toString()
    );

    if (companyModel)
      this.setState({ companyModel, apiKey: companyModel.credentials });
  }

  private handleTestClick = async (apiKey: string) => {
    const { model } = this.props;

    analytics.trackEvent("test_model_button_pressed", {
      category: "model_events",
      label: "test_model_button",
    });

    this.setState({ isLoading: true });

    const result = await checkModelApiKey(model, apiKey);

    this.setState({
      activationMessage: result.message,
      hasError: !result.success,
      isLoading: false,
    });
  };

  private handleFileSelect = async (event: any) => {
    const file = event.target.files[0];

    analytics.trackEvent("file_select_for_model_button_pressed", {
      category: "model_events",
      label: "file_select_for_model_button",
    });

    if (file && file.type === "application/json") {
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          if (e.target && e.target.result) {
            //@ts-ignore
            const result = JSON.parse(e.target.result);

            if (result.client_email && result.project_id) {
              this.setState({
                apiKey: encodeURIComponent(JSON.stringify(result)),
                isFileLoaded: true,
              });
            } else {
              this.setState({ isFileLoaded: false });
            }
          }
        } catch (err) {
          this.setState({ isFileLoaded: false });
        }
      };

      reader.readAsText(file);
    } else {
      this.setState({ isFileLoaded: false });
    }
  };

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      apiKey: e.target.value,
      activationMessage: "",
      hasError: false,
    });
  };

  render() {
    const { model } = this.props;
    const {
      companyModel,
      apiKey,
      isLoading,
      activationMessage,
      hasError,
      isFileLoaded,
    } = this.state;

    return (
      <>
        <div
          className="modal fade show d-block"
          id="kt_modal_add_company_users_apps"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered mw-500px">
            <div className="modal-content">
              <div className="modal-header pb-0 border-0">
                <h3 className="align-items-start flex-row">
                  <span className="fw-bold text-dark">{`Setup ${model.name}`}</span>
                </h3>
              </div>

              <div className="modal-body py-lg-10 px-lg-10">
                <div className="current" data-kt-stepper-element="content">
                  <span className="text-muted mt-1 fw-semibold fs-6">
                    We'll never use your API key for anything other than to run
                    your assistant.
                  </span>

                  <div className="w-100">
                    {/* Google Vertex AI control */}
                    {model.id.toString() === "4" ||
                    model.id.toString() === "6" ? (
                      <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-4 mt-3 mb-2">
                        <KTIcon
                          iconName="paper-clip"
                          className="fs-2tx text-primary me-4"
                        />

                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                          <div className="mb-3 mb-md-0 fw-bold">
                            <div
                              className={`fs-6 text-${
                                isFileLoaded === undefined
                                  ? "gray-600"
                                  : isFileLoaded === false
                                  ? "warning"
                                  : "primary"
                              } pe-7`}
                            >
                              {isFileLoaded === undefined
                                ? "Upload your service account key JSON file"
                                : isFileLoaded === false
                                ? "Please choose a valid JSON file!"
                                : "The selected file is valid."}
                            </div>
                          </div>

                          <input
                            type="file"
                            accept=".json"
                            style={{ display: "none" }}
                            ref={(input) => (this.fileInput = input)}
                            onChange={this.handleFileSelect}
                          />

                          <a
                            href="#"
                            className="btn btn-primary px-6 align-self-center text-nowrap"
                            onClick={() =>
                              this.fileInput && this.fileInput.click()
                            }
                          >
                            Select
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="fv-row mt-8">
                        <input
                          type="password"
                          className="form-control form-control-lg form-control-sm"
                          name="appname"
                          placeholder="Enter api key..."
                          value={apiKey}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    )}

                    {activationMessage ? (
                      <div className="fv-plugins-message-container ms-1">
                        <span
                          className={`text-${hasError ? "danger" : "success"}`}
                        >
                          {activationMessage}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex flex-center pt-10">
                  {companyModel ? (
                    <button
                      type="reset"
                      className="btn btn-lg btn-color-danger me-4 fw-bolder px-6 py-3"
                      onClick={() => this.props.onDeactivate(companyModel)}
                    >
                      Deactivate
                    </button>
                  ) : null}

                  <div style={{ flex: 1 }}></div>

                  <div>
                    <button
                      type="reset"
                      className="btn btn-lg btn-color-gray-600 me-4 fw-bolder px-6 py-3"
                      onClick={() => {
                        this.props.onCancel();
                        analytics.trackEvent(
                          "cancel_set_up_model_button_pressed",
                          {
                            category: "model_events",
                            label: "cancel_set_up_model_button",
                          }
                        );
                      }}
                    >
                      Cancel
                    </button>

                    {!companyModel ? (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        disabled={apiKey === "" || isLoading ? true : false}
                        onClick={() => {
                          if (activationMessage && !hasError) {
                            this.props.onActivate(apiKey);
                            analytics.trackEvent(
                              "activate_model_button_pressed",
                              {
                                category: "model_events",
                                label: "activate_model_button",
                              }
                            );
                          } else {
                            this.handleTestClick(apiKey);
                          }
                        }}
                      >
                        {isLoading ? (
                          <span className="spinner-border spinner-border-sm align-middle"></span>
                        ) : activationMessage && !hasError ? (
                          "Activate"
                        ) : (
                          "Test"
                        )}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        disabled={
                          apiKey === companyModel.credentials ? true : false
                        }
                        onClick={() => {
                          if (activationMessage && !hasError)
                            this.props.onUpdate(companyModel, apiKey);
                          else this.handleTestClick(apiKey);
                        }}
                      >
                        {isLoading ? (
                          <span className="spinner-border spinner-border-sm align-middle"></span>
                        ) : activationMessage && !hasError ? (
                          "Update"
                        ) : (
                          "Test"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
