import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react";

import { LayoutSplashScreen } from "../../layout/core";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import CompanyUserManagePageWrapper from "../pages/CompanyUsers/CompanyUsersList/CompanyUserManagePage/CompanyUserManagePageWrapper";
import CreateNewAvatarWrapper from "../pages/CompanyAvatars/CreateNewAvatar/CreateNewAvatarWrapper";

import { App } from "../App";
import stores from "../stores";
import WizardsPage from "../pages/wizards/WizardsPage";
import SuccessPage from "../pages/wizards/CreateCompany/SuccessPage";
import UpgradePlanPage from "../pages/UpgradePlan/UpgradePlanPage";

import { PrivateRoutes } from "./PrivateRoutes";
import CancelPage from "../pages/wizards/CreateCompany/CancelPage";
import CreateNewSectionFlowWrapper from "../pages/SectionFlowPage/CreateNewSectionFlow/CreateNewSectionFlowWrapper";
import PaymentRedirect from "../pages/wizards/CreateCompany/PaymentRedirect";

const AppRoutes: React.FC = observer(() => {
  const { user, isLoading, loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  if (!isLoading) {
    if (!user) {
      handleLogin();
      return null;
    }

    if (user && !stores.userStore.currentUser) {
      stores.userStore.setUser(user);
    }
  }

  if (stores.companyStore.isLoading && !stores.companyStore.selectedUserCompany)
    return <LayoutSplashScreen />;
  else if (
    stores.companyStore.selectedUserCompany &&
    stores.companyAppStore.isLoading
  ) {
    return <LayoutSplashScreen />;
  }

  const stripeSessionId = sessionStorage.getItem("stripeSessionId");

  if (!stripeSessionId) {
    if (stores.userStore.isCurrentUserAdmin && stores.userStore.isFreeUser) {
      const userCompanyPlan = stores.companyStore.selectedUserCompany?.plan;
      const freeTokenCount = stores.companyStore.freeTokenCount;

      if (userCompanyPlan === "Premium" || freeTokenCount <= 0) {
        return <UpgradePlanPage />;
      }
    }
  }

  return (
    <Routes>
      <Route element={<App />}>
        <Route path="error/*" element={<ErrorsPage />} />
        {!stores.companyStore.selectedUserCompany ? (
          <>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<WizardsPage />} />
          </>
        ) : (
          <>
            <Route path="/*" element={<PrivateRoutes />} />

            {stores.userStore.isCurrentUserAdmin ? (
              <Route index element={<Navigate to="/the-chief-workspace" />} />
            ) : (
              <Route index element={<Navigate to="/chat" />} />
            )}

            <Route
              path="/company-user-manage-page/:companyUserId"
              element={<CompanyUserManagePageWrapper />}
            />
            <Route
              path="/create-new-avatar"
              element={<CreateNewAvatarWrapper />}
            />
            <Route
              path="/create-new-section-flow/:id"
              element={<CreateNewSectionFlowWrapper />}
            />
            <Route
              path="/create-new-section-flow"
              element={<CreateNewSectionFlowWrapper />}
            />
          </>
        )}
        {stores.companyStore.selectedUserCompany && (
          <Route path="/success" element={<SuccessPage />} />
        )}

        {stores.companyStore.selectedUserCompany && (
          <Route path="/cancel" element={<CancelPage />} />
        )}
        <Route path="/stripe-redirect" element={<PaymentRedirect />} />
      </Route>
    </Routes>
  );
});

export { AppRoutes };
