/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import { SharedCompanyAvatar } from "../../../models/SharedCompanyAvatar";
import stores from "../../../stores";
import { toAbsoluteUrl } from "../../../../helpers";

interface Props {
  onSharedAvatarSelect: (sharedAvatar: SharedCompanyAvatar | undefined) => void;
}

@observer
export default class SharedAvatarsSelectionTableView extends React.Component<Props> {
  private renderSharedAvatar = (
    sharedAvatar: SharedCompanyAvatar,
    index: number
  ) => {
    return (
      <tr key={index} className="odd">
        <td>
          <div
            onClick={() => this.props.onSharedAvatarSelect(sharedAvatar)}
            className="d-flex align-items-center cursor-pointer"
          >
            <div className="me-5 position-relative">
              <div className="symbol symbol-50px me-1">
                <span className="symbol-label">
                  <img
                    src={toAbsoluteUrl(sharedAvatar.company_avatar.logo_url)}
                    className="h-50 align-self-center"
                    alt=""
                  />
                </span>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center">
              <a className="fs-6 text-gray-800 text-hover-primary">
                {sharedAvatar.company_avatar.name}
              </a>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const sharedAvatars = stores.companyAvatarStore.sharedAvatarsWithUser;

    return (
      <div className="card">
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table
              id="kt_profile_overview_table"
              className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold dataTable no-footer"
            >
              <tbody className="fs-6">
                {sharedAvatars.length > 0 ? (
                  sharedAvatars.map(this.renderSharedAvatar)
                ) : (
                  <tr>
                    <td className="text-center" colSpan={2}>
                      <div className="text-gray-600 d-flex text-center w-100 align-content-center justify-content-center mt-8">
                        No shared avatars found.
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
