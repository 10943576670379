import React from "react";
import { Position, NodeProps, Node, Edge } from "@xyflow/react";

import CustomHandle from "../handles/CustomHandle";

interface ResultNodeProps extends NodeProps {
  data: any;
  nodes: Node[];
  edges: Edge[];
}

const ResultNode: React.FC<ResultNodeProps> = (props) => {
  return (
    <div className="p-3 bg-body">
      <div className="bg-secondary border border-gray-400 rounded p-3">
        <div>
          <strong>Result:</strong>
          <div>{props.data.label}</div>
        </div>

        <CustomHandle
          type="source"
          id="result_node_source_1"
          position={Position.Top}
          connectionCount={1}
          nodes={props.nodes}
          edges={props.edges}
        />

        <CustomHandle
          type="target"
          id="result_node_target_1"
          position={Position.Bottom}
          connectionCount={1}
          nodes={props.nodes}
          edges={props.edges}
        />
      </div>
    </div>
  );
};

export default ResultNode;
