import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";

import {
  getCompanyUserDepartments,
  updateUser,
} from "../../../../../helpers/api";
import { KTIcon } from "../../../../../helpers";
import { Department } from "../../../../models/Department";
import stores from "../../../../stores";
import { CompanyUserListLoading } from "../components/loading/CompanyUserListLoading";

interface Props {
  companyUserId: number;
}

interface State {
  selectedDepartments: Department[];
  searchText: string;
  shouldRedirect: boolean;
  departments: Department[];
  isEditingName: boolean;
  editedName: string;
  isLoadingInitial: boolean;
  isUpdating: boolean;
}

@observer
export default class CompanyUserManagePage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedDepartments: [],
      searchText: "",
      shouldRedirect: false,
      departments: stores.companyDepartmentStore.departments,
      isEditingName: false,
      editedName: "",
      isLoadingInitial: true,
      isUpdating: false,
    };
    this.initializeData();
  }

  async initializeData() {
    this.setState({ isLoadingInitial: true });

    const selectedUser = stores.companyUserStore.companyUsers.find(
      (item) => item.id === this.props.companyUserId
    );

    if (selectedUser) {
      try {
        const companyUserDepartments = await getCompanyUserDepartments(
          selectedUser.id
        );

        const associatedDepartments =
          stores.companyDepartmentStore.departments.filter((department) =>
            companyUserDepartments.some(
              (companyUserDepartment) =>
                companyUserDepartment.department_id.toString() ===
                department.id.toString()
            )
          );

        this.setState({
          departments: stores.companyDepartmentStore.departments,
          selectedDepartments: associatedDepartments,
          editedName: selectedUser.user.name || "",
          isLoadingInitial: false,
        });
      } catch (error) {
        console.error("Error fetching company user departments:", error);
        this.setState({ isLoadingInitial: false });
      }
    } else {
      this.setState({ isLoadingInitial: false });
    }
  }

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.toLowerCase();
    const filteredDepartments =
      stores.companyDepartmentStore.departments.filter((department) =>
        department.name.toLowerCase().includes(searchText)
      );

    this.setState({ searchText, departments: filteredDepartments });
  };

  private handleChange = (department: Department) => {
    ReactGA.event({
      category: "company_users",
      action: "select_company_user_departments",
      label: "select_company_user_departments_button",
    });

    const { selectedDepartments } = this.state;
    const departmentIndex = selectedDepartments.findIndex(
      (item) => item.id === department.id
    );

    let newSelectedDepartments = [...selectedDepartments];

    if (departmentIndex === -1) {
      newSelectedDepartments.push(department);
    }

    this.setState({ selectedDepartments: newSelectedDepartments });
  };
  private handleSubmit = async () => {
    const { companyUserId } = this.props;
    const { selectedDepartments, editedName } = this.state;
    const selectedUser = stores.companyUserStore.companyUsers.find(
      (item) => item.id === companyUserId
    );

    ReactGA.event({
      category: "company_users",
      action: "submit_add_company_user_departments",
      label: "submit_add_company_user_departments_button",
    });

    if (!selectedUser) {
      console.error("Selected user is undefined");
      return;
    }

    this.setState({ isUpdating: true });

    try {
      if (editedName && editedName !== selectedUser.user.name) {
        const updatedUser = {
          ...selectedUser.user,
          name: editedName,
        };
        await updateUser(updatedUser);
        await stores.companyUserStore.getCompanyUsers();
      }

      const companyUserDepartments = await getCompanyUserDepartments(
        selectedUser.id
      );

      const departmentsToAdd = selectedDepartments.filter(
        (department) =>
          !companyUserDepartments.some(
            (associatedApp) => associatedApp.department_id === department.id
          )
      );

      const departmentsToRemove = companyUserDepartments.filter(
        (associatedDepartment) =>
          !selectedDepartments.some(
            (department) => department.id === associatedDepartment.department_id
          )
      );

      if (departmentsToRemove.length > 0) {
        await stores.companyDepartmentStore.deleteCompanyUserDepartments(
          departmentsToRemove
        );
      }

      if (departmentsToAdd.length > 0) {
        await stores.companyDepartmentStore.addCompanyUserDepartments(
          Number(companyUserId),
          departmentsToAdd
        );
      }
    } catch (error) {
      console.error("Error updating departments:", error);
    } finally {
      this.setState({ isUpdating: true, shouldRedirect: true });
    }
  };
  private handleRemoveDepartment = (department: Department) => {
    this.setState((prevState) => ({
      selectedDepartments: prevState.selectedDepartments.filter(
        (item) => item.id !== department.id
      ),
    }));
  };

  private renderDepartmentItem = (department: Department) => {
    const { selectedDepartments } = this.state;
    const isSelected = selectedDepartments.some(
      (item) => item.id === department.id
    );

    return (
      <div
        key={department.id}
        className={`d-flex align-items-center justify-content-between position-relative mb-4 p-3 rounded cursor-pointer ${
          isSelected
            ? "border border-primary border-3 border-dotted border-light bg-light-primary"
            : "border border-gray-300 border-dashed border-light"
        }`}
        onClick={() => this.handleChange(department)}
      >
        <span className="d-flex flex-column p-2">
          <span className="fw-bolder text-hover-primary fs-6">
            {department.name}
          </span>
          <span className="fs-7 text-muted">{department.description}</span>
        </span>
        {isSelected && (
          <button
            className="btn btn-sm btn-icon btn-active-color-danger d-flex align-items-center justify-content-center"
            onClick={(e) => {
              e.stopPropagation();
              this.handleRemoveDepartment(department);
            }}
            style={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
          >
            <i className="ki-duotone ki-cross-square fs-1">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </button>
        )}
      </div>
    );
  };

  render() {
    const { companyUserId } = this.props;
    const {
      searchText,
      shouldRedirect,
      isUpdating,
      isLoadingInitial,
      departments,
      isEditingName,
      editedName,
      selectedDepartments,
    } = this.state;
    const selectedUser = stores.companyUserStore.companyUsers.find(
      (item) => item.id === companyUserId
    );

    const selectedDepartmentsList = selectedDepartments
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
    const unselectedDepartments = departments.filter(
      (dept) => !selectedDepartments.some((sel) => sel.id === dept.id)
    );

    if (shouldRedirect) {
      return <Navigate to="/company-users" />;
    }

    return (
      <div className="d-flex flex-column align-items-center vh-100 position-relative">
        <div
          className="mb-3 mt-10 d-flex justify-content-between align-items-center"
          style={{ width: "85%" }}
        >
          <div>
            <div className="d-flex align-items-center">
              {isEditingName ? (
                <input
                  type="text"
                  className="fs-3 fw-bolder text-gray-900 mb-1 mt-1"
                  value={editedName}
                  onChange={(e) =>
                    this.setState({ editedName: e.target.value })
                  }
                  style={{ width: "auto", display: "inline-block" }}
                />
              ) : (
                <div className="fw-bold fs-2">{editedName}</div>
              )}
              <button
                className="btn btn-light-primary p-0 ms-3"
                style={{
                  width: "30px",
                  height: "30px",
                  padding: 0,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={
                  this.state.selectedDepartments.length === 0 ||
                  isUpdating ||
                  isLoadingInitial
                }
                onClick={() =>
                  this.setState((prevState) => ({
                    isEditingName: !prevState.isEditingName,
                  }))
                }
              >
                {isEditingName ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                    onClick={() =>
                      ReactGA.event({
                        category: "company_users",
                        action: "confirm_company_username_change",
                        label: "confirm_company_username_change_button",
                      })
                    }
                  >
                    <i className="fas fa-check"></i>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                    onClick={() =>
                      ReactGA.event({
                        category: "company_users",
                        action: "change_company_username",
                        label: "change_company_username_button",
                      })
                    }
                  >
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                )}
              </button>
            </div>
            <div className="text-gray-700 fs-6">{selectedUser?.user.email}</div>
          </div>

          <div>
            <Link
              to="/company-users"
              className="text-primary fw-bold"
              onClick={(event) => {
                if (isUpdating) {
                  event.preventDefault();
                } else {
                  ReactGA.event({
                    category: "company_users",
                    action: "cancel_company_user_manage",
                    label: "cancel_company_user_manage_button",
                  });
                }
              }}
            >
              <button className="btn btn-primary" disabled={isUpdating}>
                Cancel
              </button>
            </Link>
          </div>
        </div>

        <div className="separator border-2 w-100 mb-3"></div>

        <div className="w-50 mt-20">
          <h4 className="fw-bold fs-2">Select data source department</h4>
        </div>

        <div
          className="current w-50 mt-4 d-flex flex-column"
          data-kt-stepper-element="content"
          style={{
            minHeight: "50%",
          }}
        >
          <div className="d-flex align-items-center justify-content-start mb-4 ">
            <KTIcon
              iconName="magnifier"
              className="fs-1 position-absolute px-3"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control border-0 w-200px ps-12 "
              placeholder="Search Department..."
              value={searchText}
              onChange={this.handleInputChange}
            />
          </div>

          {isUpdating || isLoadingInitial ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "40vh" }}
            >
              <CompanyUserListLoading />
            </div>
          ) : (
            <div className="fv-row flex-grow-1">
              {departments.length > 0 ? (
                <div
                  style={{
                    maxHeight: "40vh",
                    overflowY: "auto",
                    paddingRight: "10px",
                  }}
                >
                  {selectedDepartmentsList.map(this.renderDepartmentItem)}

                  {selectedDepartments.length > 0 && (
                    <div className="d-flex justify-content-end align-items-center my-4">
                      <hr className="flex-grow-1 border-1 border-gray-300" />
                      <span className="px-3 text-muted">
                        {selectedDepartments.length} Selected department
                      </span>
                    </div>
                  )}
                  {unselectedDepartments.map(this.renderDepartmentItem)}
                </div>
              ) : (
                <p>No departments available.</p>
              )}
            </div>
          )}

          <div className="d-flex justify-content-end mt-20">
            <button
              className="btn btn-primary"
              disabled={
                this.state.selectedDepartments.length === 0 ||
                isUpdating ||
                isLoadingInitial
              }
              onClick={this.handleSubmit}
            >
              {isUpdating ? "Updating User..." : "Update"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
