/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { AvatarType } from "../../app/models/AvatarType";

export default async function getAvatarTypes(): Promise<AvatarType[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query AvatarTypesQuery {
        types {
          id
          type
        }
      }
    `;

    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((avatarTypesQueryResult: any) => {
        const avatarTypes: AvatarType[] = get(
          avatarTypesQueryResult,
          "data.types",
          null
        );

        if (avatarTypes) {
          resolve(avatarTypes);
        } else {
          resolve([]);
        }
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
