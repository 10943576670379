/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";

import analytics from "../../../helpers/Analytics";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { CompanyAvatarStatus } from "../../../helpers/Enums";
import { SharedCompanyAvatar } from "../../models/SharedCompanyAvatar";

interface State {}

interface Props {
  onDeletePress: (avatar: SharedCompanyAvatar) => void;
  onStartChatWithAvatarPress: (avatar: SharedCompanyAvatar) => void;
  avatar: SharedCompanyAvatar;
}

@observer
export default class SharedAvatarItem extends React.Component<Props, State> {
  state: State = {};

  renderAvatarStatusBadge = (status: CompanyAvatarStatus) => {
    let iconPath = "";
    let badgeClass = "";
    let labelText = "";

    switch (status) {
      case CompanyAvatarStatus.Connected:
        iconPath = "/media/icons/duotune/general/gen048.svg";
        badgeClass = "text-primary";
        labelText = "Connected";
        break;
      case CompanyAvatarStatus.InProgress:
        iconPath = "/media/icons/duotune/general/gen049.svg";
        badgeClass = "text-warning";
        labelText = "In Progress";
        break;
      case CompanyAvatarStatus.Failed:
        iconPath = "/media/icons/duotune/general/gen050.svg";
        badgeClass = "text-danger";
        labelText = "Failed";
        break;
      default:
        iconPath = "/media/icons/duotune/general/gen051.svg";
        badgeClass = "text-danger";
        labelText = "Not Connected";
        break;
    }

    return (
      <span className={`${badgeClass} fs-8 ms-2 mb-1 fw-bold`}>
        <KTSVG
          path={iconPath}
          className={`${badgeClass} svg-icon svg-icon-3 me-1`}
        />
        {labelText}
      </span>
    );
  };

  render() {
    const { avatar, onStartChatWithAvatarPress, onDeletePress } = this.props;

    return (
      <div className="border-bottom mt-5">
        <div key={avatar.id} className="d-flex align-items-center mb-5">
          <div className="me-5 position-relative">
            <div className="symbol symbol-90px me-1">
              <span className="symbol-label">
                <img
                  src={toAbsoluteUrl(avatar.company_avatar.logo_url)}
                  className="h-75 align-self-center"
                  alt={avatar.company_avatar.name}
                />
              </span>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex align-items-center">
              <a
                href="#"
                className="fs-4 text-gray-800 fw-bolder text-hover-primary"
              >
                {avatar.company_avatar.name}
              </a>
              {this.renderAvatarStatusBadge(avatar.company_avatar.status)}
            </div>

            <div className="fw-semibold text-gray-500">
              {avatar.company_avatar.description}
            </div>
            <div className="fw-semibold text-gray-500">
              {`Shared by ${avatar.shared_by_user.name}`}
            </div>
          </div>

          <div className="ms-auto d-flex">
            <button
              className="btn btn-icon btn-light-success me-2"
              disabled={
                avatar.company_avatar.status !== CompanyAvatarStatus.Connected
              }
              onClick={() => {
                analytics.trackEvent("start_chat_with_shared_avatar_pressed", {
                  category: "avatar_events",
                  label: "start_chat_with_shared_avatar_button",
                });
                onStartChatWithAvatarPress(avatar);
              }}
            >
              <KTSVG
                path="/media/icons/duotune/communication/com012.svg"
                className="svg-icon-3"
              />
            </button>
            <button
              className="btn btn-icon btn-light-danger"
              onClick={() => {
                analytics.trackEvent("revoke_shared_avatar_confirm_pressed", {
                  category: "avatar_events",
                  label: "shared_avatar_revoked",
                });
                onDeletePress(avatar);
              }}
            >
              <KTSVG
                path="/media/icons/duotune/general/gen027.svg"
                className="svg-icon-3"
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
