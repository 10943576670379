import StreamingAvatar from "@heygen/streaming-avatar";

export default async function getAvatar(): Promise<StreamingAvatar> {
  return new Promise<StreamingAvatar>(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/get-avatar-token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && data.token) {
          const avatar = new StreamingAvatar({ token: data.token });
          resolve(avatar);
        } else {
          reject("Could not get token");
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
