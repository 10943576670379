/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import { toast } from "react-toastify";

import { CompanyModelStatus, FeatureType } from "../../../helpers/Enums";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import analytics from "../../../helpers/Analytics";
import i18n from "../../../i18n";
import stores from "../../stores";
import { Model } from "../../models/Model";
import { CompanyModel } from "../../models/CompanyModel";

import SetupModelModal from "./SetupModelModal";

type Props = {};

type State = {
  selectedModel?: Model;
  isSetupModelModalOpened: boolean;
  isDefaultAPIKeySetting: boolean;
};

@observer
export default class CompanyModelsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isSetupModelModalOpened: false,
      isDefaultAPIKeySetting: false,
    };
  }

  private handleActivateClick = async (apiKey: string) => {
    const { selectedModel } = this.state;

    analytics.trackEvent("activate_model_button_pressed", {
      category: "model_events",
      label: "activate_model_button",
    });

    if (selectedModel) {
      this.setState({ isSetupModelModalOpened: false });

      const createdCompanyModel =
        await stores.companyModelStore.createCompanyModel(
          selectedModel,
          apiKey
        );

      if (createdCompanyModel) {
        this.setState({ selectedModel: undefined });
      }
    }
  };

  private handleUpdateClick = async (
    companyModel: CompanyModel,
    apiKey: string
  ) => {
    this.setState({ isSetupModelModalOpened: false });

    analytics.trackEvent("update_model_button_pressed", {
      category: "model_events",
      label: "update_model_button",
    });

    companyModel.credentials = apiKey;
    await stores.companyModelStore.updateCompanyModel(companyModel);

    this.setState({ selectedModel: undefined });
  };

  private handleDeactivateClick = async (companyModel: CompanyModel) => {
    analytics.trackEvent("delete_model_button_pressed", {
      category: "model_events",
      label: "delete_model_button",
    });
    this.setState({ isSetupModelModalOpened: false });

    await stores.companyModelStore.deleteSelectedCompanyModel(companyModel);

    this.setState({ selectedModel: undefined });
  };

  private renderModelStatusBadge = (status: CompanyModelStatus) => {
    let iconPath = "";
    let badgeClass = "";
    let labelText = "";

    switch (status) {
      case CompanyModelStatus.Connected:
        iconPath = "/media/icons/duotune/general/gen048.svg";
        badgeClass = "text-primary";
        labelText = "Connected";
        break;
      case CompanyModelStatus.InProgress:
        iconPath = "/media/icons/duotune/general/gen049.svg";
        badgeClass = "text-warning";
        labelText = "In Progress";
        break;
      case CompanyModelStatus.Failed:
        iconPath = "/media/icons/duotune/general/gen050.svg";
        badgeClass = "text-danger";
        labelText = "Failed";
        break;
      default:
        iconPath = "/media/icons/duotune/general/gen051.svg";
        badgeClass = "text-danger";
        labelText = i18n.AppInvitationsTable.notConnected;
    }

    return (
      <span className={`${badgeClass} fs-8 ms-2 mb-1 fw-bold`}>
        <KTSVG
          path={iconPath}
          className={`${badgeClass} svg-icon svg-icon-3 me-1`}
        />
        {labelText}
      </span>
    );
  };

  private handleModelClick = async (model: Model) => {
    const hasModelAccess = await stores.userStore.checkSubscribedFeatureType(
      FeatureType.ConnectModel
    );

    analytics.trackEvent("set_up_model_button_pressed", {
      category: "model_events",
      label: "set_up_model_button",
    });

    if (!hasModelAccess) {
      toast.error(i18n.ToastMessages.maxModelError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }

    this.setState({ selectedModel: model, isSetupModelModalOpened: true });
  };

  private renderModelItem = (model: Model, isDefaultAPIKeySetting: boolean) => {
    const companyModel = stores.companyModelStore.companyModels.find(
      (companyModel) => companyModel.model_id.toString() === model.id.toString()
    );

    return (
      <div
        key={model.id}
        className="d-flex align-items-sm-center mb-7 pb-7 border-bottom"
      >
        {/* begin::Symbol */}
        <div className="me-2 position-relative">
          <div className="symbol symbol-90px me-1">
            <span className="symbol-label">
              <img
                src={toAbsoluteUrl(model.logo)}
                className="w-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </div>
        {/* end::Symbol */}
        {/* begin::Title */}
        <div className="d-flex flex-row-fluid flex-wrap align-items-center">
          <div className="flex-grow-1 me-2">
            <span className="text-gray-800 fw-bold fs-4 d-flex align-items-center">
              {model.name}
              {this.renderModelStatusBadge(
                companyModel ? companyModel.status : CompanyModelStatus.None
              )}
            </span>

            <span
              style={{ maxWidth: "35vw" }}
              className="text-muted fw-semibold d-block pt-1"
              dangerouslySetInnerHTML={{ __html: model.description }}
            ></span>
          </div>
          {model.name === "OpenAI" &&
            (!companyModel ||
              companyModel.status !== CompanyModelStatus.Connected) && (
              <button
                className="btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6 me-3"
                onClick={() =>
                  this.setState({ isDefaultAPIKeySetting: true }, () =>
                    stores.companyModelStore.setDefaultApiKeyForBetaUser()
                  )
                }
                disabled={isDefaultAPIKeySetting}
              >
                <i className="fas fa-gift fs-4 me-2 text-primary"></i>
                Try for free 30 days
              </button>
            )}

          <div className="d-block ">
            {companyModel &&
            companyModel.status === CompanyModelStatus.Connected ? (
              <button
                className="btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6"
                onClick={() => {
                  this.setState({ selectedModel: model }, () => {
                    this.setState({ isSetupModelModalOpened: true });
                  });
                }}
              >
                <KTSVG
                  path={"/media/icons/duotune/art/art005.svg"}
                  className="svg-icon svg-icon-x text-gray me-3"
                />
                <span className="d-none d-md-inline">Edit</span>
              </button>
            ) : (
              <button
                className="btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6"
                onClick={() => this.handleModelClick(model)}
              >
                <KTSVG
                  path={"/media/icons/duotune/coding/cod001.svg"}
                  className="svg-icon svg-icon-2x text-primary me-3"
                />
                <span className="d-none d-md-inline">Set up</span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { selectedModel, isSetupModelModalOpened, isDefaultAPIKeySetting } =
      this.state;

    return (
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-lg-row-fluid">
          <div className="card ms-8 me-12">
            <div className="card-header align-items-center border-0 mt-4">
              <h3 className="card-title align-items-start flex-column">
                <span className="fw-bolder text-dark fs-2">
                  Model Providers
                </span>

                <span className="text-gray-600 mt-4 fw-semibold fs-6">
                  {stores.userStore.isFreeUser
                    ? "Free users can connect up to 1 model. Upgrade your plan to connect more models."
                    : "Manage your model connections between your account and CompanyDNA."}
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_upgrade_plan"
                    className="text-primary fw-bold ms-2"
                  >
                    Upgrade Plan
                  </a>
                </span>
              </h3>
            </div>

            <div className="card-body pt-5">
              {!stores.companyModelStore.isLoading
                ? stores.companyModelStore.models
                    .filter((model) => model.published)
                    .map((model) =>
                      this.renderModelItem(model, isDefaultAPIKeySetting)
                    )
                : null}
            </div>
          </div>
        </div>

        {isSetupModelModalOpened && selectedModel ? (
          <SetupModelModal
            model={selectedModel}
            onCancel={() => {
              this.setState({ isSetupModelModalOpened: false });
            }}
            onActivate={this.handleActivateClick}
            onUpdate={this.handleUpdateClick}
            onDeactivate={this.handleDeactivateClick}
          />
        ) : null}
      </div>
    );
  }
}
