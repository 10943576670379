/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { User } from "../../app/models/User";

export default async function getUserByEmail(
  email: string
): Promise<User | undefined> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query UserByEmailQuery($email: String!) {
        userByEmail(email: $email) {
          id
          name
          email
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          email: email,
        },
        fetchPolicy: "no-cache",
      })
      .then((userByEmailQueryResult: any) => {
        const user: User = get(
          userByEmailQueryResult,
          "data.userByEmail",
          null
        );

        if (user) {
          if (user.settings)
            //@ts-ignore
            user.settings = JSON.parse(decodeURIComponent(user.settings));

          resolve(user);
        } else {
          resolve(undefined);
        }
      })
      .catch((error: any) => {
        console.error(error);

        resolve(undefined);
      });
  });
}
