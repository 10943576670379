/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import analytics from "../../../helpers/Analytics";
import { SharedCompanyAvatar } from "../../models/SharedCompanyAvatar";

type Props = {
  onStartChatWithAvatarPress: (avatar: SharedCompanyAvatar) => void;
  onStartTalkWithAvatarPress: (avatar: SharedCompanyAvatar) => void;
  avatar: SharedCompanyAvatar;
  isChatPreparing: boolean;
};

const AvatarCard: React.FC<Props> = ({
  avatar,
  isChatPreparing,
  onStartChatWithAvatarPress,
  onStartTalkWithAvatarPress,
}) => {
  const backgroundImage = `url(${toAbsoluteUrl(
    "/media/svg/shapes/abstract-4.svg"
  )})`;

  const handleTalkPress = () => {
    analytics.trackEvent("talk_with_avatar_pressed", {
      category: "dashboard_event",
      label: "talk_with_avatar_button",
    });
    onStartTalkWithAvatarPress(avatar);
  };

  const handleChatPress = () => {
    analytics.trackEvent("chat_with_avatar_pressed", {
      category: "dashboard_event",
      label: "chat_with_avatar_button",
    });
    onStartChatWithAvatarPress(avatar);
  };

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right top",
        backgroundSize: "20% auto",
        backgroundImage,
      }}
      className="card card-xl-stretch mb-xl-8 border border-gray-300 shadow"
    >
      <div className="card-body d-flex align-items-center pt-0">
        <div className="d-flex flex-column flex-grow-1 py-2 mt-6">
          <span className="fw-bold text-dark fs-4 mb-2">
            {avatar.company_avatar.name}
          </span>

          <span className="fw-semibold text-muted fs-6">{`Shared by ${avatar.shared_by_user.name}`}</span>

          <div className="mt-8">
            <div className="d-flex gap-2">
              <button
                onClick={handleTalkPress}
                disabled={isChatPreparing}
                className="btn btn-light-primary btn-sm fs-6 me-2"
              >
                <i className="bi bi-chat-left-quote fs-4 p-0 me-2"></i>
                {isChatPreparing ? "Avatar is preparing.." : "Talk"}
              </button>

              <button
                className="btn btn-light-primary btn-sm fs-6"
                disabled={isChatPreparing}
                onClick={handleChatPress}
              >
                <KTSVG
                  path="/media/icons/duotune/communication/com003.svg"
                  className="svg-icon-2 me-2"
                />
                {isChatPreparing ? "Avatar is preparing.." : "Chat"}
              </button>
            </div>
          </div>
        </div>

        <img
          src={toAbsoluteUrl(avatar.company_avatar.logo_url)}
          alt={`${avatar.company_avatar.name} avatar`}
          className="align-self-end h-100px me-3"
        />
      </div>
    </div>
  );
};

export { AvatarCard };
