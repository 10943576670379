/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import { FeatureType, SharedAvatarStatus } from "../../../helpers/Enums";
import analytics from "../../../helpers/Analytics";
import { CompanyAvatar } from "../../models/CompanyAvatar";
import i18n from "../../../i18n";
import { AvatarPageLoading } from "./loading/AvatarPageLoading";
import { SharedCompanyAvatar } from "../../models/SharedCompanyAvatar";
import stores from "../../stores";
import {
  revokeSharedAvatar,
  updateSharedAvatarInvitationStatus,
} from "../../../helpers/api";

import AvatarItem from "./AvatarItem";
import SharedAvatarItem from "./SharedAvatarItem";
import SharedAvatarInvitationItem from "./SharedAvatarInvitationItem";

type Props = {
  navigate: (path: string) => void;
};

type State = {
  selectedAvatar?: CompanyAvatar;
  isCreateNewAvatar: boolean;
  showAvatarDeleteConfirmation: boolean;
  avatarToDelete?: CompanyAvatar;
  showSharedAvatarRevokeConfirmation: boolean;
  sharedAvatarToRevoke?: SharedCompanyAvatar;
  isEditMode: boolean;
  deleting: boolean;
  loadingAvatarId?: number;
};

@observer
class AvatarsPage extends React.Component<Props, State> {
  state: State = {
    selectedAvatar: undefined,
    isCreateNewAvatar: false,
    showAvatarDeleteConfirmation: false,
    showSharedAvatarRevokeConfirmation: false,
    sharedAvatarToRevoke: undefined,
    avatarToDelete: undefined,
    isEditMode: false,
    deleting: false,
    loadingAvatarId: undefined,
  };

  async componentWillMount() {
    try {
      await stores.companyAvatarStore.getCompanyAvatars();
    } catch (error) {
      console.error("Failed to get company avatars on mount:", error);
    }
  }

  handleAvatarSelect = (avatar: CompanyAvatar) => {
    this.setState({ selectedAvatar: avatar });
  };

  handleCreateNewAvatar = async () => {
    analytics.trackEvent("create_new_avatar_pressed", {
      category: "avatars_events",
      label: "create_new_avatar",
    });

    if (
      !stores.companyModelStore.companyModels ||
      stores.companyModelStore.companyModels.length === 0
    ) {
      toast.error(i18n.ToastMessages.companyModelMissing, {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }
    if (
      !stores.companyAppStore.companyApps ||
      stores.companyAppStore.companyApps.length === 0
    ) {
      toast.error(i18n.ToastMessages.companyAppMissing, {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }

    const hasCreateAvatarPermission =
      await stores.userStore.checkSubscribedFeatureType(
        FeatureType.CreateAvatar
      );

    if (!hasCreateAvatarPermission) {
      toast.error(i18n.ToastMessages.maxAvatarError, {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }

    this.setState({ isCreateNewAvatar: true, isEditMode: false });
    stores.wizardStore.resetWizard();
  };

  handleCloseWizard = () => {
    this.setState({ isCreateNewAvatar: false, isEditMode: false });
    stores.wizardStore.resetWizard();
    stores.wizardStore.setIsWizardActive(false);
  };

  confirmAvatarDelete = async () => {
    const { avatarToDelete } = this.state;

    if (avatarToDelete && avatarToDelete.id) {
      this.setState({ deleting: true });
      try {
        const success =
          await stores.companyAvatarStore.deleteCompanyAvatarWithIndex(
            avatarToDelete.id
          );

        if (success) {
          await stores.companyAvatarStore.getCompanyAvatars();
        } else {
          toast.error(i18n.ToastMessages.deleteFailed, {
            position: "top-center",
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error("Error occurred:", error);
        toast.error(i18n.ToastMessages.deleteFailed, {
          position: "top-center",
          autoClose: 5000,
        });
      } finally {
        this.setState({
          deleting: false,
          showAvatarDeleteConfirmation: false,
          avatarToDelete: undefined,
        });
      }
    }
  };

  handleAcceptSharedAvatar = async (avatar: SharedCompanyAvatar) => {
    await updateSharedAvatarInvitationStatus(
      avatar.id,
      SharedAvatarStatus.Accepted
    );

    stores.companyAvatarStore.getSharedAvatarsWithUser();
  };

  handleRejectSharedAvatar = async (avatar: SharedCompanyAvatar) => {
    await updateSharedAvatarInvitationStatus(
      avatar.id,
      SharedAvatarStatus.Rejected
    );

    stores.companyAvatarStore.getSharedAvatarsWithUser();
  };

  confirmSharedAvatarRevoke = async () => {
    const { sharedAvatarToRevoke } = this.state;

    if (sharedAvatarToRevoke && sharedAvatarToRevoke.id) {
      this.setState({ deleting: true });

      try {
        const success = await revokeSharedAvatar(sharedAvatarToRevoke.id);

        if (success) {
          await stores.companyAvatarStore.getSharedAvatarsWithUser();
        } else {
          toast.error(i18n.ToastMessages.deleteFailed, {
            position: "top-center",
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error("Error occurred:", error);

        toast.error(i18n.ToastMessages.deleteFailed, {
          position: "top-center",
          autoClose: 5000,
        });
      } finally {
        this.setState({
          deleting: false,
          showSharedAvatarRevokeConfirmation: false,
          sharedAvatarToRevoke: undefined,
        });
      }
    }
  };

  revokeSharedAvatar = async (avatar: SharedCompanyAvatar) => {
    analytics.trackEvent("revoke_shared_avatar_pressed", {
      category: "avatar_events",
      label: "revoke_shared_avatar_button",
    });

    this.setState({
      showSharedAvatarRevokeConfirmation: true,
      sharedAvatarToRevoke: avatar,
    });
  };

  deleteAvatar = async (avatar: CompanyAvatar) => {
    analytics.trackEvent("delete_avatar_pressed", {
      category: "avatar_events",
      label: "delete_avatar_button",
    });

    if (avatar.dashboard_section_id !== null) {
      toast.error("You do not have permission to delete this avatar.", {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }

    this.setState({
      showAvatarDeleteConfirmation: true,
      avatarToDelete: avatar,
    });
  };

  editAvatar = async (avatar: CompanyAvatar) => {
    if (avatar.dashboard_section_id !== null) {
      toast.error("You do not have permission to edit this avatar.", {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }

    stores.wizardStore.populateWizardForEditing(avatar);
    this.setState({ isCreateNewAvatar: true, isEditMode: true });
  };

  startChatWithAvatar = async (avatar: CompanyAvatar) => {
    analytics.trackEvent("start_chat_with_avatar_pressed", {
      category: "avatar_events",
      label: "start_chat_with_avatar_button",
    });

    this.setState({ loadingAvatarId: avatar.id });

    stores.companyAvatarStore.selectedCompanyAvatar = avatar;

    if (!stores.companyStore.selectedUserCompany) {
      console.error("No company selected");
      this.setState({ loadingAvatarId: undefined });
      return;
    }

    const documentName = `Chat with ${avatar.name}`;

    try {
      const newDocument = await stores.userDocumentStore.createUserDocument(
        documentName
      );
      stores.userDocumentStore.selectedUserDocument = newDocument;
      stores.userDocumentStore.lastSelectedDocumentId = newDocument.id;

      this.setState({ loadingAvatarId: undefined });

      this.props.navigate("/chat");
    } catch (error) {
      console.error("Failed to create chat document:", error);
      this.setState({ loadingAvatarId: undefined });
    }
  };

  startChatWithSharedAvatar = async (avatar: SharedCompanyAvatar) => {
    this.setState({ loadingAvatarId: avatar.company_avatar.id });

    stores.companyAvatarStore.selectedCompanyAvatar = avatar.company_avatar;

    if (!stores.companyStore.selectedUserCompany) {
      console.error("No company selected");
      this.setState({ loadingAvatarId: undefined });
      return;
    }

    const documentName = `Chat with ${avatar.company_avatar.name}`;

    try {
      const newDocument = await stores.userDocumentStore.createUserDocument(
        documentName
      );
      stores.userDocumentStore.selectedUserDocument = newDocument;
      stores.userDocumentStore.lastSelectedDocumentId = newDocument.id;

      this.setState({ loadingAvatarId: undefined });

      this.props.navigate("/chat");
    } catch (error) {
      console.error("Failed to create chat document:", error);
      this.setState({ loadingAvatarId: undefined });
    }
  };

  render() {
    const {
      isCreateNewAvatar: isCreatNewAvatar,
      showAvatarDeleteConfirmation,
      showSharedAvatarRevokeConfirmation,
      deleting,
      isEditMode,
      loadingAvatarId,
    } = this.state;
    const { isLoading } = stores.companyModelStore;

    if (isLoading) {
      return <AvatarPageLoading />;
    }

    if (deleting) {
      return <AvatarPageLoading />;
    }

    if (isCreatNewAvatar || isEditMode) {
      return <Navigate to={"/create-new-avatar"} />;
    }

    return (
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-lg-row-fluid">
          <div className="card ms-8 me-12">
            <div className="card-header align-items-center border-0 mt-4">
              <h3 className="card-title align-items-start flex-column">
                <span className="fw-bolder text-dark fs-2">Manage Avatars</span>

                <span className="text-gray-600 mt-4 fw-semibold fs-6">
                  {stores.userStore.isFreeUser
                    ? "Free users can create up to 3 avatars. Upgrade your plan to create more avatars."
                    : "Manage your avatars between your accounts and CompanyDNA."}
                  {stores.userStore.isFreeUser && (
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_upgrade_plan"
                      className="text-primary fw-bold ms-2"
                    >
                      Upgrade Plan
                    </a>
                  )}
                </span>
              </h3>

              <button
                onClick={this.handleCreateNewAvatar}
                className="btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6"
                disabled={!!loadingAvatarId}
              >
                <i className="ki-duotone ki-plus fs-2 text-primary me-0 me-md-2"></i>
                <span className="d-none d-md-inline">Create New Avatar</span>
              </button>
            </div>

            {stores.companyAvatarStore.sharedAvatarInvitations.length > 0
              ? stores.companyAvatarStore.sharedAvatarInvitations.map(
                  (avatar) => (
                    <SharedAvatarInvitationItem
                      onAcceptPress={this.handleAcceptSharedAvatar}
                      onRejectPress={this.handleRejectSharedAvatar}
                      avatar={avatar}
                    />
                  )
                )
              : null}

            <div className="card-body">
              <span className="fw-bolder text-dark fs-4">Your Avatars</span>

              {stores.companyAvatarStore.companyAvatars.filter(
                (avatar) => avatar.dashboard_section_id === null
              ).length > 0 ? (
                stores.companyAvatarStore.companyAvatars
                  .filter((avatar) => avatar.dashboard_section_id === null)
                  .map((avatar) => (
                    <AvatarItem
                      onStartChatWithAvatarPress={this.startChatWithAvatar}
                      onEditPress={this.editAvatar}
                      onDeletePress={this.deleteAvatar}
                      avatar={avatar}
                      isLoadingThisAvatar={loadingAvatarId === avatar.id}
                      isAnyLoading={loadingAvatarId !== undefined}
                      canDelete={avatar.dashboard_section_id === null}
                      canEdit={avatar.dashboard_section_id === null}
                    />
                  ))
              ) : (
                <div className="my-5">You don't have any avatars.</div>
              )}

              <div className="separator mb-3 opacity-75"></div>

              <span className="fw-bolder text-dark fs-4">Shared Avatars</span>

              {stores.companyAvatarStore.sharedAvatarsWithUser.length > 0 ? (
                stores.companyAvatarStore.sharedAvatarsWithUser.map(
                  (avatar) => (
                    <SharedAvatarItem
                      onStartChatWithAvatarPress={
                        this.startChatWithSharedAvatar
                      }
                      onDeletePress={this.revokeSharedAvatar}
                      avatar={avatar}
                    />
                  )
                )
              ) : (
                <div className="my-5">You don't have any shared avatars.</div>
              )}

              <div className="separator mb-3 opacity-75"></div>

              <Modal
                show={showAvatarDeleteConfirmation}
                onHide={() =>
                  this.setState({ showAvatarDeleteConfirmation: false })
                }
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Avatar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this avatar?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      analytics.trackEvent("cancel_avatar_delete_modal", {
                        category: "avatar_events",
                        label: "avatar_delete_modal_closed",
                      });
                      this.setState({ showAvatarDeleteConfirmation: false });
                    }}
                    disabled={!!loadingAvatarId}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      analytics.trackEvent("avatar_delete_confirm_pressed", {
                        category: "avatar_events",
                        label: "avatar_deleted",
                      });
                      this.confirmAvatarDelete();
                    }}
                    disabled={!!loadingAvatarId}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showSharedAvatarRevokeConfirmation}
                onHide={() =>
                  this.setState({ showSharedAvatarRevokeConfirmation: false })
                }
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Revoke Shared Avatar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to revoke this shared avatar?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      analytics.trackEvent(
                        "cancel_shared_avatar_revoke_modal",
                        {
                          category: "avatar_events",
                          label: "shared_avatar_revoke_modal_closed",
                        }
                      );
                      this.setState({
                        showSharedAvatarRevokeConfirmation: false,
                      });
                    }}
                    disabled={!!loadingAvatarId}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      analytics.trackEvent(
                        "shared_avatar_revoke_confirm_pressed",
                        {
                          category: "avatar_events",
                          label: "shared_avatar_revoked",
                        }
                      );
                      this.confirmSharedAvatarRevoke();
                    }}
                    disabled={!!loadingAvatarId}
                  >
                    Revoke
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AvatarsPage;
