import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useNavigate, useLocation } from "react-router-dom";
import stores from "../../../../app/stores";
import { UserDocument } from "../../../../app/models/UserDocument";
import { KTIcon } from "../../../../helpers";
import { DocumentActionModal } from "./document-action-modal";
import { toast } from "react-toastify";
import i18n from "../../../../i18n";

const DocumentsTab = observer(() => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [renamingDocumentId, setRenamingDocumentId] = useState<number | null>(
    null
  );
  const [renamingText, setRenamingText] = useState("");
  const documentRefs = useRef<{
    [key: number]: React.RefObject<HTMLDivElement>;
  }>({});
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [hoveredDocumentId, setHoveredDocumentId] = useState<number | null>(
    null
  );
  const [overflowingTexts, setOverflowingTexts] = useState<{
    [key: number]: boolean;
  }>({});

  useEffect(() => {
    return () => {
      documentRefs.current = {};
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        window.location.pathname !== "/chat"
      ) {
        setTimeout(() => {
          if (stores.userDocumentStore.selectedUserDocument) {
            stores.userDocumentStore.lastSelectedDocumentId =
              stores.userDocumentStore.selectedUserDocument.id;
          }
          stores.userDocumentStore.selectedUserDocument = undefined;
        }, 0);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/chat" &&
      stores.userDocumentStore.lastSelectedDocumentId !== undefined
    ) {
      const lastSelectedDocument = stores.userDocumentStore.userDocuments.find(
        (doc) => doc.id === stores.userDocumentStore.lastSelectedDocumentId
      );
      if (lastSelectedDocument) {
        stores.userDocumentStore.selectedUserDocument = lastSelectedDocument;
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    Object.keys(documentRefs.current).forEach((key) => {
      const ref = documentRefs.current[Number(key)].current;
      if (ref) {
        setOverflowingTexts((prev) => ({
          ...prev,
          [Number(key)]: ref.scrollWidth > ref.clientWidth,
        }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores.userDocumentStore.filteredAndSortedUserDocuments]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    stores.userDocumentStore.filterAndSortDocuments(e.target.value);
  };

  const handleNewClick = async () => {
    if (!stores.userStore.currentUser?.is_onboarded) {
      toast.error(i18n.ToastMessages.onboardingRequiredError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (
      stores.companyModelStore.companyModels.length === 0 &&
      stores.userStore.isCurrentUserAdmin
    ) {
      toast.error(i18n.ToastMessages.companyModelRequiredError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (
      stores.companyAvatarStore.companyAvatars.length === 0 &&
      !stores.userStore.isCurrentUserAdmin
    ) {
      toast.error(i18n.ToastMessages.companyAvatarRequiredError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (stores.companyAppStore.companyApps.length === 0) {
      toast.error(i18n.ToastMessages.companyAppRequiredError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setIsLoading(true);
    if (window.location.pathname !== "/chat") {
      navigate("/chat");
    }
    await stores.userDocumentStore.createUserDocument("New Chat");
    setIsLoading(false);
  };

  const handleDocumentClick = (userDocument: UserDocument) => {
    if (window.location.pathname !== "/chat") {
      navigate("/chat");
    }
    stores.userDocumentStore.selectedUserDocument = userDocument;
    stores.userDocumentStore.lastSelectedDocumentId = userDocument.id;
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    const text = e.target.innerText;
    if (text.length <= 80) {
      setRenamingText(text);
    } else {
      e.target.innerText = renamingText;
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleRenameClick = (userDocument: UserDocument) => {
    setRenamingDocumentId(userDocument.id);
    setTimeout(() => {
      const currentElement = documentRefs.current[userDocument.id]?.current;
      if (currentElement) {
        currentElement.focus();
        const range = document.createRange();
        const sel = window.getSelection();
        if (sel) {
          range.selectNodeContents(currentElement);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    }, 0);
  };

  const handleDocumentNameChange = (
    e: React.ChangeEvent<HTMLDivElement>,
    userDocument: UserDocument
  ) => {
    if (renamingText.trim() === "") {
      setRenamingText(userDocument.name);
    } else {
      userDocument.name = renamingText;
      stores.userDocumentStore.updateUserDocument(userDocument);
    }
    setRenamingDocumentId(null);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
      e.preventDefault();
    }
  };

  const renderUserDocumentItem = (
    userDocument: UserDocument,
    index: number
  ) => {
    if (!documentRefs.current[userDocument.id]) {
      documentRefs.current[userDocument.id] = React.createRef();
    }

    const isHovered = hoveredDocumentId === userDocument.id;
    const isTextOverflowing = overflowingTexts[userDocument.id] || false;

    return (
      <div
        key={userDocument.id}
        style={{
          cursor: "pointer",
          borderRadius: 5,
          position: "relative",
        }}
        onMouseEnter={() => setHoveredDocumentId(userDocument.id)}
        onMouseLeave={() => setHoveredDocumentId(null)}
        className={`d-flex flex-stack ${
          stores.userDocumentStore.selectedUserDocument?.id === userDocument.id
            ? "bg-light-primary"
            : isHovered
            ? "bg-light-primary"
            : ""
        }`}
      >
        <div
          className="d-flex w-100 py-4 px-2 align-items-center"
          onClick={() => handleDocumentClick(userDocument)}
        >
          <div className="symbol symbol-45px symbol-circle">
            <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
              {userDocument.name.charAt(0).toUpperCase()}
            </span>
          </div>

          <div className="ms-3">
            <div
              ref={documentRefs.current[userDocument.id]}
              style={{
                cursor:
                  renamingDocumentId === userDocument.id ? "text" : "pointer",
                width:
                  renamingDocumentId === userDocument.id ? "275px" : "290px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                position: "relative",
                display: "inline-block",
                backgroundColor:
                  renamingDocumentId === userDocument.id
                    ? "rgba(255, 255, 255, 0.5)"
                    : "transparent",

                padding:
                  renamingDocumentId === userDocument.id ? "4px 4px" : "0",
                borderRadius:
                  renamingDocumentId === userDocument.id ? "0px" : "0",

                maskImage:
                  isTextOverflowing && renamingDocumentId !== userDocument.id
                    ? "linear-gradient(to right, black 75%, transparent 100%)"
                    : "none",
                WebkitMaskImage:
                  isTextOverflowing && renamingDocumentId !== userDocument.id
                    ? "linear-gradient(to right, black 75%, transparent 100%)"
                    : "none",
              }}
              contentEditable={renamingDocumentId === userDocument.id}
              onKeyDown={handleKeyPress}
              onBlur={(e) => handleDocumentNameChange(e, userDocument)}
              onPaste={handlePaste}
              onInput={handleTextChange}
              className="fs-5 fw-bolder text-gray-900 mb-1 mt-1"
              suppressContentEditableWarning={true}
            >
              {decodeURIComponent(userDocument.name)}
            </div>
          </div>
        </div>

        <button
          className="btn btn-sm btn-icon bg-light-primary p-5"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
          style={{
            opacity: isHovered ? 1 : 0,
            transition: "opacity 0.5s ease",
            marginLeft: "-35px",
            zIndex: 1,
          }}
        >
          <i className="bi bi-three-dots fs-2"></i>
        </button>
        <DocumentActionModal
          userDocument={userDocument}
          onRename={() => handleRenameClick(userDocument)}
        />
      </div>
    );
  };

  return (
    <div className="m-0" ref={sidebarRef}>
      <div className="d-flex mb-10 w-100 position-relative">
        <KTIcon
          iconName="magnifier"
          className="fs-2 text-lg-3 text-gray-800 position-absolute top-50 translate-middle-y ms-5"
        />

        <input
          type="text"
          className="search-input form-control form-control-solid ps-13"
          name="search"
          placeholder="Search..."
          data-kt-search-element="input"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      <div className="m-0">
        <div className="d-flex align-items-center mb-4">
          <h1 className="text-gray-800 mx-5 flex-row-fluid">Chats</h1>

          <button
            onClick={handleNewClick}
            type="button"
            className="btn btn-primary"
            disabled={isLoading}
          >
            {isLoading ? (
              "Creating "
            ) : (
              <KTIcon iconName="plus" className="fs-2" />
            )}
            New Chat
          </button>
        </div>

        <div className="mb-10">
          {stores.userDocumentStore.filteredAndSortedUserDocuments.map(
            (userDocument, index) => renderUserDocumentItem(userDocument, index)
          )}
        </div>
      </div>
    </div>
  );
});

export default DocumentsTab;
