import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { toAbsoluteUrl } from "../../../helpers";
import analytics from "../../../helpers/Analytics";
import stores from "../../stores";
import { AvatarType } from "../../models/AvatarType";

import { AvatarLoading } from "./loading/AvatarLoading";

interface NameAvatarStepProps {
  onBack: () => void;
  onSave: () => void;
  selectedAppIds: number[];
  isEditMode: boolean;
}

const NameAvatarStep: React.FC<NameAvatarStepProps> = ({
  onBack,
  onSave,
  isEditMode,
  selectedAppIds,
}) => {
  const { editingAvatar } = stores.wizardStore;

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [promptTemplate, setPromptTemplate] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedLogoId, setSelectedLogoId] = useState<number | null>(null);
  const [selectedAvatarType, setSelectedAvatarType] = useState<AvatarType>(
    stores.companyAvatarStore.avatarTypes[0]
  );

  const { selectedUserCompany } = stores.companyStore;
  const company_id = selectedUserCompany?.id;
  const logos = useMemo(
    () => [
      {
        id: 1,
        url: "/media/avatar-logos/customer_service_avatar.png",
        name: "Customer Service",
      },
      { id: 2, url: "/media/avatar-logos/exports_avatar.png", name: "Exports" },
      { id: 3, url: "/media/avatar-logos/finance_avatar.png", name: "Finance" },
      {
        id: 4,
        url: "/media/avatar-logos/hr_avatar.png",
        name: "Human Resources",
      },
      { id: 5, url: "/media/avatar-logos/it_avatar.png", name: "IT" },
      {
        id: 6,
        url: "/media/avatar-logos/randd_avatar.png",
        name: "Research and Development",
      },
      { id: 7, url: "/media/avatar-logos/sales_avatar.png", name: "Sales" },
      {
        id: 8,
        url: "/media/avatar-logos/software_developement_avatar.png",
        name: "Software Development",
      },
    ],
    []
  );

  useEffect(() => {
    if (isEditMode && editingAvatar) {
      setName(editingAvatar.name);
      setSelectedAvatarType(
        stores.companyAvatarStore.avatarTypes.find(
          (type) => type.id.toString() === editingAvatar.type_id.toString()
        )!
      );
      setDescription(editingAvatar.description || "");
      setPromptTemplate(editingAvatar.prompt_template || "");

      const currentLogo = logos.find(
        (logo) => toAbsoluteUrl(logo.url) === editingAvatar.logo_url
      );
      if (currentLogo) {
        setSelectedLogoId(currentLogo.id);
      }
    }
  }, [editingAvatar, isEditMode, logos]);

  const resetFields = () => {
    setName("");
    setDescription("");
    setPromptTemplate("");
    setSelectedLogoId(null);
  };

  const createAvatar = async () => {
    const logoUrl = logos.find((logo) => logo.id === selectedLogoId)?.url || "";

    try {
      if (!company_id) {
        throw new Error("Company ID or selected model is undefined.");
      }

      const companyAvatar = await stores.companyAvatarStore.createCompanyAvatar(
        Number(company_id),
        stores.companyAvatarStore.dashboard_section_id ?? null,
        name.trim(),
        selectedAvatarType.id,
        description.trim(),
        promptTemplate.trim(),
        logoUrl,
        selectedAppIds
      );

      if (!companyAvatar) {
        throw new Error("Failed to create Avatar.");
      }

      resetFields();
      onSave();
    } catch (error) {
      setError("An error occurred during avatar creation.");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    stores.companyAvatarStore.isAvatarLoading = true;

    if (!selectedLogoId) {
      setError("Please select a logo.");
      setLoading(false);
      stores.companyAvatarStore.isAvatarLoading = false;
      return;
    }

    const logoUrl = logos.find((logo) => logo.id === selectedLogoId)?.url || "";

    if (isEditMode && editingAvatar) {
      try {
        const companyAvatar = {
          id: Number(editingAvatar.id),
          company_id: Number(company_id),
          name: name.trim(),
          type_id: selectedAvatarType.id,
          description: description.trim(),
          prompt_template: promptTemplate.trim(),
          logo_url: logoUrl,
          status: editingAvatar.status,
          last_sync_at: editingAvatar.last_sync_at,
        };

        const updatedAvatar =
          await stores.companyAvatarStore.updateCompanyAvatar(
            companyAvatar,
            selectedAppIds
          );

        if (updatedAvatar) {
          stores.companyAvatarStore.getCompanyAvatars();

          resetFields();
          onSave();
        } else {
          throw new Error("Failed to update avatar.");
        }
      } catch (error) {
        setError("Failed to update avatar.");
      }
    } else {
      await createAvatar();
    }
    setLoading(false);
    stores.companyAvatarStore.isAvatarLoading = false;
  };

  return (
    <form
      className="form d-flex flex-column justify-content-center align-items-center mt-15 mb-20"
      onSubmit={handleSubmit}
      style={{ height: "860px", position: "relative" }}
    >
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
          }}
        >
          <AvatarLoading />
        </div>
      ) : (
        <div className="w-100">
          <div className="w-100 mt-15 mb-7">
            <h4 className="fw-bold fs-2">Avatar Info</h4>
            <p className="text-muted fs-6">
              If you need more apps, please check out{" "}
              <Link
                to="/avatars"
                className="text-primary fw-bold"
                onClick={() =>
                  analytics.trackEvent("go_avatar_page_button_pressed", {
                    category: "avatar_events",
                    label: "go_avatar_page_button",
                  })
                }
              >
                Avatars Page.
              </Link>
            </p>
          </div>

          <div className="" style={{ height: "auto" }}>
            <div className="mb-10">
              <label className="fw-bolder fs-6 ms-1 mb-3">Avatar Name:</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Avatar Name (Required)"
                required
                disabled={loading}
              />
            </div>

            <div className="mb-10">
              <label className="fw-bolder fs-6 ms-1 mb-3">Description:</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter Description (Optional)"
                disabled={loading}
              />
            </div>

            <div className="mb-10">
              <label className="fw-bolder fs-6 ms-1 mb-3">Type:</label>

              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                onChange={(e) => {
                  const foundType = stores.companyAvatarStore.avatarTypes.find(
                    (type) => type.id.toString() === e.target.value
                  );

                  if (foundType) {
                    setSelectedAvatarType(foundType);
                  }
                }}
                value={selectedAvatarType.id}
                onClick={(e) => e.stopPropagation()}
              >
                {stores.companyAvatarStore.avatarTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.type.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>

            <div className="fv-row mb-10">
              <label className="fw-bolder fs-6 ms-1 mb-3">
                Prompt Template
              </label>
              <p className="ms-1 text-muted fs-6">
                You can enter your custom prompt template in this field to
                ensure more consistent and personalized responses from your
                avatar.
              </p>

              <textarea
                name="promptTemplate"
                value={promptTemplate}
                onChange={(e) => setPromptTemplate(e.target.value)}
                placeholder="Enter Prompt Template (Optional)"
                className="form-control form-control-lg form-control-solid"
                rows={8}
              ></textarea>
            </div>

            <div className="mb-10">
              <label className="fw-bolder fs-6 ms-1 mb-3">Select a Logo:</label>
              <div className="d-flex flex-wrap">
                {logos.map((logo) => (
                  <div key={logo.id} className="m-2">
                    <img
                      src={toAbsoluteUrl(logo.url)}
                      alt={logo.name}
                      className={`${
                        selectedLogoId === logo.id
                          ? "border border-primary border-3 border-dotted border-light"
                          : "border border-gray-300  border-dashed border-light"
                      }`}
                      style={{
                        cursor: "pointer",
                        width: "75px",
                        height: "75px",
                        borderRadius: "15px",
                      }}
                      onClick={() => {
                        if (!loading) {
                          setSelectedLogoId(logo.id);
                          analytics.trackEvent("select_avatar_logo", {
                            category: "avatar_events",
                            label: "select_avatar_logo_button",
                          });
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            {error && (
              <div className="row mb-10">
                <div className="col-lg-9 col-xl-4 offset-lg-3">
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className="d-flex justify-content-between w-100"
        style={{ marginTop: "20px" }}
      >
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            analytics.trackEvent("back_avatar_info_step", {
              category: "avatar_events",
              label: "back_avatar_info_step_button",
            });
            onBack();
          }}
          disabled={loading}
        >
          <i className="ki-duotone ki-arrow-left fs-2">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          Back
        </button>

        <button
          type="submit"
          className="btn btn-primary"
          disabled={!name.trim() || loading}
          onClick={() => {
            analytics.trackEvent(
              isEditMode
                ? "update_avatar_button_pressed"
                : "save_avatar_button_pressed",
              {
                category: "avatar_events",
                label: isEditMode
                  ? "update_avatar_button"
                  : "save_avatar_button",
              }
            );
          }}
        >
          {loading
            ? isEditMode
              ? "Updating Avatar..."
              : "Saving Avatar..."
            : isEditMode
            ? "Update"
            : "Save"}
        </button>
      </div>
    </form>
  );
};

export default observer(NameAvatarStep);
