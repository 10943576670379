import { Edge, Node, addEdge } from "@xyflow/react";
import { toast } from "react-toastify";

import { handleEdgeUpdate } from "./EdgeUtils";
import i18n from "../../../../i18n";

export const handleConnection = (
  params: Edge,
  nodes: Node[],
  edges: Edge[],
  setEdges: (edges: Edge[]) => void,
  setNodes: (nodes: Node[]) => void,
  validateConnection: (
    nodes: Node[],
    sourceHandleId: string,
    targetHandleId: string,
    sourceId: string,
    targetId: string
  ) => boolean
): void => {
  const { sourceHandle, targetHandle, source, target } = params;
  const sourceNode = nodes.find((node) => node.id === source);
  const targetNode = nodes.find((node) => node.id === target);

  const isEmptyText = (node?: Node) =>
    node?.type === "questionNode" &&
    (typeof node.data?.text !== "string" || node.data.text.trim() === "");

  if (isEmptyText(sourceNode) || isEmptyText(targetNode)) {
    toast.error(i18n.ToastMessages.EmptyQuestionConnectionError, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeButton: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return;
  }

  const sourceConnectionCount = edges.find(
    (edge) => edge.sourceHandle === sourceHandle && edge.source === source
  );
  const targetConnectionCount = edges.find(
    (edge) => edge.targetHandle === targetHandle && edge.target === target
  );

  if (sourceConnectionCount) {
    toast.error(i18n.ToastMessages.DuplicateSourceError, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeButton: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.error("This source is already used!");
  } else if (targetConnectionCount) {
    toast.error(i18n.ToastMessages.DuplicateTargetError, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeButton: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else if (
    validateConnection(nodes, sourceHandle!, targetHandle!, source, target)
  ) {
    const newEdges = addEdge(params, edges);
    handleEdgeUpdate(nodes, edges, params, newEdges, setNodes, setEdges);
    setEdges(newEdges);
  } else {
    toast.error(i18n.ToastMessages.InvalidConnectionError, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeButton: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};
