const AvatarLoading = () => {
  const styles = {
    borderRadius: "0.475rem",
    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
    // backgroundColor: "#fff",
    color: "#7e8299",
    fontWeight: "500",
    margin: "0",
    width: "auto",
    padding: "1rem 2rem",
    top: "calc(40% - 2rem)",
    left: "calc(50% - 4rem)",
  };

  return (
    <div
      style={{ ...styles, position: "absolute", textAlign: "center" }}
      role="status"
    >
      <span className="spinner-border text-primary mb-3" role="status"></span>
      <div>Loading...</div>
    </div>
  );
};

export { AvatarLoading };
