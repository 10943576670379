import { gql } from "@apollo/client";
import ApolloClientHelper from "../ApolloClientHelper";
import type { CompanyAvatar } from "../../app/models/CompanyAvatar";
import { get } from "lodash";

async function createCompanyAvatar(
  company_id: number,
  dashboard_section_id: number | null,
  name: string,
  type_id: number,
  description: string,
  prompt_template: string,
  logo_url: string,
  status: string,
  last_sync_at: number
): Promise<CompanyAvatar> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation CreateCompanyAvatar(
      $company_id: Int!
      $dashboard_section_id: Int
      $name: String!
      $type_id: Int!
      $description: String
      $prompt_template: String
      $logo_url: String
      $status: String!
      $last_sync_at: Float
    ) {
      createCompanyAvatar(
        company_id: $company_id
        dashboard_section_id: $dashboard_section_id
        name: $name
        type_id: $type_id
        description: $description
        prompt_template: $prompt_template
        logo_url: $logo_url
        status: $status
        last_sync_at: $last_sync_at
      ) {
        id
        company_id
        dashboard_section_id
        name
        type_id
        description
        prompt_template
        logo_url
        status
        last_sync_at
      }
    }
  `;

  try {
    const { data } = await client.mutate({
      mutation,
      variables: {
        company_id,
        dashboard_section_id,
        name,
        type_id,
        description,
        prompt_template,
        logo_url,
        last_sync_at,
        status,
      },
    });

    const companyAvatar = get(data, "createCompanyAvatar", null);

    if (companyAvatar) {
      return companyAvatar;
    } else {
      throw new Error("Failed to create CompanyAvatar.");
    }
  } catch (error) {
    console.error("Error creating CompanyAvatar:", error);
    throw error;
  }
}

export default createCompanyAvatar;
