import React, { useState } from "react";

import { PageLink, PageTitle } from "../../../layout/core";
import { cancelSubscription, deleteAccount } from "../../../helpers/api";
import analytics from "../../../helpers/Analytics";
import stores from "../../stores";

import { AccountLoading } from "./components/loading/AccountLoading";
import DeleteAccountModal from "./components/DeleteAccountModal";
import Overview from "./components/Overview";
import Settings from "./components/Settings";
import { AccountHeader } from "./AccountHeader";

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "Account",
    path: "/account",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const AccountPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("settings");
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleDeleteAccount = async () => {
    setLoading(true);

    analytics.trackEvent("delete_account_button_pressed", {
      category: "user_profile_events",
      label: "delete_account_button",
    });

    try {
      const userId = stores.userStore.currentUser.id;
      const companyId = stores.companyStore.selectedUserCompany?.id;
      const companyStorageId =
        stores.companyStorageStore.selectedCompanyStorage?.id;

      if (!companyId || !userId || companyStorageId === undefined) {
        console.error(
          "Required data is missing: userId, companyId, or companyStorageId."
        );
        return;
      }

      const subscriptionId = stores.userStore.currentUser.subscription?.id;
      if (subscriptionId) {
        await cancelSubscription(subscriptionId);
      }

      await deleteAccount(userId, companyId, companyStorageId);

      analytics.trackEvent("account_deleted", {
        category: "user_profile_events",
        label: "account_deleted_success",
      });

      setShowDeleteModal(false);
      stores.userStore.logout();
    } catch (error) {
      console.error("Error deleting account:", error);
      analytics.trackEvent("account_deletion_failed", {
        category: "user_profile_events",
        label: "account_deletion_failed_error",
      });
    }
  };

  if (loading) {
    return (
      <div className="card mb-5 mb-xl-10">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <AccountLoading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <AccountHeader selectedTab={selectedTab} onTabChange={handleTabChange} />

      <PageTitle breadcrumbs={accountBreadCrumbs}>
        {selectedTab === "settings" ? "Settings" : "Overview"}
      </PageTitle>

      {selectedTab === "settings" ? (
        <Settings
          onShowDeleteModal={() => {
            analytics.trackEvent("open_delete_account_modal", {
              category: "user_profile_events",
              label: "delete_account_modal_opened",
            });

            setShowDeleteModal(true);
          }}
        />
      ) : (
        <Overview />
      )}

      <DeleteAccountModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDeleteAccount}
        loading={loading}
      />
    </div>
  );
};

export default AccountPage;
