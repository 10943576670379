import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Joyride from "react-joyride";

import analytics from "../../../helpers/Analytics";
import { FeatureType } from "../../../helpers/Enums";
import { getDocumentMessages } from "../../../helpers/api";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { UserDocument } from "../../models/UserDocument";
import { CompanyModel } from "../../models/CompanyModel";
import { CompanyAvatar } from "../../models/CompanyAvatar";
import { SharedCompanyAvatar } from "../../models/SharedCompanyAvatar";
import stores from "../../stores";
import { AccountLoading } from "../accounts/components/loading/AccountLoading";
import { ModelAvatarSelectionModal } from "./model-avatar-selection-modal";
import ChatInner from "./ChatInner";

type Props = {
  userDocument?: UserDocument;
};

type State = {
  selectedCompanyModel?: CompanyModel;
  selectedCompanyAvatar?: CompanyAvatar;
  userDocument?: UserDocument;
  canUseTheChief: boolean;
  canUseLiveAvatar: boolean;
  hasAvatars: boolean;
  isElectron: boolean;
  joyrideSteps: any;
  loading: boolean;
};

@observer
export default class ChatPage extends React.Component<Props, State> {
  private chatInnerRef: any = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      canUseTheChief: false,
      canUseLiveAvatar: false,
      hasAvatars: false,
      isElectron: false,
      loading: true,

      joyrideSteps: [
        {
          target: "#modelSelectionButton",
          title: "Select Model or Avatar",
          content:
            "You can choose any of the connected models or created Avatars to interact with. When selecting a model, make sure to mention an app to direct your questions through it, ensuring the use of the appropriate model for that app. When using an Avatar, you can ask questions without mentioning an app.",
        },
        {
          target: "#mentionInput",
          title: "Input",
          content:
            "Use this section to ask any question you desire. Remember, if you select a model, you must mention the relevant app (e.g., @Slack).",
        },
        {
          target: "#videochatButtonId",
          title: "Video Chat",
          content:
            "Engage in a video chat experience with an AI-powered avatar.",
        },
        {
          target: "#voicechatButtonId",
          title: "Voice Chat",
          content:
            "You can have a voice conversation with the AI using this feature.",
        },
        {
          target: "#downloadButton",
          title: "Download Conversation",
          content: "You can export your conversation as a document.",
        },
        {
          target: "#webSearchButton",
          title: "Web Search",
          content:
            "You can perform a web search by including a URL with your question to retrieve real-time information from the specified webpage.",
        },
        {
          target: ".cc-1m2mf",
          title: "Helpdesk",
          content: "Feel free to contact us anytime you encounter an issue.",
        },
      ],
    };
  }

  componentWillMount(): void {
    this.setState({ isElectron: window.electronAPIs?.isElectron ?? false });
  }

  async componentWillReceiveProps(nextProps: any) {
    if (!nextProps.userDocument) {
      this.setState({
        userDocument: undefined,
        loading: false,
      });
    } else {
      if (
        !this.state.userDocument ||
        this.state.userDocument.id !== nextProps.userDocument.id
      ) {
        const messages = await getDocumentMessages(nextProps.userDocument.id);

        if (Array.isArray(messages) && messages.length > 0) {
          const lastMessage = messages[messages.length - 1];

          const matchingModel = stores.companyModelStore.companyModels.find(
            (model) =>
              model.id.toString() === lastMessage.company_model_id?.toString()
          );

          this.setState({
            userDocument: nextProps.userDocument,
            selectedCompanyAvatar: lastMessage.company_avatar
              ? lastMessage.company_avatar
              : undefined,
            selectedCompanyModel: matchingModel ? matchingModel : undefined,
            loading: false,
          });
        }
        if (messages.length === 0) {
          this.setState({
            userDocument: nextProps.userDocument,
            selectedCompanyAvatar: undefined,
            selectedCompanyModel: undefined,
            loading: false,
          });
        }

        if (stores.companyAvatarStore.selectedCompanyAvatar) {
          this.setState({
            userDocument: nextProps.userDocument,
            selectedCompanyAvatar:
              stores.companyAvatarStore.selectedCompanyAvatar,
            selectedCompanyModel: undefined,
            loading: false,
          });
          stores.companyAvatarStore.selectedCompanyAvatar = undefined;
        }
      }

      const canUseTheChief = await stores.userStore.checkSubscribedFeatureType(
        FeatureType.TheChief
      );
      const canUseLiveAvatar =
        await stores.userStore.checkSubscribedFeatureType(
          FeatureType.LiveAvatar
        );
      const hasAvatars = stores.companyAvatarStore.companyAvatars.length > 0;

      this.setState({ canUseTheChief, canUseLiveAvatar, hasAvatars }, () => {
        if (
          !canUseTheChief &&
          !this.state.selectedCompanyModel &&
          !this.state.selectedCompanyAvatar
        ) {
          if (stores.userStore.isCurrentUserAdmin) {
            if (stores.companyModelStore.companyModels.length > 0) {
              this.setState({
                selectedCompanyModel: stores.companyModelStore.companyModels[0],
                loading: false,
              });
            }
          } else {
            if (stores.companyAvatarStore.companyAvatars.length > 0) {
              this.setState({
                selectedCompanyAvatar:
                  stores.companyAvatarStore.companyAvatars[0],
                loading: false,
              });
            }
          }
        }
      });
    }
  }

  private handleStartChat = async () => {
    try {
      stores.userStore.currentUser.is_onboarded = true;

      await stores.userStore.updateUserData();

      const newUserDocument = await stores.userDocumentStore.createUserDocument(
        "New Chat"
      );

      stores.userDocumentStore.selectedUserDocument = newUserDocument;
      analytics.trackEvent("start_new_chat", {
        category: "chat_events",
        label: "new_chat_started",
      });
    } catch (error) {
      console.error("Error while starting chat:", error);
    }
  };

  private handleCompanyModelSelect = async (
    selectedCompanyModel?: CompanyModel
  ) => {
    this.setState({ selectedCompanyModel, selectedCompanyAvatar: undefined });
    analytics.trackEvent("select_company_model", {
      category: "chat_events",
      label: `selected_model_${selectedCompanyModel?.id}`,
    });
  };

  private handleCompanyAvatarSelect = async (
    selectedCompanyAvatar?: CompanyAvatar
  ) => {
    this.setState({ selectedCompanyModel: undefined, selectedCompanyAvatar });
    analytics.trackEvent("select_company_avatar", {
      category: "chat_events",
      label: `selected_avatar_${selectedCompanyAvatar?.id}`,
    });
  };

  private handleSharedAvatarSelect = async (
    selectedSharedAvatar?: SharedCompanyAvatar
  ) => {
    this.setState({
      selectedCompanyModel: undefined,
      selectedCompanyAvatar: selectedSharedAvatar?.company_avatar,
    });
  };

  private renderOnboardingPage = () => {
    const { name } = stores.userStore.currentUser || {};
    const userName = name || "User";

    const companyApps = stores.companyAppStore.companyApps;
    const companyModels = stores.companyModelStore.companyModels;

    const isAppConnected = companyApps && companyApps.length > 0;
    const isModelConnected = companyModels && companyModels.length > 0;

    return (
      <div className="container py-10">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body p-lg-5">
                <h2 className="fw-bold mb-7">Welcome {userName},</h2>
                <p className="fw-semibold fs-5 text-gray-800">
                  To start using our chat, please connect at least one model and
                  one app.
                </p>

                <div className="d-flex align-items-center mb-5">
                  <div>
                    {isModelConnected ? (
                      <KTSVG
                        path="/media/icons/duotune/general/gen043.svg"
                        className="svg-icon-2hx svg-icon-success me-4"
                      />
                    ) : (
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-2hx svg-icon-gray-400 me-4"
                      />
                    )}
                  </div>
                  <div>
                    <h4 className="fw-bold mb-1">1) Connect a CompanyModel</h4>
                    <p className="text-gray-600 mb-1">
                      First, you need to connect at least one of the models
                      listed on this page by entering the required information.
                    </p>
                    <Link
                      to="/models"
                      className="btn btn-sm btn-light btn-color-gray-800 btn-outline py-2 mb-2 mt-2"
                      onClick={() => {
                        analytics.trackEvent("manage_models_button_pressed", {
                          category: "chat_events",
                          label: "manage_models_button",
                        });
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/technology/teh003.svg"
                        className="svg-icon-3 me-2"
                      />
                      Manage Models
                    </Link>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-5">
                  <div>
                    {isAppConnected ? (
                      <KTSVG
                        path="/media/icons/duotune/general/gen043.svg"
                        className="svg-icon-2hx svg-icon-success me-4"
                      />
                    ) : (
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-2hx svg-icon-gray-400 me-4"
                      />
                    )}
                  </div>
                  <div>
                    <h4 className="fw-bold mb-1">2) Connect a CompanyApp</h4>
                    <p className="text-gray-600 mb-1">
                      From this section, you need to connect at least one of the
                      applications listed in our app store. You can track the
                      process through toast messages, and we will send you a
                      notification email once the process is complete.
                    </p>
                    <Link
                      to="/connections"
                      className="btn btn-sm btn-light btn-color-gray-800 btn-outline py-2 mb-2 mt-2"
                      onClick={() => {
                        analytics.trackEvent(
                          "manage_connections_button_pressed",
                          {
                            category: "chat_events",
                            label: "manage_connections_button",
                          }
                        );
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen008.svg"
                        className="svg-icon-3 me-2"
                      />
                      Manage Connections
                    </Link>
                  </div>
                </div>

                <div className="text-center mt-10">
                  <button
                    className="btn btn-primary fs-6 px-8 py-3"
                    disabled={!isAppConnected || !isModelConnected}
                    onClick={this.handleStartChat}
                  >
                    Start Chat
                  </button>
                  <div className="form-text mt-4">
                    To start using the chat, please complete the setup by
                    connecting at least one model and one app. Once connected,
                    click "Start Chat" to proceed.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      userDocument,
      selectedCompanyModel,
      selectedCompanyAvatar,
      canUseTheChief,
      canUseLiveAvatar,
      hasAvatars,
      isElectron,
      joyrideSteps,
      loading,
    } = this.state;

    console.log(stores.companyAvatarStore.companyAvatars, "kendi");
    console.log(stores.companyAvatarStore.sharedAvatarsWithUser, "share");

    if (loading) {
      return (
        <div className="card mb-5 mb-xl-10">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh" }}
          >
            <AccountLoading />
          </div>
        </div>
      );
    }

    const currentUser = stores.userStore.currentUser;
    const isFreeUser = stores.userStore.isFreeUser;

    const allApps = stores.companyAppStore.apps.filter((app) => app.published);
    const allModels = stores.companyModelStore.models.filter(
      (model) => model.published
    );
    const allStorages = stores.companyStorageStore.storages.filter(
      (storage) => storage.published
    );
    const allAvatars = stores.companyAvatarStore.companyAvatars;

    const companyModels = stores.companyModelStore.companyModels;
    const companyApps = stores.companyAppStore.companyApps;
    const companyStorages = stores.companyStorageStore.companyStorages;

    const isOnboarded = currentUser?.is_onboarded;

    const connectedAppsDisplay = stores.userStore.isCurrentUserAdmin ? (
      <div
        className="container h-100"
        style={{
          marginTop: "10%",
        }}
      >
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-8">
            <div className="row mb-9">
              <div className="col">
                <h2>Hello {currentUser.name}!</h2>
              </div>
            </div>

            <div className="row">
              {/* Left Column: Models */}
              <div className="col mt-4">
                <h2>Model Providers</h2>
                <div className="symbol symbol-35px symbol-circle d-flex">
                  {(isElectron ? companyModels : allModels)
                    .slice(0, 4)
                    .map((model) => (
                      <div
                        key={model.id}
                        className="symbol symbol-35px symbol-circle me-1 ms-1 p-2 bg-light"
                      >
                        <img
                          style={{ objectFit: "contain" }}
                          src={toAbsoluteUrl(
                            "logo" in model ? model.logo : model.model.logo
                          )}
                          alt={`${
                            "name" in model ? model.name : model.model.name
                          } logo`}
                        />
                      </div>
                    ))}

                  {(isElectron ? companyModels : allModels).length > 4 && (
                    <div className="d-flex ms-1 flex-center">
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label fs-8 fw-bold bg-dark text-gray-300">
                          +{(isElectron ? companyModels : allModels).length - 4}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                {(isElectron ? companyModels : allModels).length === 0 && (
                  <p>No models to display.</p>
                )}

                <p
                  style={{ maxWidth: 240 }}
                  className="text-muted fw-semibold fs-8 mt-4 "
                >
                  Discover the power of artificial intelligence by establishing
                  the connection between models and your data.
                </p>

                {/* Manage Models Button */}
                {!isElectron && (
                  <Link
                    to="/models"
                    className="btn btn-sm btn-light btn-color-gray-800 btn-outline py-2 mb-6 mt-1"
                    onClick={() => {
                      analytics.trackEvent("manage_models_button_pressed", {
                        category: "chat_events",
                        label: "manage_models_button",
                      });
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/technology/teh003.svg"
                      className="svg-icon-3 me-2"
                    />
                    Manage Models
                  </Link>
                )}
              </div>

              {/* Right Column: Apps */}
              <div className="col mt-4">
                <h2>Connections</h2>
                <div className="symbol symbol-35px symbol-circle d-flex">
                  {(isElectron ? companyApps : allApps)
                    .slice(0, 4)
                    .map((app) => (
                      <div
                        key={app.id}
                        className="symbol symbol-35px symbol-circle me-1 ms-1 p-2 bg-light"
                      >
                        <img
                          style={{ objectFit: "contain" }}
                          src={toAbsoluteUrl(
                            "logo" in app ? app.logo : app.app.logo
                          )}
                          alt={`${
                            "name" in app ? app.name : app.app.name
                          } logo`}
                        />
                      </div>
                    ))}

                  {(isElectron ? companyApps : allApps).length > 4 && (
                    <div className="d-flex ms-1  flex-center">
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label fs-8 fw-bold bg-dark text-gray-300">
                          +{(isElectron ? companyApps : allApps).length - 4}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                {(isElectron ? companyApps : allApps).length === 0 && (
                  <p>No apps to display.</p>
                )}

                <p
                  style={{ maxWidth: 240 }}
                  className="text-muted fw-semibold fs-8 mt-4 "
                >
                  Manage your real-time data flows by establishing the
                  connection between your applications and CompanyDNA.
                </p>

                {/* Manage Connections Button */}
                {!isElectron && (
                  <Link
                    to="/connections"
                    className="btn btn-sm btn-light btn-color-gray-800 btn-outline py-2 mb-6 mt-1"
                    onClick={() => {
                      analytics.trackEvent(
                        "manage_connections_button_pressed",
                        {
                          category: "chat_events",
                          label: "manage_connections_button",
                        }
                      );
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/general/gen008.svg"
                      className="svg-icon-3 me-2"
                    />
                    Manage Connection
                  </Link>
                )}
              </div>
            </div>

            <div className="row">
              {/* Left Column: Storages */}
              <div className="col mt-4">
                <h2>Storages</h2>
                <div className="symbol symbol-35px symbol-circle d-flex">
                  {(isElectron ? companyStorages : allStorages)
                    .slice(0, 4)
                    .map((storage) => (
                      <div
                        key={storage.id}
                        className="symbol symbol-35px symbol-circle me-1 ms-1 p-2 bg-light"
                      >
                        <img
                          style={{ objectFit: "contain" }}
                          src={toAbsoluteUrl(
                            "logo" in storage
                              ? storage.logo
                              : storage.storage.logo
                          )}
                          alt={`${
                            "name" in storage
                              ? storage.name
                              : storage.storage.name
                          } logo`}
                        />
                      </div>
                    ))}
                  {(isElectron ? companyStorages : allStorages).length > 4 && (
                    <div className="d-flex ms-1 flex-center">
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label fs-8 fw-bold bg-dark text-gray-300">
                          +
                          {(isElectron ? companyStorages : allStorages).length -
                            4}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                {(isElectron ? companyStorages : allStorages).length === 0 && (
                  <p>No storage to display.</p>
                )}

                <p
                  style={{ maxWidth: 240 }}
                  className="text-muted fw-semibold fs-8 mt-4 "
                >
                  Manage the storage for your data structured through the
                  connection with CompanyDNA.
                </p>

                {/* Manage Storages Button */}
                {!isElectron && (
                  <Link
                    to="/storages"
                    className="btn btn-sm btn-light btn-color-gray-800 btn-outline py-2 mb-6 mt-1"
                    onClick={() => {
                      analytics.trackEvent(
                        "manage_connections_button_pressed",
                        {
                          category: "chat_events",
                          label: "manage_connections_button",
                        }
                      );
                    }}
                  >
                    <KTSVG
                      path="media/icons/duotune/graphs/gra010.svg"
                      className="svg-icon-3 me-2"
                    />
                    Manage Storages
                  </Link>
                )}
              </div>

              {/* Right Column: Avatars */}
              <div className="col mt-4">
                <h2>Avatars</h2>

                <div className="symbol symbol-35px symbol-circle d-flex">
                  {allAvatars.slice(0, 4).map((avatar) => (
                    <div
                      key={avatar.id}
                      className="symbol symbol-35px symbol-circle me-1 ms-1 p-2 bg-light"
                    >
                      <img
                        style={{ objectFit: "contain" }}
                        src={toAbsoluteUrl(avatar.logo_url)}
                        alt={`${avatar.name} logo`}
                      />
                    </div>
                  ))}
                  {allAvatars.length > 4 && (
                    <div className="d-flex ms-1 flex-center">
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label fs-8 fw-bold bg-dark text-gray-300">
                          +{allAvatars.length - 4}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                {allAvatars.length === 0 && <p>No avatars to display.</p>}

                <p
                  style={{ maxWidth: 240 }}
                  className="text-muted fw-semibold fs-8 mt-4 "
                >
                  Create a virtual management avatar, guiding employees to stay
                  aligned with their goals, providing valuable feedback, and
                  serving as a repository of company knowledge and objectives.
                </p>

                {/* Manage Avatars Button */}
                {!isElectron && (
                  <Link
                    to="/avatars"
                    className="btn btn-sm btn-light btn-color-gray-800 btn-outline py-2 mb-6 mt-1"
                    onClick={() => {
                      analytics.trackEvent("manage_avatars_button_pressed", {
                        category: "chat_events",
                        label: "manage_avatars_button",
                      });
                    }}
                  >
                    <KTSVG
                      path="media/icons/duotune/technology/teh002.svg"
                      className="svg-icon-3 me-2"
                    />
                    Manage Avatars
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="rounded text-muted fw-semibold m-0 p-4 position-absolute top-50 start-50 translate-middle text-center">
        <img
          src="https://imagedelivery.net/prp7br5UdCsJqvWC5hRwlA/b4d98b10-c21b-4560-d177-28f002687c00/mid"
          className="theme-dark-show h-100px "
          alt="logo"
        />
        <img
          src="https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/59272325-9202-4e14-ff5b-759ce0050b00/mid"
          alt="logo"
          className="theme-light-show h-100px "
        />
      </div>
    );
    if (!isOnboarded) {
      return <>{this.renderOnboardingPage()}</>;
    }

    let displayName = "";
    if (userDocument) {
      const fullName = decodeURIComponent(userDocument.name);
      displayName =
        fullName.length > 50 ? fullName.substring(0, 50) + "..." : fullName;
    }

    return (
      <div className="d-flex flex-column flex-lg-row">
        {userDocument ? (
          <div className="flex-lg-row-fluid ms-xl-10">
            {!stores.userStore.currentUser.settings.is_tour_part_2_completed ? (
              <Joyride
                steps={joyrideSteps}
                showProgress={true}
                continuous={true}
                disableScrolling={true}
                run={true}
                styles={{
                  tooltipTitle: {
                    fontSize: 15,
                    color:
                      document.body.getAttribute("data-theme") === "dark"
                        ? "var(--kt-text-inverse)"
                        : "var(--kt-text-color)",
                    fontWeight: 700,
                  },
                  tooltipContent: {
                    fontSize: 13,
                    textAlign: "left",
                    color:
                      document.body.getAttribute("data-theme") === "dark"
                        ? "var(--kt-text-inverse)"
                        : "var(--kt-text-color)",
                  },
                  buttonNext: {
                    backgroundColor: " var(--bs-primary)",
                    color: "var(--bs-primary-inverse)",
                    fontSize: 13,
                    fontWeight: 400,
                  },
                  buttonBack: {
                    color: "var(--bs-light-inverse)",
                    fontSize: 13,
                    fontWeight: 400,
                  },
                }}
                callback={(e) => {
                  if (
                    e.status === "finished" &&
                    !stores.userStore.currentUser.settings
                      .is_tour_part_2_completed
                  ) {
                    stores.userStore.currentUser.settings.is_tour_part_2_completed =
                      true;
                    stores.userStore.updateUserData();
                    analytics.trackEvent("tour_completed", {
                      category: "chat_events",
                      label: "user_completed_tour",
                    });
                  }
                }}
              />
            ) : null}

            <div
              className="container-xxl card"
              id="kt_chat_messenger"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="card-header" id="kt_chat_messenger_header">
                <div className="ms-n8 card-title flex-lg-row-fluid">
                  <div className="symbol-group symbol-hover"></div>
                  <div className="d-flex justify-content-center flex-column me-3">
                    <button
                      className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 btn btn-link"
                      onClick={() => {}}
                    >
                      {displayName}
                    </button>

                    <div className="mb-0 lh-1">
                      <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                      <span className="fs-7 fw-bold text-gray-400">Active</span>
                    </div>
                  </div>
                </div>

                <div className="card-toolbar">
                  <div className="d-flex align-items-center">
                    <span className="selectModel fs-7 fw-bold text-gray-800 me-4">
                      {stores.userStore.isCurrentUserAdmin
                        ? isFreeUser
                          ? "Select Model:"
                          : stores.companyAvatarStore.companyAvatars.length ===
                            0
                          ? "Select Model:"
                          : "Select Model or Avatar:"
                        : "Select Avatar:"}
                    </span>

                    <ModelAvatarSelectionModal
                      selectButtonId="modelSelectionButton"
                      userDocument={userDocument}
                      onCompanyModelSelect={this.handleCompanyModelSelect}
                      onCompanyAvatarSelect={this.handleCompanyAvatarSelect}
                      selectedCompanyModel={selectedCompanyModel}
                      selectedCompanyAvatar={selectedCompanyAvatar}
                      onSharedAvatarSelect={this.handleSharedAvatarSelect}
                    />
                  </div>
                </div>
              </div>

              <ChatInner
                ref={this.chatInnerRef}
                mentionInputId={"mentionInput"}
                downloadButtonId={"downloadButton"}
                webSearchButtonId={"webSearchButton"}
                userDocument={userDocument}
                selectedCompanyModel={selectedCompanyModel}
                selectedCompanyAvatar={selectedCompanyAvatar}
                canUseLiveAvatar={canUseLiveAvatar}
                canUseTheChief={canUseTheChief}
              />
            </div>
          </div>
        ) : stores.userStore.isCurrentUserAdmin || hasAvatars ? (
          connectedAppsDisplay
        ) : (
          <div className="rounded text-muted fw-semibold m-0 p-4 position-absolute top-50 start-50 translate-middle text-center">
            <img
              src="https://imagedelivery.net/prp7br5UdCsJqvWC5hRwlA/b4d98b10-c21b-4560-d177-28f002687c00/mid"
              className="theme-dark-show h-100px "
              alt="logo"
            />
            <img
              src="https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/59272325-9202-4e14-ff5b-759ce0050b00/mid"
              alt="logo"
              className="theme-light-show h-100px "
            />
          </div>
        )}
      </div>
    );
  }
}
