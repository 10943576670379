import ReactGA from "react-ga4";

const GOOGLE_ANALYTICS_ID = `${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}`;

class Analytics {
  private providers: any[] = [];

  constructor() {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    this.providers.push({
      name: "GoogleAnalytics",
      trackEvent: this.trackGAEvent,
      trackPage: this.trackGAPage,
    });
  }

  private getPageTitle(pathname: string): string {
    const pageTitleMap: Record<string, string> = {
      "/": "Home",
      "/dashboard": "CompanyDNA Dashboard",
      "/avatars": "Avatars",
      "/settings": "Settings",
      "/profile": "User Profile",
      "/error/404": "Page Not Found",
      "/create-new-avatar": "Create New Avatar",
      "/create-new-section-flow": "Create New Section Flow",
      "/success": "Success Page",
      "/cancel": "Cancel Page",
      "/stripe-redirect": "Stripe Payment Redirect",
      "/company-users": "Company Users",
      "/connections": "Company Connections",
      "/models": "Company Models",
      "/storages": "Company Storages",
      "/chat": "Chat",
      "/ai-manager": "AI Manager",
      "/tutorials": "Tutorials",
      "/account": "User Account",
      "/the-chief-workspace": "The Chief Dashboard",
      "/auth/*": "Authentication",
      "/error/*": "Error Page",
    };
    return pageTitleMap[pathname] || "Unknown Page";
  }

  trackPage(page: string) {
    const pageTitle = this.getPageTitle(page);
    this.providers.forEach((provider) => provider.trackPage?.(page, pageTitle));
  }

  private trackGAPage(page: string, pageTitle: string) {
    ReactGA.send({
      hitType: "pageview",
      page,
      title: pageTitle,
    });
  }

  trackEvent(eventName: string, params: Record<string, any> = {}) {
    this.providers.forEach((provider) =>
      provider.trackEvent?.(eventName, params)
    );
  }

  private trackGAEvent(eventName: string, params: Record<string, any>) {
    ReactGA.event({
      category: params.category || "General",
      action: eventName,
      label: params.label || "",
      value: params.value || 0,
    });
  }
}

const analytics = new Analytics();
export default analytics;
