/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";

import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { SharedCompanyAvatar } from "../../models/SharedCompanyAvatar";
import { CompanyAvatarStatus } from "../../../helpers/Enums";
import analytics from "../../../helpers/Analytics";

interface State {}

interface Props {
  onAcceptPress: (avatar: SharedCompanyAvatar) => void;
  onRejectPress: (avatar: SharedCompanyAvatar) => void;
  avatar: SharedCompanyAvatar;
}

@observer
export default class SharedAvatarInvitationItem extends React.Component<
  Props,
  State
> {
  state: State = {};

  renderAvatarStatusBadge = (status: CompanyAvatarStatus) => {
    let iconPath = "";
    let badgeClass = "";
    let labelText = "";

    switch (status) {
      case CompanyAvatarStatus.Connected:
        iconPath = "/media/icons/duotune/general/gen048.svg";
        badgeClass = "text-primary";
        labelText = "Connected";
        break;
      case CompanyAvatarStatus.InProgress:
        iconPath = "/media/icons/duotune/general/gen049.svg";
        badgeClass = "text-warning";
        labelText = "In Progress";
        break;
      case CompanyAvatarStatus.Failed:
        iconPath = "/media/icons/duotune/general/gen050.svg";
        badgeClass = "text-danger";
        labelText = "Failed";
        break;
      default:
        iconPath = "/media/icons/duotune/general/gen051.svg";
        badgeClass = "text-danger";
        labelText = "Not Connected";
        break;
    }

    return (
      <span className={`${badgeClass} fs-8 ms-2 mb-1 fw-bold`}>
        <KTSVG
          path={iconPath}
          className={`${badgeClass} svg-icon svg-icon-3 me-1`}
        />
        {labelText}
      </span>
    );
  };

  render() {
    const { avatar, onAcceptPress, onRejectPress } = this.props;

    return (
      <>
        <div
          key={avatar.id}
          className="d-flex align-items-center bg-light-primary rounded-8 px-5 py-4"
        >
          <div className="symbol symbol-60px me-1">
            <img
              src={toAbsoluteUrl(avatar.company_avatar.logo_url)}
              className="align-self-center"
              alt={avatar.company_avatar.name}
            />
          </div>

          <div className="py-3 px-4 me-1 mb-3">
            <div className="d-flex">
              <span className="fs-6 text-gray-800 fw-bolder">
                {avatar.company_avatar.name}
              </span>
              {this.renderAvatarStatusBadge(avatar.company_avatar.status)}
            </div>

            <div className="text-gray-500">{`Shared by ${avatar.shared_by_user.name}`}</div>
          </div>

          <div className="ms-auto d-flex">
            <button
              type="button"
              className="btn btn-light-success me-2"
              onClick={() => {
                analytics.trackEvent("accept_shared_avatar_pressed", {
                  category: "avatar_events",
                  label: "accept_shared_avatar_button",
                });
                onAcceptPress(avatar);
              }}
            >
              Accept
            </button>

            <button
              type="button"
              className="btn btn-light-danger"
              onClick={() => {
                analytics.trackEvent("reject_shared_avatar_pressed", {
                  category: "avatar_events",
                  label: "reject_shared_avatar_button",
                });
                onRejectPress(avatar);
              }}
            >
              Reject
            </button>
          </div>
        </div>
      </>
    );
  }
}
