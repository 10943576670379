import { gql } from "@apollo/client";

import ApolloClientHelper from "../ApolloClientHelper";
import type { CompanyAvatar } from "../../app/models/CompanyAvatar";

export default async function updateCompanyAvatar(
  companyAvatar: Partial<CompanyAvatar>
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const id = Number(companyAvatar.id);
  const company_id = Number(companyAvatar.company_id);
  const type_id = Number(companyAvatar.type_id);

  const mutation = gql`
    mutation UpdateCompanyAvatar(
      $id: ID!
      $company_id: Int!
      $dashboard_section_id: Int
      $name: String!
      $type_id: Int!
      $description: String
      $prompt_template: String
      $logo_url: String
      $status: String!
      $last_sync_at: Float
    ) {
      updateCompanyAvatar(
        id: $id
        company_id: $company_id
        dashboard_section_id: $dashboard_section_id
        name: $name
        type_id: $type_id
        description: $description
        prompt_template: $prompt_template
        logo_url: $logo_url
        status: $status
        last_sync_at: $last_sync_at
      )
    }
  `;

  try {
    const { data } = await client.mutate({
      mutation,
      variables: {
        id: id,
        company_id: company_id,
        dashboard_section_id: companyAvatar.dashboard_section_id,
        name: companyAvatar.name,
        type_id: type_id,
        description: companyAvatar.description,
        prompt_template: companyAvatar.prompt_template,
        logo_url: companyAvatar.logo_url,
        status: companyAvatar.status,
        last_sync_at: companyAvatar.last_sync_at,
      },
    });

    return !!data.updateCompanyAvatar;
  } catch (error) {
    console.error("Error updating CompanyAvatar:", error);
    throw new Error("Failed to update CompanyAvatar.");
  }
}
