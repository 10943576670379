import React from "react";
import { Position, NodeProps, Node, Edge } from "@xyflow/react";

import CustomHandle from "../handles/CustomHandle";

interface FinalResultNodeProps extends NodeProps {
  nodes: Node[];
  edges: Edge[];
}

export default function FinalResultNode(props: FinalResultNodeProps) {
  return (
    <div className="p-3 bg-body">
      <div className="p-3 bg-success border border-gray-400 rounded">
        <div>
          <strong>Final Result</strong>
        </div>

        <CustomHandle
          type="source"
          id="final_result_node_source_1"
          position={Position.Top}
          connectionCount={1}
          nodes={props.nodes}
          edges={props.edges}
        />
      </div>
    </div>
  );
}
