import { Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { I18nProvider } from "../i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../layout/core";
import { MasterInit } from "../layout/MasterInit";
import { ThemeModeProvider } from "../partials";
import analytics from "../helpers/Analytics";

const App = () => {
  const location = useLocation();
  console.log(location);
  useEffect(() => {
    analytics.trackPage(location.pathname);
  }, [location]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <Outlet />
            <MasterInit />

            <ToastContainer />
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
