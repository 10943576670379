/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../../../../helpers";
import { CustomUser } from "../../../../../models/CustomUser";
import stores from "../../../../../stores";

type Props = {
  customUser: CustomUser;
};

export class CompanyUserNameInfoCell extends React.Component<Props> {
  render() {
    const { customUser } = this.props;

    const companyUser = stores.companyUserStore.companyUsers.find(
      (user) => user.id === customUser.id
    );

    if (companyUser) {
      return (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
            <a>
              <div className="symbol-label">
                <img
                  src={toAbsoluteUrl(`${companyUser.user.picture}`)}
                  alt={companyUser.user.name}
                  className="w-100"
                />
              </div>
            </a>
          </div>

          <div className="d-flex flex-column">
            <a className="text-gray-800 mb-1">{customUser.name}</a>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
            <a>
              <div
                className={clsx("symbol-label fs-3", "bg-light", "text-dark")}
              >
                {customUser.name.charAt(0).toUpperCase()}
              </div>
            </a>
          </div>

          <div className="d-flex flex-column">
            <a className="text-gray-800 text-hover-primary mb-1">
              {customUser.name}
            </a>
          </div>
        </div>
      );
    }
  }
}
