// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { SharedCompanyAvatar } from "../../app/models/SharedCompanyAvatar";

export default async function shareAvatar(
  companyAvatarId: number,
  sharedByUserId: number,
  sharedWithUserId: number
): Promise<SharedCompanyAvatar> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      shareAvatar(
        companyAvatarId: ${companyAvatarId}
        sharedByUserId: ${sharedByUserId}
        sharedWithUserId: ${sharedWithUserId}
      ) {
        id
        company_avatar_id
        shared_by_user_id
        shared_with_user_id
        invitation_status
        created_at
        updated_at
        company_avatar {
          id
          company_id
          type_id
          name
          description
          logo_url
          status
        }
        shared_by_user {
          id
          name
          email
        }
        shared_with_user {
          id
          name
          email
        }
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((shareAvatarMutationResult: any) => {
        const sharedAvatar: SharedCompanyAvatar = get(
          shareAvatarMutationResult,
          "data.shareAvatar",
          null
        );

        if (sharedAvatar) {
          resolve(sharedAvatar);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
