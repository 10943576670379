/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import stores from "../../../app/stores";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";

import { DefaultTitle } from "./page-title/DefaultTitle";
import { Topbar } from "./Topbar";

export const HeaderWrapper = observer(function HeaderWrapper() {
  const { config, classes, attributes } = useLayout();
  const { header } = config;
  const [offset, setOffset] = useState<string>(
    `{default: '200px', lg: '300px'}`
  );
  const [isElectron, setIsElectron] = useState(false);

  useEffect(() => {
    // Check if running in Electron
    setIsElectron(window.electronAPIs?.isElectron ?? false);

    let newString = `{default: '200px', lg: '300px'}`;
    if (header.fixed.desktop) {
      if (!header.fixed.tabletAndMobile) {
        newString = `{lg: '300px'}`;
      }
    } else {
      newString = `{default: '200px', lg: false}`;
    }

    setOffset(newString);
  }, [header.fixed]);

  return (
    <>
      {stores.userStore.currentUser.email_verified === true ? null : (
        <div className="d-flex justify-content-center">
          <div className="d-flex align-items-center bg-light-warning p-4 w-100">
            <KTIcon
              iconName="information-3"
              className="text-warning fs-3qx me-5"
            />

            <div className="text-center">
              <div>
                <h4 className="fw-bold text-gray-800 mb-2 fs-4 text-start">
                  Email Not Verified!
                </h4>
              </div>

              <span className="text-muted fw-semibold">
                Please check your inbox for the verification email and complete
                the process.
              </span>
            </div>

            <button
              onClick={() =>
                (stores.userStore.currentUser.email_verified = true)
              }
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <KTIcon iconName="cross" className="fs-1" />
            </button>
          </div>
        </div>
      )}
      <div
        id="kt_header"
        className={clsx("header", classes.header.join(" "))}
        {...attributes.headerMenu}
        data-kt-sticky="true"
        data-kt-sticky-name="header"
        data-kt-sticky-offset={offset}
      >
        {/* begin::Container */}
        <div
          className={clsx(
            classes.headerContainer.join(" "),
            "d-flex align-items-center justify-content-between"
          )}
          id="kt_header_container"
        >
          {!isElectron && <DefaultTitle />}
          {/* begin::Wrapper */}
          <div className={"d-flex d-lg-none align-items-center ms-n2 me-2"}>
            {/* begin::Aside mobile toggle */}
            <div
              className="btn btn-icon btn-active-icon-primary aside-toggle"
              id="kt_aside_toggle"
            >
              <KTIcon iconName="abstract-14" className="fs-1" />
            </div>

            {/* begin::Logo */}
            <Link to="/">
              <img
                src={toAbsoluteUrl(
                  "https://imagedelivery.net/prp7br5UdCsJqvWC5hRwlA/b4d98b10-c21b-4560-d177-28f002687c00/mid"
                )}
                alt="logo"
                className="theme-dark-show h-30px"
              />
              <img
                src={toAbsoluteUrl(
                  "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/6c78f836-e585-45cf-0ddd-85f496547500/mid"
                )}
                alt="logo"
                className="theme-light-show h-30px"
              />
            </Link>
            {/* end::Logo */}
          </div>
          {/* end::Wrapper */}
          <div
            className={clsx({
              "ms-auto": isElectron, // Move Topbar to the right if in Electron
            })}
          >
            <Topbar />
          </div>
        </div>
        {/* end::Container */}
      </div>
    </>
  );
});
