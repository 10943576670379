import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";

import { PageLink, PageTitle } from "../../../layout/core";
import ConnectionsPage from "./ConnectionsPage";

const connectionsBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/connections",
    isSeparator: false,
    isActive: false,
  },
];

class ConnectionsWrapper extends React.Component<WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    return (
      <>
        <PageTitle breadcrumbs={connectionsBreadCrumbs}>
          {intl.formatMessage({ id: "MENU.CONNECTIONS" })}
        </PageTitle>

        <ConnectionsPage />
      </>
    );
  }
}

const ConnectionsWrapperWithIntl = injectIntl(ConnectionsWrapper);

export { ConnectionsWrapperWithIntl as ConnectionsWrapper };
