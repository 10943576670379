import { Component } from "react";
import { observer } from "mobx-react";

import analytics from "../../../helpers/Analytics";

interface Props {
  onCancel: () => void;
}

interface State {}

@observer
export default class AlertModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className="modal fade show d-block"
          id="kt_modal_connection_alert"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered mw-500px">
            <div className="modal-content">
              <div className="modal-header pb-0 border-0">
                <h3 className="align-items-start flex-row">
                  <span className="fw-bold text-dark">Warning</span>
                </h3>
              </div>

              <div className="modal-body py-lg-10 px-lg-10">
                <div className="current" data-kt-stepper-element="content">
                  <span className="text-gray-700 mt-1 fw-semibold fs-6">
                    The storage connection cannot be disconnected. To disconnect
                    the connected storage, you need to connect a new storage and
                    set it as the default.
                  </span>
                </div>
              </div>

              <div className="d-flex flex-center mb-8">
                <button
                  type="reset"
                  className="btn btn-lg btn-color-gray-600 me-4 fw-bolder px-6 py-3"
                  onClick={() => {
                    analytics.trackEvent(
                      "cancel_storage_alert_button_pressed",
                      {
                        category: "storage_events",
                        label: "cancel_storage_alert_button",
                      }
                    );
                    this.props.onCancel();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
