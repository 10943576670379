import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";

import { KTIcon } from "../../../helpers";
import stores from "../../../app/stores";
import analytics from "../../../helpers/Analytics";

const Notification: React.FC = observer(() => {
  const { userActionLogs, lastViewedAt, setLastViewedAt } =
    stores.userActionLogStore;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [visibleLogsCount, setVisibleLogsCount] = useState(10);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lastViewedAt === 0) {
      setLastViewedAt(Date.now());
    }
  }, [lastViewedAt, setLastViewedAt]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
        setVisibleLogsCount(10);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredLogs = userActionLogs.filter(
    (log) => !log.action.includes("socket")
  );

  const unreadLogs = filteredLogs.filter(
    (log) => log.modified_at > lastViewedAt
  );
  const unreadCount = unreadLogs.length;

  const handleToggleDropdown = () => {
    const willOpen = !dropdownOpen;
    setDropdownOpen(willOpen);

    if (willOpen) {
      analytics.trackEvent("notification_button_pressed", {
        category: "user_interaction",
        label: "notification_button",
      });

      setLastViewedAt(Date.now());
    } else {
      setVisibleLogsCount(5);
    }
  };
  const handleSeeAllNotifications = () => {
    analytics.trackEvent("see_all_notifications_button_pressed", {
      category: "user_interaction",
      label: "see_all_notifications",
    });

    setVisibleLogsCount(filteredLogs.length);
  };

  const visibleLogs = filteredLogs
    .sort((a, b) => b.modified_at - a.modified_at)
    .slice(0, visibleLogsCount);

  return (
    <div
      className="d-flex align-items-center ms-3 position-relative"
      ref={dropdownRef}
    >
      {/* Notification Icon */}
      <button
        className="btn btn-icon bg-body btn-color-gray-600 btn-active-color-primary h-40px me-3 position-relative"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleToggleDropdown}
      >
        <KTIcon iconName="notification" className="fs-1" />
        {unreadCount > 0 && (
          <span
            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style={{
              minWidth: "16px",
              height: "16px",
              fontSize: "0.75rem",
              lineHeight: "16px",
              transform: "translate(-50%, -50%)",
              color: "#fff",
            }}
          >
            {unreadCount}
          </span>
        )}
      </button>

      {/* Dropdown Menu */}
      {dropdownOpen && (
        <div
          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 bg-body menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-350px p-5 shadow show"
          data-kt-menu="true"
          style={{
            position: "absolute",
            zIndex: 105,
            top: "100%",
            right: "0",
            transform: "translateY(5px)",
          }}
        >
          <h6 className="dropdown-header fw-bold text-dark fs-4 mb-5">
            Notifications
          </h6>
          <div className="overflow-auto" style={{ maxHeight: "300px" }}>
            {filteredLogs.length === 0 ? (
              <div className="text-muted fs-7 text-center">
                No new notifications
              </div>
            ) : (
              visibleLogs.map((log) => (
                <div key={log.id} className="d-flex align-items-start mb-3">
                  <div className="symbol symbol-40px me-2 mt-1">
                    <KTIcon iconName="check-circle" className="fs-3" />
                  </div>

                  <div className="d-flex flex-column">
                    <span className="fw-bold text-dark">{log.action}</span>
                    <span className="text-muted fs-7">{log.action_result}</span>
                  </div>
                </div>
              ))
            )}
          </div>
          {/* "Tümünü Gör" Butonu */}
          {filteredLogs.length > visibleLogsCount && (
            <div className="text-center mt-3">
              <button
                className="btn text-primary fw-bold"
                onClick={handleSeeAllNotifications}
              >
                See All Notifications
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export { Notification };
