import React, { Component } from "react";
import { observer } from "mobx-react";
import { Edge, Node, ReactFlowProvider } from "@xyflow/react";
import { toast } from "react-toastify";

import i18n from "../../../i18n";
import CustomNodeFlow from "../../components/CustomNodeFlow";
import { DashboardSection } from "../../models/DashboardSection";
import { InitialNodes } from "../../components/CustomNodeFlow/InitialNodes";

interface SectionFlowCreatorStepProps {
  onNext: () => void;
  onBack: () => void;
  onNodesChange: (nodes: any[]) => void;
  onEdgesChange: (edges: any[]) => void;
  selectedSection: DashboardSection | undefined;
}

interface State {
  isNextEnabled: boolean;
  nodes: Node[];
  edges: Edge[];
}

@observer
class SectionFlowCreatorStep extends Component<
  SectionFlowCreatorStepProps,
  State
> {
  constructor(props: SectionFlowCreatorStepProps) {
    super(props);
    this.state = {
      isNextEnabled: false,
      nodes: [],
      edges: [],
    };
  }

  handleFlowCompletion = (isCompleted: boolean) => {
    if (this.state.isNextEnabled !== isCompleted) {
      this.setState({ isNextEnabled: isCompleted });
    }
  };

  handleFlowDataChange = (nodes: Node[], edges: Edge[]) => {
    this.setState({ nodes, edges }, () => {
      this.props.onNodesChange(nodes);
      this.props.onEdgesChange(edges);
    });
  };
  handleNextClick = () => {
    const { isNextEnabled, nodes } = this.state;

    if (!isNextEnabled) {
      toast.error(i18n.ToastMessages.IncompleteConnectionsError, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const hasEmptyQuestionNode = nodes.some(
      (node) =>
        node.type === "questionNode" &&
        //@ts-ignore
        (!node.data?.text || node.data.text.trim() === "")
    );

    if (hasEmptyQuestionNode) {
      toast.error(i18n.ToastMessages.EmptyQuestionError, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    this.props.onNext();
  };

  render() {
    const { selectedSection } = this.props;

    return (
      <div>
        <ReactFlowProvider>
          <CustomNodeFlow
            onComplete={(isCompleted) => {
              this.handleFlowCompletion(isCompleted);
            }}
            onFlowDataChange={this.handleFlowDataChange}
            initialNodes={
              selectedSection ? selectedSection.node_objects_list : InitialNodes
            }
            initialEdges={
              selectedSection ? selectedSection.edge_objects_list : []
            }
          />
        </ReactFlowProvider>

        <div
          className="fixed-bottom pb-20 pt-10"
          style={{
            height: "80px",
            backgroundColor:
              document.documentElement.getAttribute("data-bs-theme") === "dark"
                ? "#151521"
                : "#fff",
          }}
        >
          <div className="container d-flex justify-content-end w-50">
            <button className="btn btn-primary" onClick={this.handleNextClick}>
              Next
              <i className="ki-duotone ki-arrow-right fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionFlowCreatorStep;
