/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";

import analytics from "../../../../../../helpers/Analytics";
import { KTIcon } from "../../../../../../helpers";
import { CustomUser } from "../../../../../models/CustomUser";

import stores from "../../../../../stores";

type Props = {
  customUser: CustomUser;
};

@observer
export class CompanyUserActionsCell extends React.Component<Props> {
  private handleDeleteInviteItem = () => {
    analytics.trackEvent("delete_user_invite_button_pressed", {
      category: "company_user_events",
      label: "delete_user_invite_button",
    });
    const { customUser } = this.props;
    const selectedUserInvite = stores.companyUserStore.companyUserInvites.find(
      (invite) => invite.id === customUser.id
    );

    stores.companyUserStore.selectedCompanyUserInvite = selectedUserInvite;

    stores.companyUserStore.isCompanyUserInviteDeleteModalOpened = true;
  };

  private handleDeleteItem = () => {
    const { customUser } = this.props;

    analytics.trackEvent("delete_company_user_button_pressed", {
      category: "company_user_events",
      label: "delete_company_user_button",
    });

    stores.companyUserStore.selectedCompanyUser =
      stores.companyUserStore.companyUsers.find(
        (item) => item.id === customUser.id
      );

    stores.companyUserStore.isCompanyUserDeleteModalOpened = true;
  };
  //   const { customUser } = this.props;
  //   const selectedUserInvite = stores.companyUserStore.companyUserInvites.find(
  //     (invite) => invite.id === customUser.id
  //   );

  //   stores.companyUserStore.selectedCompanyUserInvite = selectedUserInvite;
  //   stores.companyUserStore.isCompanyUserEditModalOpened = true;

  //   ReactGA.event({
  //     category: "company_user_invites",
  //     action: "edit_user_invite",
  //     label: "edit_user_invite_button",
  //   });
  // };

  render() {
    const { customUser } = this.props;
    const companyUser = stores.companyUserStore.companyUsers.find(
      (user) => user.id === customUser.id
    );
    const isUserInCompanyUsers = stores.companyUserStore.companyUsers.some(
      (user) => user.id === customUser.id
    );

    if (stores.companyUserStore.isLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center w-100"></div>
      );
    }

    return (
      <>
        {companyUser?.user.id.toString() ===
        stores.userStore.currentUser.id.toString() ? (
          <div className="d-flex justify-content-center align-items-center w-100">
            <div></div>
          </div>
        ) : !isUserInCompanyUsers ? (
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end flex-shrink-0">
              <a
                onClick={this.handleDeleteInviteItem}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              >
                <KTIcon iconName="trash" className="fs-3" />
              </a>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-end flex-shrink-0">
            <a
              onClick={this.handleDeleteItem}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <KTIcon iconName="trash" className="fs-3" />
            </a>
          </div>
        )}
      </>
    );
  }
}
