import { action, observable, when } from "mobx";
import {
  createCompanyModel,
  deleteCompanyModel,
  getModels,
  getCompanyModels,
  updateCompanyModel,
  setDefaultApiKeyForBetaUser,
} from "../../helpers/api";
import { CompanyModel } from "../models/CompanyModel";
import { CompanyModelStatus, UserActionLogStatus } from "../../helpers/Enums";
import { Model } from "../models/Model";

import RootStore from "./RootStore";
import stores from ".";

export type CompanyModelFilter = {
  status: CompanyModelStatus;
};

export default class CompanyModelStore {
  @observable models: Model[] = [];
  @observable.deep companyModels: CompanyModel[] = [];
  @observable.deep nonFilteredCompanyModels: CompanyModel[] = [];
  @observable isSetupModelModalOpened: boolean = false;
  @observable isLoading: boolean = false;

  constructor(rootStore: RootStore) {
    when(
      () =>
        rootStore.companyStore.selectedUserCompany !== undefined &&
        rootStore.companyAppStore.apps.length > 0,
      () => {
        this.getCompanyModels();
      }
    );
  }

  @action getModelById = (modelId: number): Model | undefined => {
    return this.models.find((model) => model.id === modelId);
  };

  @action findCompanyModelById = (
    companyModelId: number
  ): CompanyModel | undefined => {
    const companyIdStr = companyModelId.toString();
    return this.companyModels.find(
      (companyModel) => companyModel.id.toString() === companyIdStr
    );
  };

  @action getCompanyModels = async () => {
    this.isLoading = true;
    this.models = await getModels();

    if (
      stores.companyStore.selectedUserCompany !== undefined &&
      stores.userStore.isCurrentUserAdmin
    ) {
      this.nonFilteredCompanyModels = await getCompanyModels(
        stores.companyStore.selectedUserCompany.id
      );
      this.filterAndSortCompanyModels();
    }

    this.isLoading = false;
  };

  @action filterAndSortCompanyModels = (
    searchTerm?: string,
    filter?: CompanyModelFilter
  ) => {
    this.isLoading = true;

    this.companyModels = this.nonFilteredCompanyModels;

    if (searchTerm) {
      this.companyModels = this.nonFilteredCompanyModels.filter(
        (companyModel) => companyModel.model.name.includes(searchTerm)
      );
    }

    if (filter) {
      this.companyModels = this.nonFilteredCompanyModels.filter(
        (companyModel) => companyModel.status === filter.status
      );
    }

    this.isLoading = false;
  };

  @action setDefaultApiKeyForBetaUser(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!stores.companyStore.selectedUserCompany) {
        return reject();
      }

      await setDefaultApiKeyForBetaUser(
        stores.companyStore.selectedUserCompany.id
      );

      this.getCompanyModels();

      resolve(true);
    });
  }

  @action createCompanyModel(
    model: Model,
    credentials: string = ""
  ): Promise<CompanyModel> {
    return new Promise(async (resolve, reject) => {
      if (!stores.companyStore.selectedUserCompany) {
        return reject();
      }

      await stores.userActionLogStore.createUserActionLog(
        `${model.name} Connection`,
        "Connecting model...",
        0,
        0,
        UserActionLogStatus.InProgress
      );

      const createdCompanyModel = await createCompanyModel(
        model.id,
        stores.companyStore.selectedUserCompany.id,
        credentials,
        CompanyModelStatus.Connected,
        Date.now()
      );
      this.nonFilteredCompanyModels.push(createdCompanyModel);

      await stores.userActionLogStore.createUserActionLog(
        `${model.name} Connection`,
        `${model.name} activation successful.`,
        0,
        0,
        UserActionLogStatus.Success
      );

      resolve(createdCompanyModel);
    });
  }

  @action updateCompanyModel(companyModel: CompanyModel): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await updateCompanyModel(companyModel);

      const existingIndex = this.nonFilteredCompanyModels.findIndex(
        (item) => item.id === companyModel.id
      );
      if (existingIndex !== -1) {
        this.nonFilteredCompanyModels[existingIndex] = companyModel;
      }

      this.filterAndSortCompanyModels();
      resolve(true);
    });
  }

  @action deleteSelectedCompanyModel(
    companyModel: CompanyModel
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await stores.userActionLogStore.createUserActionLog(
        `${companyModel.model.name} Deletion`,
        "Deleting model...",
        0,
        0,
        UserActionLogStatus.InProgress
      );

      await deleteCompanyModel(companyModel.id);

      this.nonFilteredCompanyModels = this.nonFilteredCompanyModels.filter(
        (item) => item.id !== companyModel.id
      );
      this.filterAndSortCompanyModels();

      await stores.userActionLogStore.createUserActionLog(
        `${companyModel.model.name} Deletion`,
        `${companyModel.model.name} has been deleted successfully.`,
        0,
        0,
        UserActionLogStatus.Success
      );

      resolve(true);
    });
  }
}
