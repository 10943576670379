import { Component } from "react";
import { observer } from "mobx-react";
import analytics from "../../../../helpers/Analytics";

interface Props {
  onClose: () => void;
  apiKey: string;
}

interface State {
  isApiKeyCopied: boolean;
}

@observer
export default class GenerateAPIKeyModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isApiKeyCopied: false,
    };
  }

  handleCopyApiKey = (text: string) => {
    analytics.trackEvent("copy_api_key_button_pressed", {
      category: "user_profile_events",
      label: "copy_api_key_button",
    });
    navigator.clipboard.writeText(text).then(
      () => {
        this.setState({ isApiKeyCopied: true }, () => {
          setTimeout(() => {
            this.setState({ isApiKeyCopied: false });
          }, 2000);
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  render() {
    const { onClose, apiKey } = this.props;

    return (
      <>
        <div
          className="modal fade show d-block"
          id="kt_modal_add_company_users_apps"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered mw-500px">
            <div className="modal-content">
              <div className="modal-body py-lg-10 px-lg-10">
                <div className="current" data-kt-stepper-element="content">
                  <div className="fw-bolder text-primary fs-4 mb-1 mt-3">
                    API Key
                    <button
                      onClick={() => this.handleCopyApiKey(apiKey)}
                      className="btn btn-icon w-auto me-2"
                      style={{
                        marginLeft: 10,
                        height: "auto",
                        border: "1px solid var(--bs-gray-300)",
                        background: this.state.isApiKeyCopied
                          ? "#d4edda"
                          : "#f8f5ff",
                        borderRadius: "15px",
                        padding: "5px 10px",
                        borderColor: this.state.isApiKeyCopied
                          ? "var(--bs-success)"
                          : "var(--bs-gray-300)",
                      }}
                    >
                      <i
                        className={`fas fa-${
                          this.state.isApiKeyCopied ? "check" : "copy"
                        } p-0`}
                        style={{
                          color: this.state.isApiKeyCopied
                            ? "var(--bs-success)"
                            : "grey",
                          fontSize: "1.2rem",
                        }}
                      ></i>
                    </button>
                  </div>

                  <span className="fw-bolder text-gray-700 mt-1 fw-semibold fs-6">
                    {apiKey}
                  </span>
                </div>

                <div className="d-flex flex-center pt-10">
                  <div>
                    <button
                      type="reset"
                      className="btn btn-outline btn-outline-gray me-4 fw-bolder px-6 py-3"
                      onClick={() => {
                        analytics.trackEvent("close_generate_api_key_modal", {
                          category: "user_profile_events",
                          label: "generate_api_key_modal_closed",
                        });
                        onClose();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
