/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { KTIcon, KTSVG, toAbsoluteUrl } from "../../../helpers";
import stores from "../../stores";
import {
  getSharedAvatarWithUsers,
  getUserByEmail,
  shareAvatar,
} from "../../../helpers/api";
import { SharedCompanyAvatar } from "../../models/SharedCompanyAvatar";
import { revokeSharedAvatar } from "../../../helpers/api";
import { SharedAvatarStatus } from "../../../helpers/Enums";
import analytics from "../../../helpers/Analytics";

type EmailStatus = "valid" | "invalid" | "pending";

type EmailEntry = {
  email: string;
  status: EmailStatus;
};

type State = {
  emails: EmailEntry[];
  inputValue: string;
  sharedUsers: SharedCompanyAvatar[];
  isLoading: boolean;
};

class SharedAvatarModal extends Component<{}, State> {
  private userDomain?: string;

  constructor(props: {}) {
    super(props);

    if (stores.userStore.currentUser.email) {
      const userEmail = stores.userStore.currentUser.email;
      this.userDomain = userEmail.substring(userEmail.indexOf("@") + 1);
    }

    this.state = {
      emails: [],
      inputValue: "",
      sharedUsers: [],
      isLoading: false,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      if (stores.companyAvatarStore.selectedCompanyAvatar?.id) {
        const sharedUsers = await getSharedAvatarWithUsers(
          Number(stores.companyAvatarStore.selectedCompanyAvatar.id)
        );
        this.setState({ sharedUsers });
      }
    } catch (error) {
      console.error("Error fetching shared users:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputValue: e.target.value });
  };

  handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === " " || e.key === "," || e.key === "Enter") {
      e.preventDefault();

      const { inputValue, emails } = this.state;
      const email = inputValue.trim().toLowerCase();

      if (!email) return;

      if (!this.isValidEmail(email)) {
        this.setState({ inputValue: "" });
        return;
      }

      if (
        stores.userStore.currentUser.email &&
        email === stores.userStore.currentUser.email.toLowerCase()
      ) {
        this.setState({ inputValue: "" });
        return;
      }

      if (emails.some((entry) => entry.email === email)) {
        this.setState({ inputValue: "" });
        return;
      }

      const alreadyShared = this.state.sharedUsers.some(
        (user) =>
          user.shared_with_user?.email?.toLowerCase().toString() ===
          email.toString()
      );

      if (alreadyShared) {
        this.setState({
          emails: [...emails, { email, status: "invalid" }],
          inputValue: "",
        });
        return;
      }

      if (alreadyShared) {
        this.setState({
          emails: [...emails, { email, status: "invalid" }],
          inputValue: "",
        });
        return;
      }

      if (!this.isSameDomain(email)) {
        this.setState({
          emails: [...emails, { email, status: "invalid" }],
          inputValue: "",
        });
        return;
      }

      this.setState({
        emails: [...emails, { email, status: "pending" }],
        inputValue: "",
      });

      const user = await getUserByEmail(email);

      this.setState((prevState) => ({
        emails: prevState.emails.map((entry) =>
          entry.email === email
            ? { ...entry, status: user ? "valid" : "invalid" }
            : entry
        ),
      }));
    }
  };

  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  isSameDomain(email: string): boolean {
    const emailDomain = email.substring(email.indexOf("@") + 1);
    return emailDomain === this.userDomain;
  }

  handleRemoveEmail = (emailToRemove: string) => {
    analytics.trackEvent("revoke_shared_avatar_pressed", {
      category: "avatar_events",
      label: "revoke_shared_avatar_button",
    });
    this.setState((prevState) => ({
      emails: prevState.emails.filter((entry) => entry.email !== emailToRemove),
    }));
  };

  handleShare = async () => {
    const { emails } = this.state;

    analytics.trackEvent("share_avatar_pressed", {
      category: "avatar_events",
      label: "share_avatar_button",
    });

    const validEmails = emails.filter(
      (emailEntry) => emailEntry.status === "valid"
    );

    this.setState({ isLoading: true });

    for (const emailEntry of validEmails) {
      try {
        const user = await getUserByEmail(emailEntry.email);

        if (user) {
          const sharedByUserId = stores.userStore.currentUser.id;
          const sharedWithUserId = user.id;
          const companyAvatar = stores.companyAvatarStore.selectedCompanyAvatar;

          if (companyAvatar) {
            await shareAvatar(
              companyAvatar.id,
              sharedByUserId,
              sharedWithUserId
            );
          }
        }
      } catch (error) {
        console.error(`avatar sharing failed: ${emailEntry.email}`, error);
      }
    }

    this.setState({ emails: [], inputValue: "", isLoading: false });
    stores.companyAvatarStore.isSharedAvatarModalOpen = false;
    stores.companyAvatarStore.selectedCompanyAvatar = undefined;
  };

  handleRemoveSharedUser = async (sharedUserId: number) => {
    try {
      const success = await revokeSharedAvatar(sharedUserId);

      if (success) {
        this.setState((prevState) => ({
          sharedUsers: prevState.sharedUsers.filter(
            (user) => user.id !== sharedUserId
          ),
        }));
      }
    } catch (error) {
      console.error("Error revoking shared avatar:", error);
    }
  };

  handleCloseModal = () => {
    analytics.trackEvent("cancel_avatar_share_modal", {
      category: "avatar_events",
      label: "cancel_share_modal_button",
    });
    stores.companyAvatarStore.isSharedAvatarModalOpen = false;
    stores.companyAvatarStore.selectedCompanyAvatar = undefined;
  };

  renderSharedAvatarStatusBadge = (status: SharedAvatarStatus) => {
    let iconPath = "";
    let badgeClass = "";
    let labelText = "";

    switch (status) {
      case SharedAvatarStatus.Accepted:
        iconPath = "/media/icons/duotune/general/gen048.svg";
        badgeClass = "text-primary";
        labelText = "Accepted";
        break;
      case SharedAvatarStatus.Pending:
        iconPath = "/media/icons/duotune/general/gen049.svg";
        badgeClass = "text-warning";
        labelText = "Pending";
        break;
      case SharedAvatarStatus.Rejected:
        iconPath = "/media/icons/duotune/general/gen050.svg";
        badgeClass = "text-danger";
        labelText = "Rejected";
        break;
    }
    return (
      <span className={`${badgeClass} fs-8 ms-2 mb-1 fw-bold`}>
        <KTSVG
          path={iconPath}
          className={`${badgeClass} svg-icon svg-icon-3 me-1`}
        />
        {labelText}
      </span>
    );
  };

  render() {
    const { emails, inputValue, isLoading } = this.state;
    const hasInvalidOrPendingEmail = emails.some(
      (email) => email.status === "invalid" || email.status === "pending"
    );
    const avatarName = stores.companyAvatarStore.selectedCompanyAvatar?.name
      ? stores.companyAvatarStore.selectedCompanyAvatar.name
          .charAt(0)
          .toUpperCase() +
        stores.companyAvatarStore.selectedCompanyAvatar.name.slice(1)
      : "";

    return (
      <>
        <div
          className="modal fade show d-block"
          id="kt_modal_add_user"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered mw-650px">
            <div className="modal-content">
              {isLoading && (
                <div
                  className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-body bg-opacity-100"
                  style={{ zIndex: 10 }}
                >
                  <div
                    style={{
                      position: "absolute",
                      textAlign: "center",
                    }}
                    role="status"
                  >
                    <span
                      className="spinner-border text-primary mb-3"
                      role="status"
                    ></span>
                    <div>Loading...</div>
                  </div>
                </div>
              )}

              <div className="modal-header pb-0 border-0 justify-content-end">
                <div
                  className="btn btn-sm btn-icon btn-active-color-primary"
                  onClick={this.handleCloseModal}
                  data-bs-dismiss="modal"
                >
                  <KTIcon iconName="cross" className="fs-1" />
                </div>
              </div>

              <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-0">
                <div className="d-flex align-items-center justify-content-center mb-13">
                  {/* Avatar Resmi */}
                  {stores.companyAvatarStore.selectedCompanyAvatar
                    ?.logo_url && (
                    <img
                      src={toAbsoluteUrl(
                        stores.companyAvatarStore.selectedCompanyAvatar.logo_url
                      )}
                      alt="Avatar"
                      className="me-3"
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  )}

                  <div>
                    <h1>{`Share ${avatarName} Avatar`}</h1>
                    {/* <div className="text-muted fw-bold fs-5">
                      If you need more info, please check out
                      <a href="#" className="link-primary fw-bolder">
                        {" "}
                        FAQ Page
                      </a>
                      .
                    </div> */}
                  </div>
                </div>

                <p className="text-muted fs-6 mb-4">
                  For security reasons, the email address you use to send an
                  invitation must belong to the same domain as your registered
                  email address in the system and must be a valid email address.
                </p>

                <p className="text-muted fs-8 mb-1">
                  Enter the user's email to send an invitation. They can access
                  the avatar once they accept.
                </p>

                {/* Email Input */}
                <div
                  className="form-control form-control-solid  mb-8 border p-2 d-flex flex-wrap h-200px "
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "8px",
                    alignContent: "flex-start",
                    overflow: "auto",
                  }}
                >
                  {emails.map((emailEntry, i) => (
                    <span
                      key={i}
                      className="d-flex align-items-center px-2 rounded me-2"
                      style={{
                        backgroundColor:
                          emailEntry.status === "valid"
                            ? "rgba(40, 167, 69, 0.2)"
                            : emailEntry.status === "invalid"
                            ? "rgba(220, 53, 69, 0.2)"
                            : "rgba(108, 117, 125, 0.2)",
                        display: "inline-flex",

                        borderRadius: "5px",
                        whiteSpace: "nowrap",
                        fontSize: "14px",
                      }}
                    >
                      <span className="fw-bold text-dark">
                        {emailEntry.email}
                      </span>
                      <span
                        className=" ms-1 cursor-pointer m-0"
                        onClick={() => this.handleRemoveEmail(emailEntry.email)}
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                      >
                        <KTIcon
                          iconName="cross"
                          className="text-danger fs-1 mt-2"
                        />
                      </span>
                    </span>
                  ))}

                  <input
                    type="text"
                    className="border-0 flex-grow-1 outline-0 form-control form-control-solid text-gray-500 "
                    placeholder="Type or paste emails here"
                    value={inputValue}
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyPress}
                    style={{
                      minWidth: "80px",
                      flex: "1",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      padding: "5px",
                      fontSize: "14px",
                      height: "25px",
                      alignSelf: "flex-start",
                    }}
                  />
                </div>

                <div className="mb-10">
                  <div className="fs-6 fw-bold mb-2">Your Shares</div>

                  <div
                    className=" overflow-auto me-n7 pe-7 border"
                    style={{
                      maxHeight: "200px",
                      minHeight: "150px",
                      overflowY: "scroll",
                    }}
                  >
                    {this.state.sharedUsers.length === 0 ? (
                      <div className="text-muted fs-6 text-center mt-20">
                        You don't have any shared avatars.
                      </div>
                    ) : (
                      this.state.sharedUsers.map((user, i) => (
                        <div
                          className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed mx-2"
                          key={i}
                        >
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-35px symbol-circle">
                              {user.shared_with_user.picture && (
                                <img
                                  alt="Pic"
                                  src={toAbsoluteUrl(
                                    user.shared_with_user.picture
                                  )}
                                />
                              )}
                              {!user.shared_with_user.picture && (
                                <div className="symbol symbol-35px symbol-circle">
                                  <span className={`symbol-label fw-bold`}>
                                    {user.shared_with_user.name
                                      ?.charAt(0)
                                      .toUpperCase()}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="ms-5">
                              <div className="d-flex">
                                <span className="fs-6 text-gray-800 fw-bolder">
                                  {user.shared_with_user.name}
                                </span>
                                {this.renderSharedAvatarStatusBadge(
                                  user.invitation_status
                                )}
                              </div>
                              <div className="fw-bold text-muted">
                                {`Shared by ${user.shared_by_user.name}`}
                              </div>
                            </div>
                          </div>

                          <div className="ms-2">
                            <button
                              className="btn btn-icon btn-sm btn-light-danger"
                              onClick={() =>
                                this.handleRemoveSharedUser(user.id)
                              }
                            >
                              <KTSVG
                                path="/media/icons/duotune/general/gen027.svg"
                                className="svg-icon-3"
                              />
                            </button>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-0 mb-10 mx-20">
                <button
                  className="btn btn-primary"
                  disabled={
                    isLoading || hasInvalidOrPendingEmail || emails.length === 0
                  }
                  onClick={this.handleShare}
                >
                  Share Avatars
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}

export { SharedAvatarModal };
