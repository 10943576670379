import React, { useEffect, useRef } from "react";
import lottie, { AnimationItem } from "lottie-web";
import animationData from "./animation.json";

const LottieAnimation = ({ speed = 1 }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const animationInstance = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (!animationInstance.current && containerRef.current) {
      animationInstance.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData,
      });
    }
  }, []);

  useEffect(() => {
    if (animationInstance.current) {
      animationInstance.current.setSpeed(speed);
    }
  }, [speed]);

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
};

export default LottieAnimation;
