import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import {
  Position,
  NodeProps,
  Node,
  Edge,
  NodeToolbar,
  useReactFlow,
} from "@xyflow/react";

import { CompanyAvatar } from "../../../models/CompanyAvatar";
import { KTSVG } from "../../../../helpers";
import stores from "../../../stores";
import CustomHandle from "../handles/CustomHandle";

interface AvatarGroupNodeProps extends NodeProps {
  data: {
    company_avatars?: CompanyAvatar[];
    isEditing?: boolean;
    handles?: { id: string; allowedTypes: string[] }[];
  };
  nodes: Node[];
  edges: Edge[];
  setNodes: (callback: (nodes: Node[]) => Node[]) => void;
}

const AvatarGroupNode: React.FC<AvatarGroupNodeProps> = (props) => {
  const allAvatars = [
    ...stores.companyAvatarStore.companyAvatars.filter(
      (avatar) => !avatar.dashboard_section_id
    ),
    ...stores.companyAvatarStore.sharedAvatarsWithUser.flatMap(
      (sharedAvatar) => sharedAvatar.company_avatar
    ),
  ];
  const selectedCompanyAvatars = props.data.company_avatars || [];
  const isEditing = props.data.isEditing || false;
  const scrollRef = useRef<HTMLDivElement>(null);
  const { deleteElements } = useReactFlow();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = stores.companyAvatarStore.scrollPosition;
    }
  }, [isEditing]);

  const toggleAvatarSelection = (avatar: CompanyAvatar) => {
    const updatedAvatars = selectedCompanyAvatars.find(
      (a) => a.id === avatar.id
    )
      ? selectedCompanyAvatars.filter((a) => a.id !== avatar.id)
      : [...selectedCompanyAvatars, avatar];

    props.setNodes((nodes) =>
      nodes.map((node) =>
        node.id === props.id
          ? {
              ...node,
              data: {
                ...node.data,
                company_avatars: updatedAvatars,
              },
            }
          : node
      )
    );
  };

  const saveChanges = () => {
    props.setNodes((nodes) =>
      nodes.map((node) =>
        node.id === props.id
          ? {
              ...node,
              data: {
                ...node.data,
                isEditing: false,
              },
            }
          : node
      )
    );
  };

  const toggleEditMode = () => {
    props.setNodes((nodes) =>
      nodes.map((node) =>
        node.id === props.id
          ? {
              ...node,
              data: {
                ...node.data,
                isEditing: !isEditing,
              },
            }
          : node
      )
    );
  };

  const handleDelete = () => {
    deleteElements({ nodes: [{ id: props.id }] });
  };

  return (
    <div className="p-3 bg-body">
      {isEditing ? (
        <div>
          <p className="fw-bold fs-5">Edit Avatars</p>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              maxHeight: "300px",
              overflowY: "auto",
              minWidth: "240px",
            }}
            className="nowheel px-3"
            ref={scrollRef}
          >
            {allAvatars.map((avatar) => (
              <div
                key={avatar.id}
                className={`d-flex flex-column justify-content-center align-items-center p-3 border ${
                  selectedCompanyAvatars.find((a) => a.id === avatar.id)
                    ? "border-primary border-2"
                    : "border-gray-400"
                } rounded cursor-pointer`}
                onClick={() => toggleAvatarSelection(avatar)}
              >
                <img
                  src={avatar.logo_url}
                  alt={`${avatar.name} Avatar`}
                  style={{ width: 35, height: 35 }}
                />
                <div style={{ marginTop: 5, fontSize: 12 }}>{avatar.name}</div>
              </div>
            ))}
          </div>
          <button className="btn btn-primary mt-2" onClick={saveChanges}>
            Save
          </button>
        </div>
      ) : (
        <div>
          {selectedCompanyAvatars.length > 0 && (
            <NodeToolbar isVisible={true} position={Position.Top}>
              <button
                onClick={toggleEditMode}
                className="btn btn-primary py-2 px-3 rounded-sm"
              >
                Edit Avatars
              </button>
            </NodeToolbar>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${Math.min(
                selectedCompanyAvatars.length || 1,
                3
              )}, minmax(100px, 1fr))`,
              gap: "10px",
              padding: "2px",
            }}
          >
            {selectedCompanyAvatars.length > 0 ? (
              selectedCompanyAvatars.map((avatar, index) => (
                <div
                  key={index}
                  className="d-flex flex-column justify-content-center align-items-center p-3 border border-gray-400 rounded"
                >
                  {avatar.logo_url && (
                    <img
                      src={avatar.logo_url}
                      alt={`${avatar.name} Logosu`}
                      style={{ width: 35, height: 35 }}
                    />
                  )}
                  <div style={{ marginTop: 5, fontSize: 12 }}>
                    {avatar.name}
                  </div>
                </div>
              ))
            ) : (
              <div
                onClick={toggleEditMode}
                className="d-flex flex-column border border-dashed border-gray-400 rounded p-3 justify-content-center align-items-center h-100px"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen035.svg"
                  className="svg-icon-muted svg-icon-5hx"
                />
                <span className="text-gray-600 fs-5">Add Avatars</span>
              </div>
            )}
            <button
              onClick={handleDelete}
              style={{
                position: "absolute",
                top: "1px",
                right: "-2px",
                background: "transparent",
                border: "none",
                color: "#F64E60",
                cursor: "pointer",
                fontSize: 8,
              }}
              aria-label="Close"
            >
              ❌
            </button>
          </div>
        </div>
      )}

      <CustomHandle
        id="avatar_group_node_target_1"
        type="target"
        position={Position.Right}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />
    </div>
  );
};

export default observer(AvatarGroupNode);
