import React from "react";
import { observer } from "mobx-react";
import stores from "../../stores";

import { CompanyUsersListWrapper } from "./CompanyUsersList/CompanyUsersListWrapper";

type Props = {};

type State = {};

@observer
export default class CompanyUsersPage extends React.Component<Props, State> {
  componentDidMount() {
    document.getElementById("kt_layout_toolbar")?.classList.remove("d-none");
  }

  componentWillUnmount() {
    document.getElementById("kt_layout_toolbar")?.classList.add("d-none");
  }

  render() {
    const isFreeUser = stores.userStore.isFreeUser;

    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="card-xl-stretch mb-xl-8 w-100">
          {isFreeUser && (
            <div className="text-gray-600 mt-1 fw-semibold fs-6 px-10 ">
              Free users can't add any users. To add users, please{" "}
              <button
                type="button"
                className="text-primary fw-bold btn btn-link p-0 py-4"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_upgrade_plan"
              >
                upgrade your plan
              </button>
              .
            </div>
          )}

          <CompanyUsersListWrapper />
        </div>
      </div>
    );
  }
}
