import React, { useCallback } from "react";
import {
  Position,
  NodeProps,
  Node,
  Edge,
  useReactFlow,
  NodeResizer,
} from "@xyflow/react";

import CustomHandle from "../handles/CustomHandle";

interface QuestionNodeProps extends NodeProps {
  data: any;
  nodes: Node[];
  edges: Edge[];
  setNodes: (setter: (nodes: Node[]) => Node[]) => void;
}

const QuestionNode: React.FC<QuestionNodeProps> = (props) => {
  const { id, data, setNodes } = props;
  const { deleteElements } = useReactFlow();

  const handleUpdate = useCallback(
    (newValue: string) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === id
            ? { ...node, data: { ...node.data, text: newValue } }
            : node
        )
      );
    },
    [id, setNodes]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      handleUpdate(newValue);
    },
    [handleUpdate]
  );
  const handleDelete = () => {
    deleteElements({ nodes: [{ id: props.id }] });
  };

  return (
    <div
      className="nowheel bg-body"
      style={{
        width: data.width || 220,
        height: data.height || 170,
        padding: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NodeResizer
        minWidth={200}
        minHeight={150}
        isVisible={true}
        handleStyle={{
          backgroundColor: "black",
          width: "8px",
          height: "8px",
          borderRadius: "10%",
        }}
        lineStyle={{
          borderColor: "transparent",
        }}
        onResize={(event, params) => {
          setNodes((prevNodes) =>
            prevNodes.map((node) =>
              node.id === id
                ? {
                    ...node,
                    data: {
                      ...node.data,
                      width: params.width,
                      height: params.height,
                    },
                  }
                : node
            )
          );
        }}
      />
      <CustomHandle
        type="source"
        id="question_node_source_1"
        position={Position.Left}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="mb-2">Question</div>

        <textarea
          id={`question-${id}`}
          name={`question-${id}`}
          defaultValue={data?.text || ""}
          onBlur={handleBlur}
          className="nodrag mx-2 mb-2 p-2 rounded border border-gray-400"
          style={{
            flex: 1,
            resize: "none",
          }}
          placeholder="Enter your question here..."
        />
      </div>

      <CustomHandle
        type="source"
        id="question_node_source_2"
        position={Position.Top}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />

      <CustomHandle
        type="target"
        id="question_node_target_1"
        position={Position.Right}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />

      <button
        onClick={handleDelete}
        style={{
          position: "absolute",
          top: "3px",
          right: "-1px",
          background: "transparent",
          border: "none",
          color: "#F64E60",
          cursor: "pointer",
          fontSize: 8,
        }}
        aria-label="Close"
      >
        ❌
      </button>
    </div>
  );
};

export default QuestionNode;
