import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";

import { PageLink, PageTitle } from "../../../layout/core";
import stores from "../../stores";

import { SharedAvatarModal } from "./SharedAvatarModal";
import AvatarsPage from "./AvatarsPage";

const avatarBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/avatars",
    isSeparator: false,
    isActive: false,
  },
];

const AvatarWrapperBase = (props: WrappedComponentProps) => {
  const navigate = useNavigate();

  return <AvatarWrapperClass {...props} navigate={navigate} />;
};

@observer
class AvatarWrapperClass extends React.Component<
  WrappedComponentProps & { navigate: (path: string) => void }
> {
  render() {
    const { intl, navigate } = this.props;

    return (
      <>
        <PageTitle breadcrumbs={avatarBreadCrumbs}>
          {intl.formatMessage({ id: "MENU.AVATAR" })}
        </PageTitle>
        <AvatarsPage navigate={navigate} />
        {stores.companyAvatarStore.isSharedAvatarModalOpen && (
          <SharedAvatarModal />
        )}
      </>
    );
  }
}

const AvatarWrapperWithIntl = injectIntl(AvatarWrapperBase);

export { AvatarWrapperWithIntl as AvatarWrapper };
