export enum CompanyAppStatus {
  None = "",
  Connected = "Connected",
  Failed = "Failed",
  InProgress = "InProgress",
}

export enum SharedAvatarStatus {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
}

export enum CompanyAvatarStatus {
  None = "",
  Connected = "Connected",
  Failed = "Failed",
  InProgress = "InProgress",
}

export enum CompanyModelStatus {
  None = "",
  Connected = "Connected",
  Failed = "Failed",
  InProgress = "InProgress",
}

export enum CompanyStorageStatus {
  None = "",
  Connected = "Connected",
  Failed = "Failed",
  InProgress = "InProgress",
}

export enum CompanyUserStatus {
  None = "",
  Approved = "Approved",
  Declined = "Declined",
  InProgress = "InProgress",
}

export enum CompanyUserAppStatus {
  None = "",
  Approved = "Approved",
  Declined = "Declined",
  InProgress = "InProgress",
}

export enum AppAction {
  Login = "login",
  ModalLogin = "modal Login",
  GoogleLogin = "google login",
  AddUser = "add user",
  GetUser = "get user",
  RemoveUser = "remove user",
  GetWorkspace = "get workspace",
}

export enum UserActionLogStatus {
  Success = "Approved",
  InProgress = "InProgress",
  Declined = "Declined",
}

export enum AuthTypes {
  OAUTH2 = "OAUTH2",
  ApiKey = "API_KEY",
}

export enum AppStatus {
  None = "",
  Verified = "Verified",
  InReview = "InReview",
  Unverified = "Unverified",
}

export enum ConversationMode {
  Chat = "Chat",
  Avatar = "Avatar",
}

export enum FeatureType {
  CreateAvatar,
  ConnectApp,
  ConnectModel,
  CompanyStorage,
  CompanyUser,
  TheChief,
  VoiceAsk,
  LiveAvatar,
  AutoSync,
  Api,
  AIManager,
  WebSearch,
  DashboardSectionAccess,
}

export enum CompanyIndexStatus {
  Indexing = "Indexing",
  Connected = "Connected",
  Failed = "Failed",
}
