import * as React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Link, Navigate } from "react-router-dom";

import analytics from "../../../../helpers/Analytics";
import { CompanyApp } from "../../../models/CompanyApp";
import NameAvatarStep from "../../AvatarWizard/NameAvatarStep";
import stores from "../../../stores";
import AppSelectionStep from "../../AvatarWizard/AppSelectionStep";

interface State {
  tab: string;
  navigate: boolean;
  selectedAppIds: number[];
}

interface Props {
  companyApp?: CompanyApp;
}

const TABS = ["avatar-app-selection", "detail-your-avatar"];

@observer
class CreateNewAvatar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: TABS[0],
      navigate: false,
      selectedAppIds: [],
    };
  }

  setTab = (tabName: string) => {
    this.setState({ tab: tabName });
  };

  handleNext = () => {
    const currentIndex = TABS.indexOf(this.state.tab);
    if (currentIndex < TABS.length - 1) {
      this.setTab(TABS[currentIndex + 1]);
    }
  };

  handleBack = () => {
    const currentIndex = TABS.indexOf(this.state.tab);
    if (currentIndex > 0) {
      this.setTab(TABS[currentIndex - 1]);
    }
  };

  handleSave = () => {
    analytics.trackEvent("avatar_creation_saved", {
      category: "avatar_events",
      label: "avatar_successfully_created",
    });
    this.setState({ navigate: true });
  };

  handleAppSelect = (selectedAppIds: number[]) => {
    this.setState({ selectedAppIds });
  };

  render() {
    const { tab, navigate, selectedAppIds } = this.state;
    const isEditMode = stores.wizardStore.isEditMode;

    if (navigate) {
      return <Navigate to="/avatars" />;
    }

    return (
      <div className="d-flex flex-column align-items-center min-vh-100">
        <div
          className="mb-3 mt-10 d-flex justify-content-between align-items-center"
          style={{
            width: "85%",
          }}
        >
          <div>
            <div className="fw-bold fs-2">
              {isEditMode ? "Editing Avatar" : "Creating Avatar"}
            </div>
          </div>
          <Link
            to="/avatars"
            className={"text-primary fw-bold"}
            onClick={(event) => {
              if (stores.companyAvatarStore.isAvatarLoading) {
                event.preventDefault();
                return;
              }

              if (isEditMode) {
                analytics.trackEvent("cancel_edit_avatar", {
                  category: "avatar_events",
                  label: "cancel_edit_avatar_button",
                });
              } else {
                analytics.trackEvent("cancel_creating_avatar", {
                  category: "avatar_events",
                  label: "cancel_creating_avatar_button",
                });
              }
            }}
          >
            <button
              className="btn btn-primary"
              disabled={stores.companyAvatarStore.isAvatarLoading}
            >
              Cancel
            </button>
          </Link>
        </div>

        <div
          className="card w-70"
          style={{ minWidth: "50vw", maxWidth: "80vw" }}
        >
          <div className="card-header justify-content-center overflow-auto">
            <ul
              className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap justify-content-center"
              role="tablist"
            >
              <li className="nav-item">
                <button
                  className={clsx("nav-link cursor-default", {
                    active: tab === "avatar-app-selection",
                    "text-active-primary": tab === "avatar-app-selection",
                  })}
                  role="tab"
                  style={{
                    pointerEvents: "none",
                    color:
                      tab === "avatar-app-selection" ? "#00ff00" : "inherit",
                  }}
                  disabled
                >
                  <p className="fs-5 px-15 pt-10">{"Avatar App Selection"}</p>
                </button>
              </li>

              <li className="nav-item">
                <button
                  className={clsx("nav-link cursor-default", {
                    active: tab === "detail-your-avatar",
                    "text-active-primary": tab === "detail-your-avatar",
                  })}
                  role="tab"
                  style={{
                    pointerEvents: "none",
                    color: tab === "detail-your-avatar" ? "#00ff00" : "inherit",
                  }}
                  disabled
                >
                  <p className="fs-5 px-15 pt-10">{"Detail Your Avatar"}</p>
                </button>
              </li>
            </ul>
          </div>

          <div className="card-body p-0">
            <div className="tab-content">
              <div
                className={clsx("tab-pane", {
                  active: tab === "avatar-app-selection",
                })}
              >
                <div className="row mb-10">
                  <AppSelectionStep
                    onNext={this.handleNext}
                    onBack={this.handleBack}
                    onAppSelect={this.handleAppSelect}
                  />
                </div>
              </div>

              <div
                className={clsx("tab-pane", {
                  active: tab === "detail-your-avatar",
                })}
              >
                <div className="row mb-10">
                  <NameAvatarStep
                    onBack={this.handleBack}
                    onSave={this.handleSave}
                    isEditMode={isEditMode}
                    selectedAppIds={selectedAppIds}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateNewAvatar;
