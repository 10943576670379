import React, { createRef } from "react";

interface SpeechPlayerProps {
  text: string;
  onPlaybackFinished?: () => void;
  onPlaybackStart?: () => void;
}

interface SpeechPlayerState {
  isPlaying: boolean;
  audioChunks: Uint8Array[];
  processedText: string;
}

class SpeechPlayer extends React.Component<
  SpeechPlayerProps,
  SpeechPlayerState
> {
  private audioRef = createRef<HTMLAudioElement>();
  private socketRef: WebSocket | null = null;
  private voiceId = process.env.REACT_APP_ELEVEN_LABS_VOICE_ID;
  private apiKey = process.env.REACT_APP_ELEVEN_LABS_API_KEY;
  private model = "eleven_flash_v2_5";

  constructor(props: SpeechPlayerProps) {
    super(props);
    this.state = {
      isPlaying: false,
      audioChunks: [],
      processedText: "",
    };
  }

  public isPlaying = (): boolean => {
    return this.state.isPlaying;
  };

  componentDidUpdate(prevProps: SpeechPlayerProps) {
    if (prevProps.text !== this.props.text) {
      console.log("[SpeechPlayer] Props changed. New text:", this.props.text);
      if (this.props.text.trim() === "") {
        console.log("[SpeechPlayer] Empty text received, stopping playback.");
        this.stopPlayback();
        return;
      }
      const newText = this.props.text.replace(this.state.processedText, "");
      const sentences = this.extractCompleteSentences(newText);
      if (sentences.length > 0) {
        if (!this.state.isPlaying) {
          this.handlePlay(sentences.join(" "));
        }
        this.setState(
          (prevState) => ({
            processedText: prevState.processedText + sentences.join(" "),
          }),
          () => {}
        );
      }
    }
  }

  componentDidMount() {
    if (this.props.text.trim() !== "") {
      this.handlePlay(this.props.text);
      this.setState({ processedText: this.props.text }, () => {});
    }
  }

  componentWillUnmount() {
    this.stopPlayback();
  }

  extractCompleteSentences = (text: string) => {
    const sentenceRegex = /[^.!?]+(?:[.!?]+|$)/g;
    const matches = text.match(sentenceRegex) || [];

    return matches;
  };

  handlePlay = (sentence: string) => {
    this.stopPlayback();
    this.setState({ isPlaying: true, audioChunks: [] });

    if (this.props.onPlaybackStart) {
      this.props.onPlaybackStart();
    }

    let finalReceived = false;

    const wsUrl = `wss://api.elevenlabs.io/v1/text-to-speech/${this.voiceId}/stream-input?model_id=${this.model}`;
    this.socketRef = new WebSocket(wsUrl);

    this.socketRef.onopen = () => {
      console.log("[SpeechPlayer] WebSocket connection opened");
      const bosMessage = {
        text: " ",
        voice_settings: { stability: 0.5, similarity_boost: 0.8 },
        xi_api_key: this.apiKey,
      };
      this.socketRef?.send(JSON.stringify(bosMessage));

      const textMessage = { text: sentence };
      this.socketRef?.send(JSON.stringify(textMessage));

      setTimeout(() => {
        const eosMessage = { text: "" };

        this.socketRef?.send(JSON.stringify(eosMessage));
      }, 500);
    };

    this.socketRef.onmessage = (event) => {
      const response = JSON.parse(event.data);

      if (response.audio) {
        const audioBuffer = new Uint8Array(
          atob(response.audio)
            .split("")
            .map((c) => c.charCodeAt(0))
        );
        console.log(
          "[SpeechPlayer] Received audio chunk of length:",
          audioBuffer.length
        );
        this.setState(
          (prevState) => ({
            audioChunks: [...prevState.audioChunks, audioBuffer],
          }),
          () => {
            console.log(
              "[SpeechPlayer] Total audioChunks count:",
              this.state.audioChunks.length
            );
          }
        );
      }

      if (response.isFinal) {
        console.log("[SpeechPlayer] Final audio chunk received.");
        finalReceived = true;

        setTimeout(() => {
          this.socketRef?.close();
          this.playMergedAudio();
        }, 1200);
      }
    };

    this.socketRef.onerror = (error) => {
      console.error("[SpeechPlayer] WebSocket Error:", error);
      this.setState({ isPlaying: false });
    };

    this.socketRef.onclose = (event) => {
      console.info(
        `[SpeechPlayer] WebSocket closed: code=${event.code}, reason=${event.reason}`
      );
      if (!finalReceived && this.state.audioChunks.length > 0) {
        console.log(
          "[SpeechPlayer] onclose: Final not received, playing merged audio after delay."
        );
        setTimeout(() => {
          this.playMergedAudio();
        }, 1000);
      }
    };
  };

  playMergedAudio = () => {
    console.log("[SpeechPlayer] playMergedAudio called.");
    if (this.state.audioChunks.length === 0) {
      console.log("[SpeechPlayer] No audio chunks to play.");
      this.setState({ isPlaying: false });
      return;
    }

    const mergedAudio = new Blob(this.state.audioChunks, { type: "audio/wav" });
    const audioUrl = URL.createObjectURL(mergedAudio);
    const audio = new Audio(audioUrl);

    // @ts-ignore
    this.audioRef.current = audio;

    audio
      .play()
      .then(() => {
        console.log("Merged audio is playing");
      })
      .catch((err) => {
        console.error("[SpeechPlayer] Error playing merged audio:", err);
      });

    audio.onended = () => {
      console.log("[SpeechPlayer] Merged audio playback ended.");
      if (audio.currentTime < audio.duration - 0.1) {
        console.log("Playback finished");
        return;
      }
      this.setState({ isPlaying: false }, () => {
        if (this.props.text.length > this.state.processedText.length) {
          const remainingText = this.props.text.substring(
            this.state.processedText.length
          );
          console.log(
            "[SpeechPlayer] Remaining text to process:",
            remainingText
          );
          const sentences = this.extractCompleteSentences(remainingText);
          if (sentences.length > 0) {
            console.log(
              "[SpeechPlayer] Playing remaining sentences:",
              sentences.join(" ")
            );
            this.handlePlay(sentences.join(" "));
            this.setState(
              (prevState) => ({
                processedText: prevState.processedText + sentences.join(" "),
                isPlaying: true,
              }),
              () => {
                console.log(
                  "[SpeechPlayer] Updated processedText after remaining sentences:",
                  this.state.processedText
                );
              }
            );
            return;
          }
        }
        if (this.props.onPlaybackFinished) {
          setTimeout(() => {
            console.log("[SpeechPlayer] Playback finished callback invoked.");
            this.props.onPlaybackFinished!();
          }, 100);
        }
      });
    };
  };

  stopPlayback = () => {
    console.log("[SpeechPlayer] stopPlayback called.");
    if (this.socketRef) {
      console.log("[SpeechPlayer] Closing WebSocket.");
      this.socketRef.close();
      this.socketRef = null;
    }
    if (this.audioRef.current) {
      console.log("[SpeechPlayer] Stopping audio playback.");
      this.audioRef.current.pause();
      this.audioRef.current.currentTime = 0;
      // @ts-ignore
      this.audioRef.current = null;
    }
    this.setState(
      { audioChunks: [], processedText: "", isPlaying: false },
      () => {
        console.log("[SpeechPlayer] Playback stopped and state reset.");
      }
    );
  };

  render() {
    return null;
  }
}

export default SpeechPlayer;
