import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { WithChildren } from "../../helpers";

const SplashScreenContext = createContext<
  Dispatch<SetStateAction<number>> | undefined
>(undefined);

const styles = {
  borderRadius: "0.475rem",
  // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
  // backgroundColor: "#fff",
  color: "#7e8299",
  fontWeight: "500",
  margin: "0",
  width: "auto",
  padding: "1rem 2rem",
  top: "calc(50% - 2rem)",
  left: "calc(50% - 4rem)",
};

const SplashScreenProvider: FC<WithChildren> = ({ children }) => {
  const [count, setCount] = useState(0);
  let visible = count > 0;

  useEffect(() => {
    // Show SplashScreen
    if (visible) {
      document.body.classList.remove("page-loading");

      return () => {
        document.body.classList.add("page-loading");
      };
    }

    // Hide SplashScreen
    let timeout: number;
    if (!visible) {
      timeout = window.setTimeout(() => {
        document.body.classList.add("page-loading");
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [visible]);

  return (
    <SplashScreenContext.Provider value={setCount}>
      {children}
    </SplashScreenContext.Provider>
  );
};

const LayoutSplashScreen: FC<{ visible?: boolean }> = ({ visible = true }) => {
  // Everything are ready - remove splashscreen
  const setCount = useContext(SplashScreenContext);

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (setCount) {
      setCount((prev) => {
        return prev + 1;
      });
    }

    return () => {
      if (setCount) {
        setCount((prev) => {
          return prev - 1;
        });
      }
    };
  }, [setCount, visible]);

  return (
    <div style={{ ...styles, position: "absolute", textAlign: "center" }}>
      <span className="spinner-border text-primary mb-3" role="status"></span>
      <div>Loading...</div>
    </div>
  );
};

export { SplashScreenProvider, LayoutSplashScreen };
