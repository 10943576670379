import { Position, NodeProps, Node, Edge, useReactFlow } from "@xyflow/react";

import { CompanyAvatar } from "../../../models/CompanyAvatar";
import CustomHandle from "../handles/CustomHandle";

interface AvatarNodeProps extends NodeProps {
  data: {
    company_avatar: CompanyAvatar;
    handles: { id: string; allowedTypes: string[] }[];
  };
  nodes: Node[];
  edges: Edge[];
}

const AvatarNode: React.FC<AvatarNodeProps> = (props) => {
  const { deleteElements } = useReactFlow();

  const handleDelete = () => {
    deleteElements({ nodes: [{ id: props.id }] });
  };

  return (
    <div className="p-3 bg-body">
      <div
        style={{
          marginLeft: 10,
          marginRight: 10,
          marginTop: 2.5,
          marginBottom: 2.5,
        }}
      >
        <CustomHandle
          id="avatar_node_target_1"
          type="target"
          position={Position.Right}
          connectionCount={1}
          nodes={props.nodes}
          edges={props.edges}
        />

        <div>
          <img
            src={props.data.company_avatar.logo_url}
            alt={`${props.data.company_avatar.name} Avatar`}
            style={{ width: 35, height: 35 }}
          />
          <div style={{ marginTop: 5, fontSize: 12 }}>
            {props.data.company_avatar.name}
          </div>
        </div>
      </div>

      <button
        onClick={handleDelete}
        style={{
          position: "absolute",
          top: "1px",
          right: "-2px",
          background: "transparent",
          border: "none",
          color: "#F64E60",
          cursor: "pointer",
          fontSize: 8,
        }}
        aria-label="Close"
      >
        ❌
      </button>
    </div>
  );
};

export default AvatarNode;
