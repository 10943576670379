import React from "react";
import { CustomUser } from "../../../../../models/CustomUser";

type Props = {
  customUser: CustomUser;
};

export class CompanyUserRoleCell extends React.Component<Props> {
  render() {
    const { customUser } = this.props;

    const roleLabel = customUser.role_id === 1 ? "Admin" : "Member";

    return (
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">{roleLabel}</div>
      </div>
    );
  }
}
