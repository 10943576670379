import React, { useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { observer } from "mobx-react";

import stores from "../../../stores";
import { Department } from "../../../../app/models/Department";

const CompanyDetailsForm = observer(() => {
  const formik = useFormikContext<any>();

  const { companyDepartmentStore } = stores;
  const { departments, isLoading } = companyDepartmentStore;

  useEffect(() => {
    if (!isLoading && departments.length === 0) {
      companyDepartmentStore.loadDepartments();
    }
  }, [departments, isLoading, companyDepartmentStore]);

  if (!formik) {
    return null;
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className="py-10" onKeyDown={handleKeyDown}>
      <div className="text-center mb-10">
        <h1 className="mb-3">Enter Company Details</h1>
        <div className="text-muted fw-bold fs-5">
          Provide the necessary information about your company to get started.
        </div>
      </div>

      <div className="mb-10 fv-row">
        <label className="d-flex align-items-center form-label mb-3 required">
          Specify Team Size
        </label>
        <div className="row mb-2" data-kt-buttons="true">
          {["1-1", "2-10", "10-50", "50+"].map((size, index) => (
            <div className="col" key={index}>
              <Field
                type="radio"
                className="btn-check"
                name="teamSize"
                value={size}
                id={`kt_team_size_select_${index + 1}`}
              />
              <label
                className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                htmlFor={`kt_team_size_select_${index + 1}`}
              >
                <span className="fw-bolder fs-3">{size}</span>
              </label>
            </div>
          ))}
        </div>

        <ErrorMessage
          name="teamSize"
          component="div"
          className="text-danger mt-2"
        />
      </div>

      <div className="mb-10 fv-row">
        <label className="form-label mb-3 required">Company Name</label>
        <Field
          type="text"
          className="form-control form-control-lg form-control-solid"
          name="name"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="name" />
        </div>
      </div>

      <div className="mb-10 fv-row">
        <label className="form-label mb-3 required">Manager Type</label>
        <Field
          as="select"
          className="form-select form-select-solid form-select-white"
          name="department_id"
        >
          <option value="">Select Manager Type</option>
          {departments
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((dept: Department) => (
              <option key={dept.id} value={dept.id}>
                {dept.name} Manager
              </option>
            ))}
        </Field>
        <div className="text-danger mt-2">
          <ErrorMessage name="department_id" />
        </div>
      </div>

      {/* Company Website */}
      <div className="mb-10 fv-row">
        <label className="form-label mb-3">Company Website</label>
        <Field
          type="text"
          className="form-control form-control-lg form-control-solid"
          name="website"
          placeholder="https://yourcompany.com"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="website" />
        </div>
      </div>

      <div className="mb-10 fv-row">
        <label className="form-label mb-3">Company Description</label>
        <Field
          as="textarea"
          className="form-control form-control-lg form-control-solid"
          name="companyDescription"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="companyDescription" />
        </div>
      </div>
    </div>
  );
});

export default CompanyDetailsForm;
