import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { SharedCompanyAvatar } from "../../app/models/SharedCompanyAvatar";

export default async function getSharedAvatarWithUsers(
  companyAvatarId: number
): Promise<SharedCompanyAvatar[]> {
  const client = ApolloClientHelper.getApolloClient();

  const query = gql`
    query SharedAvatarWithUsersQuery($companyAvatarId: Int!) {
      sharedAvatarWithUsers(companyAvatarId: $companyAvatarId) {
        id
        company_avatar_id
        shared_by_user_id
        shared_with_user_id
        invitation_status
        created_at
        updated_at
        company_avatar {
          id
          company_id
          type_id
          name
          description
          logo_url
          status
        }
        shared_by_user {
          id
          name
          email
        }
        shared_with_user {
          id
          name
          email
        }
      }
    }
  `;

  try {
    const { data } = await client.query({
      query,
      variables: { companyAvatarId },
      fetchPolicy: "no-cache",
    });
    return get(data, "sharedAvatarWithUsers", []);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
