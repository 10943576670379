import { action, observable, when } from "mobx";
import { toast } from "react-toastify";

import {
  getCompanyAvatars,
  createCompanyAvatar,
  createAvatarIndex,
  createGeneralIndex,
  deleteAvatarIndex,
  updateCompanyAvatar,
  getCompanyAvatarApps,
  getAvatarTypes,
  getSharedAvatarsByUser,
  getSharedAvatarsWithUser,
} from "../../helpers/api";
import { CompanyAvatar } from "../models/CompanyAvatar";
import {
  FeatureType,
  CompanyAvatarStatus,
  UserActionLogStatus,
  CompanyIndexStatus,
  SharedAvatarStatus,
} from "../../helpers/Enums";
import SocketHelper from "../../helpers/SocketHelper";
import i18n from "../../i18n";
import createCompanyAvatarApp from "../../helpers/api/createCompanyAvatarApp";
import deleteCompanyAvatarApp from "../../helpers/api/deleteCompanyAvatarApp";
import realDeleteCompanyAvatarAPI from "../../helpers/api/deleteCompanyAvatar";
import { AvatarType } from "../models/AvatarType";
import { SharedCompanyAvatar } from "../models/SharedCompanyAvatar";

import RootStore from "./RootStore";
import stores from ".";

export default class CompanyAvatarStore {
  @observable companyAvatars: CompanyAvatar[] = [];
  @observable sharedAvatarsWithUser: SharedCompanyAvatar[] = [];
  @observable sharedAvatarsByUser: SharedCompanyAvatar[] = [];
  @observable sharedAvatarInvitations: SharedCompanyAvatar[] = [];
  @observable selectedCompanyAvatar: CompanyAvatar | undefined = undefined;
  @observable isLoading: boolean = false;
  @observable isAvatarLoading: boolean = false;
  @observable isSharedAvatarModalOpen: boolean = false;
  @observable dashboard_section_id: number | null = null;
  @observable avatarTypes: AvatarType[] = [];

  @observable scrollPosition: number = 0;

  constructor(rootStore: RootStore) {
    when(
      () =>
        rootStore.companyStore.selectedUserCompany !== undefined &&
        rootStore.companyUserStore.isLoading === false,
      () => {
        this.getCompanyAvatars();
        this.getAvatarTypes();
        this.getSharedAvatarsWithUser();
        this.getSharedAvatarsByUser();

        SocketHelper.addMessageHandler(this.handleMessage);
      }
    );
  }

  @action
  private handleMessage = async (data: any) => {
    if (data.companyAvatarId) {
      const companyAvatarIndex = this.companyAvatars.findIndex(
        (item) => item.id === data.companyAvatarId
      );

      if (companyAvatarIndex !== -1 && data.processId === 6) {
        if (!data.isIndexCreating && data.isIndexCreated) {
          const updatedAvatar = {
            ...this.companyAvatars[companyAvatarIndex],
            status: CompanyAvatarStatus.Connected,
            last_sync_at: Date.now(),
          };

          this.companyAvatars = [
            ...this.companyAvatars.slice(0, companyAvatarIndex),
            updatedAvatar,
            ...this.companyAvatars.slice(companyAvatarIndex + 1),
          ];

          await updateCompanyAvatar(updatedAvatar);

          toast.update(data.companyAvatarId, {
            render: `${updatedAvatar.name} created successfully.`,
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else if (!data.isIndexCreating && !data.isIndexCreated) {
          const updatedAvatar = {
            ...this.companyAvatars[companyAvatarIndex],
            status: CompanyAvatarStatus.Failed,
            last_sync_at: 0,
          };

          this.companyAvatars = [
            ...this.companyAvatars.slice(0, companyAvatarIndex),
            updatedAvatar,
            ...this.companyAvatars.slice(companyAvatarIndex + 1),
          ];

          await updateCompanyAvatar(updatedAvatar);

          toast.update(data.companyAvatarId, {
            render: `${updatedAvatar.name} index creation error.`,
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            position: "top-center",
          });
        }
      }
    }
  };

  @action checkGeneralIndex = async () => {
    if (
      stores.companyStore.selectedUserCompany &&
      stores.companyStore.selectedUserCompany.index_status ===
        CompanyIndexStatus.Failed &&
      stores.companyStorageStore.selectedCompanyStorage
    ) {
      const result = await createGeneralIndex(
        stores.companyStore.selectedUserCompany.id,
        stores.companyStorageStore.selectedCompanyStorage.id
      );

      if (result.success) {
        stores.companyStore.selectedUserCompany.index_status =
          CompanyIndexStatus.Connected;
        stores.companyStore.updateCompany(
          stores.companyStore.selectedUserCompany
        );
      }
    }
  };

  @action updateCompanyAvatar = async (
    companyAvatar: Partial<CompanyAvatar>,
    selectedAppIds: number[]
  ): Promise<boolean> => {
    let userActionLog;
    try {
      userActionLog = await stores.userActionLogStore.createUserActionLog(
        "Avatar Update in Progress",
        `We are currently updating the details of your avatar ${companyAvatar.name}. This process may take a moment.`,
        0,
        0,
        UserActionLogStatus.InProgress
      );
    } catch (error) {
      console.error("Failed to create update log:", error);
    }

    this.isLoading = true;
    try {
      const result = await updateCompanyAvatar(companyAvatar);
      if (result) {
        const index = this.companyAvatars.findIndex(
          (avatar) => Number(avatar.id) === Number(companyAvatar.id)
        );

        if (index !== -1) {
          this.companyAvatars[index] = {
            ...this.companyAvatars[index],
            ...companyAvatar,
          };
        }

        const companyAvatarApp = await getCompanyAvatarApps(
          Number(companyAvatar.id)
        );

        const appsToDelete = companyAvatarApp.filter(
          (app) =>
            !selectedAppIds.toString().includes(app.company_app_id.toString())
        );

        const appsToCreate = selectedAppIds.filter(
          (appId) =>
            !companyAvatarApp.some(
              (app) => app.company_app_id.toString() === appId.toString()
            )
        );

        await Promise.all(
          appsToCreate.map((appId) =>
            createCompanyAvatarApp(Number(companyAvatar.id), Number(appId))
          )
        );

        await Promise.all(
          appsToDelete.map((app) => deleteCompanyAvatarApp(Number(app.id)))
        );

        if (companyAvatar.id) {
          await this.createAvatarIndex(this.companyAvatars[index]);
        }

        if (userActionLog) {
          userActionLog.action_status = UserActionLogStatus.Success;
          userActionLog.action_result = `${companyAvatar.name} updated successfully.`;
          await stores.userActionLogStore.updateUserActionLog(userActionLog);
        }

        return true;
      } else {
        if (userActionLog) {
          userActionLog.action_status = UserActionLogStatus.Declined;
          userActionLog.action_result = `${companyAvatar.name} update failed.`;
          await stores.userActionLogStore.updateUserActionLog(userActionLog);
        }
        return false;
      }
    } catch (error) {
      console.error("Failed to update company avatar:", error);
      if (userActionLog) {
        userActionLog.action_status = UserActionLogStatus.Declined;
        userActionLog.action_result = `${companyAvatar.name} update error: ${error}`;
        await stores.userActionLogStore.updateUserActionLog(userActionLog);
      }
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  @action getAvatarTypes = async () => {
    try {
      const avatarTypes = await getAvatarTypes();

      this.avatarTypes = avatarTypes;
    } catch (error) {
      console.error("Failed to fetch avatar types:", error);
    }
  };

  @action getSharedAvatarsByUser = async () => {
    try {
      const sharedAvatarsByUser = await getSharedAvatarsByUser(
        Number(stores.userStore.currentUser.id)
      );

      this.sharedAvatarsByUser = sharedAvatarsByUser;
    } catch (error) {
      console.error("Failed to fetch shared avatars by user:", error);
    }
  };

  @action getSharedAvatarsWithUser = async () => {
    try {
      const sharedAvatarsWithUser = await getSharedAvatarsWithUser(
        Number(stores.userStore.currentUser.id)
      );

      this.sharedAvatarsWithUser = sharedAvatarsWithUser.filter(
        (avatar) => avatar.invitation_status === SharedAvatarStatus.Accepted
      );

      this.sharedAvatarInvitations = sharedAvatarsWithUser.filter(
        (avatar) => avatar.invitation_status === SharedAvatarStatus.Pending
      );
    } catch (error) {
      console.error("Failed to fetch shared avatars with user:", error);
    }
  };
  @action getCompanyAvatars = async () => {
    this.isLoading = true;

    try {
      const company_id = stores.companyStore.selectedUserCompany?.id;
      if (company_id) {
        const avatars = await getCompanyAvatars(company_id);

        if (stores.userStore.isCurrentUserAdmin) {
          this.companyAvatars = avatars;
        } else {
          const filteredAvatars: CompanyAvatar[] = avatars.filter(
            (avatar) =>
              avatar.type_id === 2 &&
              avatar.status === CompanyAvatarStatus.Connected
          );

          if (filteredAvatars.length > 0) {
            this.selectedCompanyAvatar = filteredAvatars[0];
          }

          this.companyAvatars = filteredAvatars;
        }
      }
    } catch (error) {
      console.error("Failed to fetch company avatars:", error);
    } finally {
      this.isLoading = false;
    }
  };

  @action getAvatarDepartmentId = (avatarId: number): number | null => {
    const avatar = this.companyAvatars.find((avatar) => avatar.id === avatarId);
    if (
      avatar &&
      avatar.company_avatar_departments &&
      avatar.company_avatar_departments.length > 0
    ) {
      return avatar.company_avatar_departments[0].department_id;
    }
    return null;
  };

  @action createCompanyAvatar(
    company_id: number,
    dashboard_section_id: number | null,
    name: string,
    type_id: number,
    description: string,
    prompt_template: string,
    logo_url: string,
    selectedAppIds: number[]
  ): Promise<CompanyAvatar> {
    return new Promise(async (resolve, reject) => {
      if (
        !(await stores.userStore.checkSubscribedFeatureType(
          FeatureType.CreateAvatar
        ))
      ) {
        reject();
        return;
      }

      this.isLoading = true;

      let userActionLog;
      try {
        userActionLog = await stores.userActionLogStore.createUserActionLog(
          "Creating Your Avatar",
          `Your new avatar ${name} is being created. Sit tight, this won't take long!`,
          0,
          0,
          UserActionLogStatus.InProgress
        );
      } catch (logError) {
        console.error("Failed to create start log:", logError);
      }

      try {
        const newAvatar = await createCompanyAvatar(
          company_id,
          dashboard_section_id,
          name,
          Number(type_id),
          description,
          prompt_template,
          logo_url,
          CompanyAvatarStatus.InProgress,
          0
        );

        this.companyAvatars.push(newAvatar);

        const numericAppIds = selectedAppIds.map((id) => Number(id));

        await Promise.all(
          numericAppIds.map((appId) =>
            createCompanyAvatarApp(Number(newAvatar.id), appId)
          )
        );

        await this.createAvatarIndex(newAvatar);

        await stores.companyAvatarStore.getCompanyAvatars();

        if (userActionLog) {
          userActionLog.action_status = UserActionLogStatus.Success;
          userActionLog.action_result = `${name} avatar created successfully`;
          await stores.userActionLogStore.updateUserActionLog(userActionLog);
        }

        this.isLoading = false;
        resolve(newAvatar);
      } catch (error) {
        console.error(error);

        if (userActionLog) {
          userActionLog.action_status = UserActionLogStatus.Declined;
          userActionLog.action_result = `${name} avatar creation failed: ${error}`;
          await stores.userActionLogStore.updateUserActionLog(userActionLog);
        }

        this.isLoading = false;
        reject(error);
      }
    });
  }

  @action
  createAvatarIndex(companyAvatar: CompanyAvatar): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!stores.companyStorageStore.selectedCompanyStorage) {
        toast.error(i18n.ToastMessages.nonStorageError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      const emotionalMessages = [
        "We are forging the path to greatness!",
        "Hold tight! Your avatar is leveling up.",
        "The magic of indexing is happening. ✨",
        "Stay calm, the gears are turning smoothly. 🛠️",
        "Your avatar is about to shine brighter than ever! 🌟",
        "Big things are coming, just a moment longer! 🚀",
      ];

      const getRandomMessage = () =>
        emotionalMessages[Math.floor(Math.random() * emotionalMessages.length)];

      const actionToastId = toast.info(
        `Preparing ${companyAvatar.name}... ${getRandomMessage()}`,
        {
          position: "top-center",
          autoClose: false,
          toastId: companyAvatar.id,
          closeButton: true,
        }
      );

      let userActionLog;
      try {
        userActionLog = await stores.userActionLogStore.createUserActionLog(
          "Preparing Avatar Data",
          `We are getting everything ready for your avatar ${companyAvatar.name} to work smoothly.`,
          0,
          0,
          UserActionLogStatus.InProgress
        );
      } catch (logError) {
        console.error("Failed to create index log:", logError);
      }

      const intervalId = setInterval(() => {
        toast.update(actionToastId, {
          render: `${companyAvatar.name}... ${getRandomMessage()}`,
        });
      }, 3000);

      try {
        const createAvatarIndexDataResult = await createAvatarIndex(
          companyAvatar.company_id,
          companyAvatar.id,
          stores.companyStorageStore.selectedCompanyStorage.id
        );

        clearInterval(intervalId);

        if (createAvatarIndexDataResult.success) {
          if (userActionLog) {
            userActionLog.action_status = UserActionLogStatus.Success;
            userActionLog.action_result = `${companyAvatar.name} created successfully`;
            await stores.userActionLogStore.updateUserActionLog(userActionLog);
          }

          toast.update(actionToastId, {
            render: `${companyAvatar.name} is ready to use! 🎉`,
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });

          setTimeout(() => {
            toast.dismiss(actionToastId);
          }, 3000);

          resolve(true);
        } else {
          if (userActionLog) {
            userActionLog.action_status = UserActionLogStatus.Declined;
            userActionLog.action_result = `${companyAvatar.name} index creation failed: ${createAvatarIndexDataResult.message}`;
            await stores.userActionLogStore.updateUserActionLog(userActionLog);
          }

          toast.update(actionToastId, {
            render: `${companyAvatar.name} index creation failed. 😢`,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });

          setTimeout(() => {
            toast.dismiss(actionToastId);
          }, 3000);

          resolve(false);
        }
      } catch (error) {
        clearInterval(intervalId);

        console.error("Index creation error:", error);

        if (userActionLog) {
          userActionLog.action_status = UserActionLogStatus.Declined;
          userActionLog.action_result = `${companyAvatar.name} index creation error: ${error}`;
          await stores.userActionLogStore.updateUserActionLog(userActionLog);
        }

        toast.update(actionToastId, {
          render: `${companyAvatar.name} index creation error. 😟`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });

        setTimeout(() => {
          toast.dismiss(actionToastId);
        }, 3000);

        reject(false);
      }
    });
  }

  @action
  async deleteCompanyAvatarWithIndex(avatarId: number): Promise<boolean> {
    const avatar = this.companyAvatars.find((a) => a.id === avatarId);
    if (!avatar) {
      toast.error(i18n.ToastMessages.avatarNotFoundError, {
        position: "top-center",
        autoClose: 5000,
      });
      return false;
    }

    const isAllowed = this.checkDashboardSectionAccess(avatar);
    if (!isAllowed) {
      return false;
    }

    let userActionLog;
    try {
      userActionLog = await stores.userActionLogStore.createUserActionLog(
        `Removing Avatar ${avatar.name}`,
        `We are currently removing your avatar ${avatar.name}. This might take a moment.`,
        0,
        0,
        UserActionLogStatus.InProgress
      );
    } catch (error) {
      console.error("Failed to create user action log:", error);
      return false;
    }

    try {
      const storageId = stores.companyStorageStore.selectedCompanyStorage?.id;
      if (!storageId) {
        userActionLog.action_status = UserActionLogStatus.Declined;
        userActionLog.action_result =
          "No storage selected. Cannot delete avatar.";
        await stores.userActionLogStore.updateUserActionLog(userActionLog);

        toast.error("No storage selected. Cannot delete avatar.", {
          position: "top-center",
          autoClose: 5000,
        });
        return false;
      }

      const deleteIndexResult = await deleteAvatarIndex(
        avatar.company_id,
        avatar.id,
        stores.companyStorageStore.selectedCompanyStorage!.id
      );
      if (!deleteIndexResult.success) {
        userActionLog.action_status = UserActionLogStatus.Declined;
        userActionLog.action_result = `Index deletion failed: ${deleteIndexResult.message}`;
        await stores.userActionLogStore.updateUserActionLog(userActionLog);

        toast.error(deleteIndexResult.message, {
          position: "top-center",
          autoClose: 5000,
        });
        return false;
      }

      const deleted = await this.deleteCompanyAvatar(avatar.id);
      if (!deleted) {
        return false;
      }

      await stores.userActionLogStore.createUserActionLog(
        "Avatar Deletion Successful",
        `Your avatar ${avatar.name} has been successfully deleted.`,
        0,
        0,
        UserActionLogStatus.Success
      );

      return true;
    } catch (error) {
      console.error("Error deleting avatar with index:", error);

      if (userActionLog) {
        userActionLog.action_status = UserActionLogStatus.Declined;
        userActionLog.action_result = `Avatar deletion error: ${error}`;
        await stores.userActionLogStore.updateUserActionLog(userActionLog);
      }

      toast.error(i18n.ToastMessages.deleteFailed, {
        position: "top-center",
        autoClose: 5000,
      });
      return false;
    }
  }

  @action
  checkDashboardSectionAccess(avatar: CompanyAvatar): boolean {
    if (avatar.dashboard_section_id) {
      toast.error(i18n.ToastMessages.accessDeleteAvatarError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    return true;
  }

  @action
  async deleteCompanyAvatar(avatarId: number): Promise<boolean> {
    const avatar = this.companyAvatars.find((a) => a.id === avatarId);
    if (!avatar) {
      toast.error(i18n.ToastMessages.avatarNotFoundError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return false;
    }

    const isAllowed = this.checkDashboardSectionAccess(avatar);
    if (!isAllowed) {
      return false;
    }

    const userActionLog = await stores.userActionLogStore.createUserActionLog(
      `Deleting Avatar ${avatar.name}`,
      `We have started the process to delete your avatar ${avatar.name}. Please wait while we complete this action.`,
      0,
      0,
      UserActionLogStatus.InProgress
    );

    try {
      const apiDeleteSuccess = await realDeleteCompanyAvatarAPI(avatarId);
      if (!apiDeleteSuccess) {
        userActionLog.action_status = UserActionLogStatus.Declined;
        userActionLog.action_result = `Failed to delete ${avatar.name} on server.`;
        await stores.userActionLogStore.updateUserActionLog(userActionLog);

        toast.error(i18n.ToastMessages.deleteFailed, {
          position: "top-center",
          autoClose: 5000,
        });
        return false;
      }

      this.companyAvatars = this.companyAvatars.filter(
        (a) => a.id !== avatarId
      );

      userActionLog.action_status = UserActionLogStatus.Success;
      userActionLog.action_result = `${avatar.name} avatar deleted successfully`;
      await stores.userActionLogStore.updateUserActionLog(userActionLog);

      return true;
    } catch (error) {
      console.error("Error occurred during avatar deletion:", error);

      userActionLog.action_status = UserActionLogStatus.Declined;
      userActionLog.action_result = `${avatar.name} deletion error: ${error}`;
      await stores.userActionLogStore.updateUserActionLog(userActionLog);

      toast.error(i18n.ToastMessages.deleteFailed, {
        position: "top-center",
        autoClose: 5000,
      });
      return false;
    }
  }
}
